import { useMediaQuery } from "react-responsive";

const SuggestedSpaOrGarage = (props) => {
  const { spaOrGarageList } = props;
  const reviews = [1, 2, 3, 4, 5];
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 500px) and (max-width: 912px)",
  });
  return (
    <>
      {spaOrGarageList &&
        spaOrGarageList.map((center) => {
          return (
            <>
              <div
                class="suggested_spaOrGarage service-center"
                onClick={() => {
                  if (isMobile || isTablet) {
                    props.showDetails({...center,service_center_dispaly_name:center.name});
                  }
                }}
              >
                <div class="row">
                  <div class="col-lg-2 col-md-3 col-sm-3 m-0">
                    <img
                      src="/assets/imgs/garages/garage-image.png"
                      // src={center.garagePic}
                      className="img-fluid h-100"
                    />
                  </div>
                  <div class="col-lg-10 col-md-8 col-sm-9">
                    <h5 className="post-title fw-bold m-0">{center.name}</h5>
                    <div class="d-flex align-items-center justify-content-start">
                      <ul class="p-0 m-0">
                        {reviews.map((item) => {
                          return (
                            <>
                              {center?.overAllRatingData?.avarageRate != 0 &&
                              item <= center?.overAllRatingData?.avarageRate ? (
                                // <li>
                                <img
                                  class="rating"
                                  style={{ width: "15px" }}
                                  src="../assets/icons/star-selected.svg"
                                ></img>
                              ) : (
                                <img
                                  class="rating blue-star"
                                  style={{ width: "15px" }}
                                  src="../assets/icons/star.svg"
                                ></img>
                              )}
                            </>
                          );
                        })}
                      </ul>
                    </div>
                    {center?.overAllRatingData?.totalRatedUsers > 0 ? (
                      <p class="m-0">
                        ({center.overAllRatingData.totalRatedUsers} - reviews)
                      </p>
                    ) : (
                      <p class="m-0">(0 - reviews)</p>
                    )}
                    <p class="m-0">{center.distance} Km away</p>
                    <p
                      style={{
                        maxWidth: `${isMobile || isTablet ? "100%" : "50%"}`,
                      }}
                      class="m-0 d-inline-block text-truncate"
                    >
                      {center.full_address}
                    </p>
                    <div class="d-flex justify-content-end">
                      <p
                        class=" fw-bolder pointer details m-0 "
                        onClick={() => {
                          props.showDetails({...center,service_center_dispaly_name:center.name});
                        }}
                      >
                        Details {">>"} &nbsp;
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
    </>
  );
};

export default SuggestedSpaOrGarage;
