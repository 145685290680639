import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  METAMECHX_ADDRESS,
  METAMECHX_EMAIL,
  METAMECHX_MOBILE,
} from "../../config/constants";

const ContactUs = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalDetails, setModalDetails] = useState(null);

  const TollfreeModal = (props) => {
    const { headerName, title, data } = props;
    return (
      <>
        <Modal className="contact-details-modal" show={showModal} centered>
          <Modal.Body>
            <div>
              <div class="d-flex align-items-center justify-content-end">
                <img
                  class="pointer"
                  src="../assets/icons/cross.png"
                  style={{ width: "20px" }}
                  onClick={() => {
                    setShowModal(false);
                  }}
                ></img>
              </div>
              <div class="d-flex align-items-center justify-content-around">
                <span className="modal_header fw-600">
                  {" "}
                  &nbsp; {headerName} &nbsp;
                </span>
              </div>
              <div class="my-3">
                <div class="d-flex align-items-center justify-content-around">
                  <span className="modal_text fw-600">{title}:</span>
                </div>
                <div class="d-flex align-items-center justify-content-around">
                  <span className="modal_text fw-600">{data}</span>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };

  return (
    <>
      <div class="contact_us">
        <p class="mb-4 account-headers">Contact Us</p>
        <h6 class="fw-600 question">How can we help you now?</h6>
        <p class="m-0 desc">
          Seems like you are experiencing some problems. <br />
          Choose how would you like to react out to us now.
        </p>
        <div class="row mt-4">
          <div className="col-lg-4 col-md-4 col-sm-6 pointer">
            <img
              src="/assets/imgs/contact-us/call.png"
              alt=""
              class="w-100"
              onClick={() => {
                setModalDetails({
                  headerName: "Call Us Now",
                  title: "Toll Free No",
                  data: METAMECHX_MOBILE,
                });
                setShowModal(true);
              }}
            />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 pointer">
            {/* <a href="mailto:connect@metamechx.com" className="bx bx-envelope"> */}
            <img
              src="/assets/imgs/contact-us/mail.png"
              alt=""
              class="w-100"
              onClick={() => {
                setModalDetails({
                  headerName: "Mail Us Now",
                  title: "Mail Id",
                  data: METAMECHX_EMAIL,
                });
                setShowModal(true);
              }}
            />
            {/* </a> */}
          </div>
        </div>
      </div>
      {showModal && <TollfreeModal {...modalDetails} />}
    </>
  );
};

export default ContactUs;
