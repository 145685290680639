import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CouponsAndWalletService } from '../../services/couponsAndWallet.service';
import { EmergencyService } from '../../services/emergency.service';
import { CommonServices } from '../../utils/commonServices';
const commonServices = new CommonServices();
const walletAmount = commonServices.getLocalStrgJSON("MMX_WALLET")
const initialState = {
    getCouponsResponse: null,
    applyCouponResponse: null,
    removeCouponResponse: null,
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorResponse: null,
    walletAmount: walletAmount || null,
    applyWalletAmountResponse: null,
    isApplyingCoupon: false
}


/**
 * Write your Async reducers here and add builders for them to the extraReducers object
 */
export const getCoupons = createAsyncThunk('couponsAndWallet/getCoupons', async (data, thunkAPI) => {
    const couponsAndWalletService = new CouponsAndWalletService();
    try {
        const response = await couponsAndWalletService.getCoupons(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const applyCoupon = createAsyncThunk('couponsAndWallet/applyCoupon', async (data, thunkAPI) => {
    const couponsAndWalletService = new CouponsAndWalletService();
    try {
        const response = await couponsAndWalletService.applyCoupon(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const removeCoupon = createAsyncThunk('couponsAndWallet/removeCoupon', async (data, thunkAPI) => {
    const couponsAndWalletService = new CouponsAndWalletService();
    try {
        const response = await couponsAndWalletService.removeCoupon(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const getWalletAmountToRedeem = createAsyncThunk('couponsAndWallet/getWalletAmountToRedeem', async (data, thunkAPI) => {
    const couponsAndWalletService = new CouponsAndWalletService();
    try {
        const response = await couponsAndWalletService.getWalletAmountToRedeem(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const applyWalletAmount = createAsyncThunk('couponsAndWallet/applyWalletAmount', async (data, thunkAPI) => {
    const couponsAndWalletService = new CouponsAndWalletService();
    try {
        const response = await couponsAndWalletService.applyWalletAmount(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});


const couponsAndWalletSlice = createSlice({
    name: 'couponsAndWallet',
    initialState: initialState,
    reducers: {
        resetCouponAndWalletSlice: (state) => {
            state.applyCouponResponse = null
            state.removeCouponResponse = null
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
        },
        updateIsApplyingCoupon: (state, action) => {
            state.isApplyingCoupon = action.payload
        }
    },
    extraReducers: (builder) => {
        // add your async reducers here
        builder

            .addCase(getCoupons.fulfilled, (state, action) => {
                state.getCouponsResponse = action.payload.data
            })

            .addCase(applyCoupon.fulfilled, (state, action) => {
                state.applyCouponResponse = action.payload.data
            })
            .addCase(removeCoupon.fulfilled, (state, action) => {
                state.removeCouponResponse = action.payload.data
            })
            .addCase(getWalletAmountToRedeem.fulfilled, (state, action) => {
                if (action.payload) {
                    state.walletAmount = action.payload.data
                    state.isApplyingCoupon = false
                }
                commonServices.setLocalStrg("MMX_WALLET", JSON.stringify(state.walletAmount))
            })
            .addCase(applyWalletAmount.fulfilled, (state, action) => {
                state.applyWalletAmountResponse = action.payload.data
            })


    }
});

export const { resetCouponAndWalletSlice, updateIsApplyingCoupon } = couponsAndWalletSlice.actions;

export const couponsAndWalletSelector = (state) => state.couponsAndWallet;

export default couponsAndWalletSlice.reducer;
