/* eslint-disable no-useless-catch */
import { SLIDER } from '../config/constants';
import { HttpClient } from '../utils/httpClient';

class BannerService {
  constructor() {
    this.httpClient = new HttpClient();
  }

  async getBanners(data) {
    try {
      const vehicleTypeId = data.vehicleType;
      const response = await this.httpClient.get(SLIDER);
      // const response =BannerData;
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export { BannerService };

