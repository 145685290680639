import { Navigate, useLocation } from "react-router-dom";
import { CommonServices } from "../utils/commonServices";
import { authSelector } from "../features/auth/authSlice";
import { useSelector } from "react-redux";

const ValidateCheckoutRoute = ({ children }) => {
  const location = useLocation();
  const commonServices = new CommonServices();
  const { isAuthenticated, user } = useSelector(authSelector);
  const cartCount = commonServices.getLocalStrg("MMX_CART_COUNT")
  if (cartCount === null || cartCount === undefined || cartCount === "" || !isAuthenticated) {
    return <Navigate to="/home" state={{ from: location }} />;
  }

  return children;
};

export default ValidateCheckoutRoute;
