import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   isAnyAccountModuleSelectedMobile : false
}



const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        reset: (state) => {
            state.isAnyAccountModuleSelectedMobile = false;
            
        },
        setIsAnyAccountModuleSelectedMobile : (state,action) => {
            state.isAnyAccountModuleSelectedMobile = action.payload
        }

    },
   
})

export const { reset , setIsAnyAccountModuleSelectedMobile } = accountSlice.actions;
export const accountSelector = (state) => state.account;
export default accountSlice.reducer;