import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SkeletonLoader from "../../common/SkeletonLoaders/SkeletonLoaders";
import {
  getServiceCenterDetails,
  setShowMoreServices,
  updateServiceCenters,
  updateShowSuggestedServiceCentersFlag,
  updateGarageDetailsFlag,
} from "../../features/garage/garageSlice";
import { getServiceCenterAllServices, updateServicePackagesList } from "../../features/servicepackage/servicepackageSlice";
import {
  carspaserviceselector,
  updateSpaDetailsFlag,
} from "../../features/spa/spaserviceSlice";
import { CommonServices } from "../../utils/commonServices";
import "./carSpa.css";
import { updatecategoryselected } from "../../features/emergency/emergencySlice";

function CarSpa(props) {
  const { carSpasNearMeList, isLoading } = useSelector(carspaserviceselector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [carSpasList, setCarSpasList] = useState([]);
  const reviews = [1, 2, 3, 4, 5];
  const commonServices = new CommonServices();

  useEffect(() => {
    setCarSpasList(carSpasNearMeList);
  }, [carSpasNearMeList]);

  const body = {
    vehicleType: "",
    customerVehicleId: "",
    vehicleTypeId: "",
    vehicleModelId: "",
    vehicleFueltypeId: "",
    vehicleBodyTypeId: "",
    servicesId: "",
  };

  const options = {
    items: 4,
    nav: true,
    navText: [
      "<div className='nav-btn prev-slide'></div>",
      "<div className='nav-btn next-slide'></div>",
    ],
    loop: false,
    dots: false,
    autoplay: true,
    responsiveBaseElement: "body",
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 2,
      },
      980: {
        items: 3,
      },
      1199: {
        items: 4,
      },
    },
  };

  const handleClick = (carSpa) => {
    const vehicle = commonServices.getLocalStrgJSON("MMX_VEHICLE");
    if (vehicle === null) {
      toast.info("Select your vehicle", {
        toastId: "selectVehicle",
        autoClose: 2000,
      });
      window.scrollTo(0, 0);
    } else {
      dispatch(getServiceCenterDetails(carSpa?.serviceCenterId));
      dispatch(
        getServiceCenterAllServices({
          serviceCenterId: carSpa?.serviceCenterId,
        })
      );
      localStorage.removeItem('MMX_SERVICE_DETAILS');
      localStorage.removeItem("MMX_SERVICE");
      commonServices.setLocalStrg("MMX_SERVICE_CENTER", JSON.stringify({serviceCenterId:carSpa.serviceCenterId,service_center_dispaly_name:carSpa.name}));
      dispatch(updateServicePackagesList([]))
      dispatch(updatecategoryselected(null))
      dispatch(updateSpaDetailsFlag(true));
      dispatch(updateGarageDetailsFlag(false));
      dispatch(setShowMoreServices(true));
      dispatch(updateShowSuggestedServiceCentersFlag(true));
      // const newArray = carSpasNearMeList.filter((center) => {
      //   if (center.serviceCenterId != carSpa.serviceCenterId) {
      //     return center;
      //   }
      // });
      dispatch(updateServiceCenters(carSpasNearMeList));
      navigate("/services/service-centers");
    }
  };

  return (
    <React.Fragment>
      <link
        href="assets/css/spa_services.css"
        type="text/css"
        rel="stylesheet"
      ></link>
      <div className="spa my-4">
        <div className="row">
          <div className="col-md-12">
            <h4>CAR SPAS NEAR YOU</h4>
            <p>
              Search from our service centres and book a slot closest to you and
              at your convenience
            </p>
            <OwlCarousel className="owl-carousel owl-theme" {...options}>
              {carSpasNearMeList &&
                carSpasNearMeList.map((carservice, i) => {
                  // Return the element. Also pass key
                  return (
                    <div
                      key={i}
                      className="post-slide pointer"
                      onClick={() => {
                        handleClick(carservice);
                      }}
                    >
                      <div className="post-img">
                        <img
                          src="/assets/imgs/car-spa/carSpa.png"
                          // src={garage.garagePic}
                          alt=""
                        />
                        {/* <img src={carservice.garagePic} alt="" /> */}
                      </div>
                      <div className="post-content ">
                        <div class="d-flex align-items-center justify-content-start">
                          <ul>
                            {reviews.map((item) => {
                              return (
                                <>
                                  {carservice?.overAllRatingData?.avarageRate !=
                                    0 &&
                                  item <=
                                    carservice?.overAllRatingData
                                      ?.avarageRate ? (
                                    // <li>
                                    <img
                                      class="rating"
                                      style={{ width: "12px" }}
                                      src="assets/icons/star-selected.svg"
                                    ></img>
                                  ) : (
                                    <img
                                      class="rating blue-star"
                                      style={{ width: "12px" }}
                                      src="assets/icons/star.svg"
                                    ></img>
                                  )}
                                </>
                              );
                            })}
                          </ul>
                          {carservice?.overAllRatingData?.totalRatedUsers >
                          0 ? (
                            <p class="m-0 reviews">
                              ({carservice.overAllRatingData.totalRatedUsers} -
                              reviews)
                            </p>
                          ) : (
                            <p class="m-0 reviews">(0 - reviews)</p>
                          )}
                        </div>

                        <p className="post-title fw-bold m-0">
                          {carservice.name}
                        </p>
                        <p class="m-0">{carservice.distance} Km away</p>
                        <p
                          style={{
                            maxWidth: "100%",
                          }}
                          class="m-0 d-inline-block text-truncate"
                        >
                          {carservice.full_address}
                        </p>
                      </div>
                    </div>
                  );
                })}
              {isLoading &&
                Array(4)
                  .fill(0)
                  .map((item) => {
                    return <SkeletonLoader isCardSkeleton={true} />;
                  })}
            </OwlCarousel>
            {!isLoading &&
              carSpasNearMeList &&
              carSpasNearMeList.length <= 0 && (
                <div class="d-flex align-items-center">
                  <img
                    src="../assets/imgs/garages/automobile_repair_ywci.png"
                    style={{ width: "35%" }}
                  />
                  <p>No Car SPA is available at your location</p>
                </div>
              )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CarSpa;
