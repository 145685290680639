import { HttpClient } from '../utils/httpClient';
import { API_URL } from '../config';

class PaymentPageService {
    constructor() {
        this.httpClient = new HttpClient(API_URL, { device: "mobile", outside: '1' });
    }

    async getOrderDetailsByOrderNumber(orderNumber) {
        try {
            const response = await this.httpClient.get(API_URL + 'outside/order-details/' + orderNumber);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async initializeOrderPayment(orderNumber) {
        try {
            const response = await this.httpClient.post(API_URL + 'outside/order-payment-init/' + orderNumber);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async orderPaymentUpdate(data) {
        try {
            const response = await this.httpClient.post(API_URL + 'outside/order-payment-update/' + data.orderId, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export { PaymentPageService }