import React, { useCallback, useEffect, useState } from 'react';
import "../MobileFooter/MobileFooter.css";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoginOrRegistrationModalComponent } from '../../components/Login/Login';
import SearchServicesModal from '../../components/SearchServicesModal/SearchServicesModal';
import { setActiveModule } from '../../features/account/customerProfileSlice';
import { authSelector, logout } from '../../features/auth/authSlice';
import {
    cartSelector,
    setOpenCartPage
} from "../../features/cart/cartSlice";
import { updateCloseMenuFlag } from '../../features/home/homeSlice';

const MobileFooter = () => {

    const { cartItemsCount } = useSelector(cartSelector)
    const { isAuthenticated } = useSelector(authSelector)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedPage, setSelectedPage] = useState("")
    const [showLoginModal, setShowLoginModal] = useState(false)
    const [showSearchServicesModal, setShowSearchServicesModal] = useState(false)

    const logOut = useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    const handleClick = (item, path="") => {
        setSelectedPage(item)
        navigate(path)
        dispatch(updateCloseMenuFlag(true))
        if ((path === "profile" || path === "/profile/myvehicles") && !isAuthenticated) {
            setShowLoginModal(true)
        }
    }

    useEffect(() => {
        if (isAuthenticated) {
            setShowLoginModal(false)
        }
    }, [isAuthenticated])

    return (
        <div className="footer">
            <footer className="fixed-bottom">
                <div class="col-sm-12 elements">
                    <div>
                        <button onClick={() => {
                            handleClick("fa-home", "home")
                        }}>
                            <a href='#'>
                                <i class={`fas fa-home ${selectedPage === "fa-home" && "selected"}`}></i>
                            </a>
                        </button>
                    </div>
                    <div>
                        <button onClick={() => {
                            handleClick("fa-car", "/profile/myvehicles")
                            dispatch(setActiveModule("My Vehicles"));
                        }}>
                            <a href='#'>
                                <i class={`fas fa-car  ${selectedPage === "fa-car" && "selected"}`}></i>
                            </a>
                        </button>
                    </div>
                    <div>
                        <button className="navbar-toggler" type="button" aria-expanded="false" aria-label="Toggle navigation"
                            onClick={() => {
                                handleClick("fa-search")
                                setShowSearchServicesModal(true)
                            }}>
                            <a href='#'>
                                <i class={`fas fa-search ${selectedPage === "fa-search" && "selected"}`}></i>
                            </a>
                        </button>
                        {showSearchServicesModal && <SearchServicesModal handleClose={() => { setShowSearchServicesModal(false) }} isSearchEnabled={true} />}
                    </div>
                    <div class="position-relative">
                        <button onClick={() => {
                            handleClick("fa-shopping-cart", "services/mycart")
                            dispatch(setOpenCartPage(true))
                        }}>
                            <a href='#'>
                                <i class={`fas fa-shopping-cart ${selectedPage === "fa-shopping-cart" && "selected"}`}></i>
                            </a>
                            {cartItemsCount > 0 && <span
                                class="footer-cart-count"
                            >{cartItemsCount}</span>}
                        </button>
                    </div>
                    <div>
                        <button onClick={() => {
                            handleClick("fa-user-circle", "profile")
                        }}>
                            <a href='#'>
                                <i class={`fas fa-user-circle ${selectedPage === "fa-user-circle" && "selected"}`}></i>
                            </a>
                        </button>
                    </div>
                </div>
            </footer>
            {showLoginModal && <LoginOrRegistrationModalComponent show={showLoginModal}
                setShow={() => {
                    setShowLoginModal(false);
                }} />}
        </div>
    );
};

export default MobileFooter;