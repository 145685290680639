/* eslint-disable no-useless-catch */


import { SERVICES_LIST_WITH_PAGINATION, ALL_SERVICES } from '../config/constants';
import { CommonServices } from '../utils/commonServices';
import { HttpClient } from '../utils/httpClient';

class ServicePackageService {
    constructor() {
        this.httpClient = new HttpClient();
        this.commonServices = new CommonServices();
    }

    async getservices(data) {
        try {
            const body = {
                length: data?.length || 20,
                start: data?.start || 0,
                filter: {
                    value: data?.value ? data.value : "",
                    serviceCatId: data ? data.serviceCatId : "",
                }
            }
            const response = await this.httpClient.post(SERVICES_LIST_WITH_PAGINATION, body);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getServiceCenterAllServices(serviceCenterId) {
        try {
            const service = this.commonServices.getLocalStrgJSON("MMX_SERVICE_DETAILS");
            const vehicle = this.commonServices.getLocalStrgJSON("MMX_VEHICLE");
            const data = {
                customerVehicleId:
                    this.commonServices.getLocalStrgJSON("MMX_SELECTED_VEHICLE")
                        ?.customerVehicleId || "",
                vehicleTypeId: this.commonServices.getLocalStrg("VEHICLE_TYPE"),
                vehicleModelId: JSON.parse(vehicle.selectedmodel).vehicleModelId,
                vehicleFueltypeId: JSON.parse(vehicle.selectedfueltype).fueltypeId,
                vehicleBodyTypeId: JSON.parse(vehicle.selectedmodel).vehicleBodyTypeId,
                // servicesId: "",
                servicesId: service?.servicesId,
            };
            const response = await this.httpClient.post(ALL_SERVICES + `${serviceCenterId}`, data)
            return response;
        } catch (error) {
            throw error
        }
    }
}

export { ServicePackageService };
