import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  displayLogoutModal,
  customerProfileSelector,
  setActiveModule,
} from "../../features/account/customerProfileSlice";
import { logout } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { showLogoutModal } = useSelector(customerProfileSelector);

  const closeModal = () => {
    window.scrollTo(0, 0);
    dispatch(displayLogoutModal(false));
  };

  return (
    <>
      <div class="">
        <Modal className="logout-modal" show={showLogoutModal} centered>
          <Modal.Body
            style={{
              height: "fit-content",
              //   borderRadius: "15px",
            }}
          >
            <div>
              <div class="d-flex justify-content-end">
                <img
                  src="../assets/icons/cancel.png"
                  style={{ width: "33px" }}
                  class="pointer"
                  onClick={() => {
                    // navigate("/profile");
                    // dispatch(setActiveModule("Profile Details"));
                    closeModal();
                  }}
                ></img>
              </div>
              <div class="d-flex justify-content-around">
                <img
                  src="/assets/imgs/account/logoutImage.png"
                  class="w-25"
                  alt=""
                />
              </div>
              <div class="my-4">
                <p class="m-0 text-center message ">
                  Are you sure you want to logout
                </p>

                <p class="m-0 text-center message ">from MetaMechX ?</p>
              </div>
              <div class="mb-4 d-flex justify-content-center">
                <button
                  class="cancel-btn"
                  onClick={() => {
                    // navigate("/profile");

                    // dispatch(setActiveModule("Profile Details"));
                    closeModal();
                  }}
                >
                  Cancel
                </button>
                <button
                  class="confirm-btn"
                  onClick={() => dispatch(logout(false))}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Logout;
