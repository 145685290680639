import { HttpClient } from '../utils/httpClient';
import { VEHICLE_LIST, VEHICLE_ADD, VEHICLE_UPDATE, VEHICLE_DELETE, } from '../config/constants';
class VehicleService {
    constructor() {
        this.httpClient = new HttpClient();
    }

    async getUserVehiclesList() {
        try {
            const body = {
                // "length": 4,
                "start": 0,
                "search": ""
            }
            const response = await this.httpClient.post(VEHICLE_LIST, body);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
    async addVehicle(body) {
        try {
            const response = this.httpClient.post(VEHICLE_ADD, body)
            return response;
        } catch (error) {
            throw error;
        }
    }

    async updateVehicle(data) {
        try {
            const { registration_no, customerVehicleId } = data
            const response = this.httpClient.put(VEHICLE_UPDATE + customerVehicleId, { registration_no })
            return response;
        } catch (error) {
            throw error;
        }
    }

    async removeVehicle(vehicleId) {
        try {
            const response = this.httpClient.delete(VEHICLE_DELETE + vehicleId)
            return response;
        } catch (error) {
            throw error;
        }
    }
}
export { VehicleService }