import React, { useEffect, useState } from "react";
import { CommonServices } from "../../utils/commonServices";
import "./MyAddresses.css";

const DisplayMySavedAddresses = React.memo((props) => {
  const {
    handleDelete,
    handleEdit,
    handleSetDefault,
    myAddressesList,
    defaultAddress,
  } = props;

  const commonServices = new CommonServices();

  useEffect(() => { }, []);

  const isDefaultAddress = (data) => {
    if (defaultAddress) {
      if (data.customerAddressId === defaultAddress) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }

  };

  return (
    <>
      {myAddressesList &&
        myAddressesList.map((address) => {
          return (
            <>
              <div
                class="col-md-6 col-sm-12 col-lg-6"
                style={{ height: "12rem" }}
              >
                <div class="addressCard p-4 border_radius common_border ps-3 pointer">
                  <div class="row">
                    <div class="col-lg-11 col-sm-10">
                      <h6>{address.addressName}</h6>
                      <div
                        style={{
                          height: "60px",
                          overflow: "hidden",
                          overflowY: "auto",
                        }}
                      >
                        <p style={{ fontSize: "12px" }}>{address.Location}</p>
                      </div>
                    </div>

                    <div class="col-lg-1 col-md-2 col-sm-2">
                      <i
                        class="fas fa-trash-alt pointer"
                        title="Remove"
                        onClick={() => handleDelete(address)}
                      ></i>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <button
                        class="border-0 common-btn px-3 py-1 addressBtns"
                        onClick={() => handleEdit(address)}
                      >
                        Edit
                      </button>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-8">
                      <button
                        class={`border-0 common-btn px-3 py-1 addressBtns ${isDefaultAddress(address) && "defaultButton"
                          }`}
                        onClick={() => handleSetDefault(address)}
                      >
                        {isDefaultAddress(address)
                          ? "Default Address"
                          : "Make as default"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
    </>
  );
});

export default DisplayMySavedAddresses;
