import * as moment from "moment";
import { useEffect, useState, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import {
  addressSelector,
  getAddressesList,
  createAddress,
  updateAddress,
} from "../../features/account/addressSlice";
import { cartSelector } from "../../features/cart/cartSlice";
import {
  checkoutSelector,
  getServiceCenterAvailableSlots,
  setPayOnCompletionTrue,
  updateAvailableSlots,
  updateEnableBookingButton,
  updateSelectedAddress,
  updateSelectedSchedule,
} from "../../features/Checkout/checkoutslice";
import { CommonServices } from "../../utils/commonServices";
import Geocode from "react-geocode";
import "./Checkout.css";
import { STATUS_CODES } from "../../config/constants";
import { updateRegistrationNumber } from "../../features/account/vehicleSlice";
import { getCustomerDetails } from "../../features/account/customerProfileSlice";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const commonServices = new CommonServices();
  const [progressBarWidth, setProgressbarWidth] = useState("0%");
  const [selectSchedule, setSelectSchedule] = useState(false);
  const [isScheduleError, setScheduleError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [enableNextButton, setEnableNextButton] = useState(false);
  const [selectAddressFlag, setSelectAddress] = useState(false);

  const [selectPayment, setSelectPayment] = useState(false);

  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedDeliveryMode, setSelectedDeliveryMode] = useState(null);
  const [slotDates, setSlotDates] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [locality, setLocality] = useState(null);
  const [street, setStreet] = useState(null);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [addressType, setAddresstype] = useState("");
  const [isInvalidVehicleNum, setIsInvaliVehicleNum] = useState(false);

  const { createAddressResponse } = useSelector(addressSelector);
  const { serviceCenterAvailableSlots, isAvailableSlotsLoading } =
    useSelector(checkoutSelector);
  const { cartDetails } = useSelector(cartSelector);
  const serviceCenterId = commonServices.getLocalStrg(
    "MMX_CURRENT_CART_SERVICECENTER"
  );

  // const vehicleNumberRef = useRef(null);
  const [vehicleNumberRef, setVehicleNumRef] = useState(null);

  useEffect(() => {
    const slotDates = [];
    for (let i = 0; i <= 12; i++) {
      const date = moment().add(i, "day");
      slotDates.push({
        slot_date: date.format("YYYY-MM-DD"),
        date: date.format("DD"),
        day: date.format("ddd"),
        month: date.format("MMM"),
        active: true,
      });
    }

    setSlotDates(slotDates);
    dispatch(getAddressesList());
    const selectedAddress = commonServices.getLocalStrg("MMX_ADDRESS");
    const isAddressSaved = commonServices.getLocalStrg("MMX_ISADDRESSSAVED");
    if (isAddressSaved) {
      const savedAddress = commonServices.getLocalStrgJSON("MMX_CRRADDRESS");
      if (savedAddress.AddressType == 1) setAddresstype("1");
      if (savedAddress.AddressType == 2) setAddresstype("2");
      if (savedAddress.AddressType == 3) setAddresstype("3");
      if (savedAddress.AddressType == 4) setAddresstype("4");
    }
    const selectedVehicle = commonServices.getLocalStrgJSON(
      "MMX_SELECTED_VEHICLE"
    );
    setVehicleNumRef(
      selectedVehicle?.registration_no ? selectedVehicle?.registration_no : ""
    );
    const pincode = commonServices.getLocalStrg("MMX_USER_PINCODE");
    setSelectedLocation(selectedAddress + " " + pincode);
    const vehicle = commonServices.getLocalStrgJSON("MMX_VEHICLE");
    setSelectedVehicle(vehicle);

    // getCustomerDetails
    dispatch(getCustomerDetails());
    dispatch(updateEnableBookingButton(false));
  }, []);

  useEffect(() => {
    if (slotDates?.length > 0) {
      dispatch(
        getServiceCenterAvailableSlots({
          slot_date: slotDates[0].slot_date,
          serviceCenterId: serviceCenterId,
        })
      );
      setSelectedDay(slotDates[0]);
    }
  }, [slotDates]);

  const options = {
    // items: 4,
    nav: true,
    navText: [
      "<div className='nav-btn prev-slide'></div>",
      "<div className='nav-btn next-slide'></div>",
    ],
    loop: false,
    dots: false,
    autoplay: false,
    responsiveBaseElement: "body",
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      980: {
        items: 4,
      },
      1199: {
        items: 5,
      },
    },
  };

  useEffect(() => {
    if (selectedDay !== null) {
      setEnableNextButton(false);

      dispatch(
        getServiceCenterAvailableSlots({
          slot_date: selectedDay.slot_date,
          serviceCenterId: serviceCenterId,
        })
      );
      setSelectedSlot(null);
    }
  }, [selectedDay]);

  useEffect(() => {
    if (
      selectedDay !== null &&
      selectedDeliveryMode !== null &&
      selectedSlot !== null
    ) {
      setEnableNextButton(true);
      setScheduleError(false);
      setSelectSchedule(false);
    }
  }, [selectedDay, selectedSlot, selectedDeliveryMode]);

  const handleSelectedDay = (currentSelectedDay) => {
    if(selectedDay !== currentSelectedDay){
      dispatch(updateAvailableSlots(null));
      setSelectedSlot(null);
    }
    setSelectedDay(currentSelectedDay);
  };

  const handleSelectAddress = () => {
    if (selectedDay === null) {
      setScheduleError(true);
      setErrorMessage("Please Select Day!");
    } else if (selectedSlot === null) {
      setScheduleError(true);
      setErrorMessage("Please Select Slot Date!");
    } else if (selectedDeliveryMode === null) {
      setScheduleError(true);
      setErrorMessage("Please Select Delivery Mode!");
    } else {
      setScheduleError(false);
      const scheduleData = {
        selectedDay,
        selectedSlot,
        selectedDeliveryMode,
      };
      dispatch(updateSelectedSchedule(scheduleData));
      setProgressbarWidth("33%");
      setSelectAddress(true);
    }
  };
  const validateVehicleNum = (event) => {
    const max = 10
    let num = event.target.value.substring(0, max);
    setVehicleNumRef(num);
    let value = event.target.value;
    let Number = value.toUpperCase()
    // const expr = /^[A-Za-z]{2}[0-9]{2}[A-Za-z]{2}[0-9]{4}$/;
    let NIC_REGEX_OLD = /^[A-Z]{2}[0-9]{1,2}[A-Z]{1,2}[0-9]{4}$/;
    let NIC_REGEX_NEW = /^[A-Z]{2}[0-9]{6,8}$/;
    if (NIC_REGEX_OLD.test(Number) || NIC_REGEX_NEW.test(Number)) {
      setIsInvaliVehicleNum(false);
      setErrorMessage(null);
    }else {
      setIsInvaliVehicleNum(true);
      setErrorMessage("Invalid Vehicle Number");
    }
    
  };

  const gotoPayment = () => {
    let Number = vehicleNumberRef.toUpperCase();

    // const expr = /^[A-Za-z]{2}[0-9]{2}[A-Za-z]{2}[0-9]{4}$/;
    let NIC_REGEX_OLD = /^[A-Z]{2}[0-9]{1,2}[A-Z]{1,2}[0-9]{4}$/;
    let NIC_REGEX_NEW = /^[A-Z]{2}[0-9]{6,8}$/;
    if (NIC_REGEX_OLD.test(Number) || NIC_REGEX_NEW.test(Number)) {
      setProgressbarWidth("66%");
      setIsInvaliVehicleNum(false);
      setErrorMessage(null);
      let selectedAddress = "";
      if (locality) {
        selectedAddress += locality + ", ";
      }
      if (street) {
        selectedAddress += street + ", ";
      }
      selectedAddress += selectedLocation;
      dispatch(updateSelectedAddress(selectedAddress));
      setSelectedAddress(selectedAddress);
      setSelectPayment(true);
      dispatch(updateRegistrationNumber(Number));
      dispatch(updateEnableBookingButton(true));
      if (commonServices.getLocalStrg("MMX_ISADDRESSSAVED") === "false") {
        const latitude = commonServices.getLocalStrgJSON("MMX_USER_LATLON").lat;
        const longitude =
          commonServices.getLocalStrgJSON("MMX_USER_LATLON").lng;
        getAddressDetails(latitude, longitude);
      } else {
        const savedAddress = commonServices.getLocalStrgJSON("MMX_CRRADDRESS");
        dispatch(
          updateAddress({
            ...savedAddress,
            AddressType: addressType,
            Address1: locality ? locality : savedAddress.Address1,
            Address2: street ? street : savedAddress.Address2,
            customerAddressId: commonServices.getLocalStrg("MMX_ADDRESSID"),
          })
        );
      }
    } else {
      setIsInvaliVehicleNum(true);
      setErrorMessage("Invalid Vehicle Number");
      window.scrollTo(0, 0);
    }
  };

  const getAddressDetails = (latitude, longitude) => {
    Geocode.fromLatLng(latitude, longitude).then(
      (response) => {
        if (response.status === "OK") {
          if (response.results.length > 0) {
            response.results[0].address_components.forEach((element) => {
              element.types.forEach((s) => {
                if (s == "country") {
                  setCountry(element.long_name);
                }
                if (s == "administrative_area_level_1") {
                  setState(element.long_name);
                }
                if (s == "locality") {
                  setCity(element.long_name);
                }
              });
            });
          } else {
            commonServices.consoleError(
              "Error while getting google place results",
              response.results
            );
          }
        } else {
          commonServices.consoleError(
            "Error while getting google place",
            response.status
          );
        }
      },
      (error) => {
        console.error(error);
      }
    );
  };

  useEffect(() => {
    if (country && state && city) {
      const body = {
        Location: commonServices.getLocalStrg("MMX_ADDRESS"),
        Latitude: commonServices.getLocalStrgJSON("MMX_USER_LATLON").lat,
        Longitude: commonServices.getLocalStrgJSON("MMX_USER_LATLON").lng,
        Address1: locality,
        Address2: street,
        Address3: "",
        AddressType: addressType,
        SaveAs: "",
        PinCode: commonServices.getLocalStrg("MMX_USER_PINCODE"),
        Country: country,
        State: state,
        City: city,
      };
      dispatch(createAddress(body));
    }
  }, [country, state, city]);

  useEffect(() => {
    if (
      createAddressResponse &&
      createAddressResponse.statuscode === STATUS_CODES[200]
    ) {
      commonServices.getLocalStrg(
        "MMX_ADDRESSID",
        createAddressResponse.data.customerAddressId
      );
      commonServices.setLocalStrg("MMX_ISADDRESSSAVED", "true");
    }
  }, [createAddressResponse]);

  const handleChangeAddress = () => {
    setSelectPayment(false);
    setProgressbarWidth("33%");
    dispatch(updateEnableBookingButton(false));
  };

  return (
    <>
      <div class="checkout">
        <h5>CHECKOUT</h5>
        <div class="px-9 steps">
          <div className="progressBar">
            <div
              className="progress"
              id="progress"
              style={{ width: progressBarWidth }}
            ></div>
            <div
              className="progress-step progress-step-active"
              data-title="Schedule"
            ></div>
            <div
              className={`progress-step ${selectAddressFlag && "progress-step-active"
                }`}
              data-title="Address"
            ></div>
            <div
              className={`progress-step ${selectPayment && "progress-step-active"
                }`}
              data-title="Payment"
            ></div>
            <div className="progress-step" data-title="Order Initiated"></div>
          </div>
        </div>
        <div class="mt-5">
          <div class="px-4 py-3 my-3 common_border rounded-3">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <p class="mb-3">
                  <strong>VEHICLE DETAILS</strong>
                </p>
                {selectedVehicle && (
                  <p class="m-0 ">
                    <i class="fas fa-car"></i>
                    {selectedVehicle.selectedvehicle}
                  </p>
                )}
              </div>
              <div class="col-lg-6 col-md-6">
                <p class="mb-2">
                  <strong>Enter Vehicle number</strong>
                </p>
                <input
                style={{textTransform:"uppercase"}}
                  type="text"
                  class="common_border rounded-3 fw-500 p-1"
                  placeholder="Ex: AA11BB2354"
                  autoComplete="off"
                  value={vehicleNumberRef}
                  text
                  onChange={(event) => {
                    setVehicleNumRef(event.target.value);
                    validateVehicleNum(event);
                  }}
                  disabled={selectPayment}
                // value={vehicleNumberRef?.current?.value}
                />
                {isInvalidVehicleNum && (
                  <p class="" style={{ color: "red" }}>
                    * {errorMessage}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div
            class="px-4 py-3 my-3 common_border rounded-3 schedule"
            onClick={() => {
              setSelectSchedule(true);
            }}
          >
            {!selectSchedule && selectedDay == null && (
              <p class="headings">SCHEDULE YOUR SERVICE</p>
            )}
            {(selectSchedule || selectedDay !== null) && (
              <>
                <div class="d-flex align-items-center justify-content-between">
                  <h6>SCHEDULE YOUR SERVICE</h6>
                  {selectAddressFlag && (
                    <p
                      class="change m-0 pointer"
                      onClick={() => {
                        setSelectAddress(false);
                        setProgressbarWidth("0%");
                      }}
                    >
                      Change
                    </p>
                  )}
                </div>
                { }
                <hr></hr>
                {!selectAddressFlag ? (
                  <div class="ps-1">
                    <div className="select-date">
                      <div class="row d-flex align-items-center justify-content-start">
                        <p class="m-0 col-sm-12">Select Date *</p>
                      </div>
                      <div class="row w-100 p-3 d-flex m-0">
                        <OwlCarousel
                          className="owl-carousel owl-theme"
                          {...options}
                        >
                          {slotDates?.length > 0 &&
                            slotDates.map((item) => {
                              return (
                                <>
                                  <div
                                    className={`slot-day pointer col-lg-12 common_border rounded-3 text-center me-3 fw-bold ml-2 w-75 m-auto ${selectedDay &&
                                      selectedDay.date === item.date &&
                                      "active-item"
                                      }`}
                                    style={{
                                      padding: "6px",
                                      fontSize: "14px",
                                    }}
                                    onClick={() => {
                                      handleSelectedDay(item);
                                    }}
                                  >
                                    <p class="w-auto m-auto">{item.month}</p>
                                    <p class="w-auto m-auto">
                                      {item.date}, &nbsp;
                                      {item.day}
                                    </p>
                                  </div>
                                </>
                              );
                            })}
                        </OwlCarousel>
                      </div>
                    </div>
                    <div className="select-timeslot">
                      <p class="m-0 col-sm-12">Select Time Slot *</p>
                      <div class="row time-slots p-3">
                        {!isAvailableSlotsLoading &&
                          serviceCenterAvailableSlots?.length > 0 &&
                          serviceCenterAvailableSlots.map((slot, i) => {
                            return (
                              <>
                                <div
                                  className={` col-lg-2 common_border rounded-3 text-center m-2 fw-bold ${selectedSlot &&
                                    selectedSlot.slotId === slot.slotId &&
                                    "active-item"
                                    } ${!slot.disabled
                                      ? "time-slot pointer"
                                      : "not-available-deliveryMode"
                                    }`}
                                  style={{
                                    width: "100px",
                                    height: "35px",
                                    padding: "6px",
                                    fontSize: "11px",
                                  }}
                                  onClick={() => {
                                    if (!slot.disabled) {
                                      setSelectedSlot(slot);
                                    }
                                  }}
                                  id={i}
                                >
                                  {slot.from_time} - {slot.to_time}
                                </div>
                              </>
                            );
                          })}
                        {isAvailableSlotsLoading && (
                          <div class="row">
                            {Array(6)
                              .fill(0)
                              .map(() => {
                                return (
                                  <>
                                    <div className="col-lg-2 col-sm-6">
                                      <Skeleton height={30} />
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="delivery-mode">
                      <p class="mt-0 ">Select Delivery Mode *</p>
                      <div class="row d-flex align-items-center justify-content-between mb-3">
                        <div class="col-lg-4 col-md-5 col-sm-12">
                          <input
                            type="radio"
                            name="delivery-mode"
                            class={`common_border form-check-input ${!cartDetails?.data?.is_pickup_available &&
                              "not-available-deliveryMode"
                              } `}
                            value="1"
                            checked={selectedDeliveryMode === "1"}
                            onChange={(e) => {
                              if (cartDetails?.data?.is_pickup_available) {
                                setSelectedDeliveryMode(e.target.value);
                              }
                            }}
                          />
                          <label
                            class={`mx-1 fw-600 pointer ${cartDetails?.data?.is_pickup_available
                              ? ""
                              : "not-available-slot"
                              }`}
                            style={{ fontSize: "14px" }}
                            onClick={(e) => {
                              if (cartDetails?.data?.is_pickup_available) {
                                setSelectedDeliveryMode("1");
                              }
                            }}
                          >
                            Pickup And Drop Service
                          </label>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12">
                          <input
                            type="radio"
                            name="delivery-mode"
                            class={`common_border form-check-input ${!cartDetails?.data?.is_doorstep_available &&
                              "not-available-deliveryMode"
                              }`}
                            value="2"
                            checked={selectedDeliveryMode === "2"}
                            onChange={(e) => {
                              if (cartDetails?.data?.is_doorstep_available) {
                                setSelectedDeliveryMode(e.target.value);
                              }
                            }}
                          />
                          <label
                            class={`mx-1 fw-600 pointer ${cartDetails?.data?.is_doorstep_available
                              ? ""
                              : "not-available-slot"
                              }`}
                            style={{ fontSize: "14px" }}
                            onClick={(e) => {
                              if (cartDetails?.data?.is_doorstep_available) {
                                setSelectedDeliveryMode("2");
                              }
                            }}
                          >
                            Door Step Delivery
                          </label>
                        </div>
                        <div class="col-lg-3 m-0 col-md-3 col-sm-12">
                          <input
                            type="radio"
                            name="delivery-mode"
                            class="common_border form-check-input"
                            value="3"
                            checked={selectedDeliveryMode === "3"}
                            onChange={(e) => {
                              setSelectedDeliveryMode(e.target.value);
                            }}
                          />
                          <label
                            class="mx-1 fw-600 pointer"
                            style={{ fontSize: "14px" }}
                            onClick={(e) => {
                              setSelectedDeliveryMode("3");
                            }}
                          >
                            Walk In
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-end">
                      <div class="d-flex align-items-center justify-content-end">
                        <button
                          className={` common-btn border-0 rounded-3 p-1 ${!enableNextButton ? "next-disabled" : "next-btn"
                            }`}
                          onClick={handleSelectAddress}
                          id="button1"
                          data-testid="button1"
                        >
                          Next &nbsp;
                          <i class="fas fa-long-arrow-alt-right"></i>
                        </button>
                      </div>
                    </div>
                    {isScheduleError && (
                      <p class="" style={{ color: "red" }}>
                        * {errorMessage}
                      </p>
                    )}
                  </div>
                ) : (
                  <>
                    <div class="row">
                      <div class="col-lg-4 col-md-6 m-0">
                        <strong>Date:</strong> {selectedDay?.date}-
                        {selectedDay?.month}
                      </div>
                      <div class="col-lg-4 col-md-6">
                        <strong> Time Slot:</strong> {selectedSlot?.from_time}-
                        {selectedSlot?.to_time}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
          <div class="px-4 py-3 my-3 common_border rounded-3 select_address">
            {!selectAddressFlag && selectedAddress == null && (
              <h6>SELECT / ADD ADDRESS</h6>
            )}
            {(selectAddressFlag || selectedAddress !== null) && (
              <>
                <div class="d-flex align-items-center justify-content-between">
                  <h6>SELECT ADDRESS</h6>
                  {selectPayment && (
                    <p
                      class="change m-0 pointer"
                      onClick={() => {
                        handleChangeAddress();
                      }}
                    >
                      Change
                    </p>
                  )}
                </div>
                { }
                <hr></hr>
                {!selectPayment ? (
                  <>
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center common_border py-2 px-3 rounded-3 mb-2">
                        <div class="pe-2">
                          <i class="fas fa-map-marker-alt"></i>
                        </div>
                        <div>
                          <p class="m-0 fw-bold">PickUp address::</p>
                          <p class="m-0">{selectedLocation}</p>
                        </div>
                      </div>
                    </div>
                    <div class=" col-sm-12 col-lg-12">
                      <p class="fw-bold">Edit Address</p>
                      <div style={{ display: "flex" }}>
                        <div class='col-lg-6' style={{ marginRight: "10px" }}>
                          <input
                            type="text"
                            placeholder="Enter Locality (Optional) "
                            value={locality}
                            onChange={(event) => {
                              setLocality(event.target.value);
                            }}
                            class={`address-field common_border rounded-1 p-2 mb-3`}
                          />
                        </div>
                        <div class='col-lg-6'>
                          <input
                            type="text"
                            placeholder="Flat No / Street No (Optional)"
                            value={street}
                            onChange={(event) => {
                              setStreet(event.target.value);
                            }}
                            class={`address-field common_border rounded-1  p-2 mb-3`}
                          />
                        </div>
                      </div>
                      <div class="vehicle-delivery-type row d-flex w-75 align-items-center justify-content-between mb-3">
                        <div class="col-lg-2 col-md-6 col-sm-12">
                          <input
                            type="radio"
                            name="address-type"
                            class={`common_border form-check-input `}
                            value="1"
                            checked={addressType === "1"}
                            onChange={(e) => {
                              setAddresstype("1");
                            }}
                          />
                          <label
                            class={`mx-1 fw-600 pointer`}
                            style={{ fontSize: "14px" }}
                            onClick={(e) => {
                              setAddresstype("1");
                            }}
                          >
                            Home
                          </label>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-12">
                          <input
                            type="radio"
                            name="address-type"
                            class={`common_border form-check-input`}
                            value="2"
                            checked={addressType === "2"}
                            onChange={(e) => {
                              setAddresstype(e.target.value);
                            }}
                          />
                          <label
                            class={`mx-1 fw-600 pointer `}
                            style={{ fontSize: "14px" }}
                            onClick={(e) => {
                              setAddresstype("2");
                            }}
                          >
                            Work
                          </label>
                        </div>
                        <div class="col-lg-4 m-0 col-md-6 col-sm-12">
                          <input
                            type="radio"
                            name="address-type"
                            class="common_border form-check-input"
                            value="3"
                            checked={addressType === "3"}
                            onChange={(e) => {
                              setAddresstype("3");
                            }}
                          />
                          <label
                            class="mx-1 fw-600 pointer"
                            style={{ fontSize: "14px" }}
                            onClick={(e) => {
                              setAddresstype("3");
                            }}
                          >
                            Friends & Family
                          </label>
                        </div>
                        <div class="col-lg-2 m-0 col-md-6 col-sm-12">
                          <input
                            type="radio"
                            name="address-type"
                            class="common_border form-check-input"
                            value="4"
                            checked={addressType === "4"}
                            onChange={(e) => {
                              setAddresstype("4");
                            }}
                          />
                          <label
                            class="mx-1 fw-600 pointer"
                            style={{ fontSize: "14px" }}
                            onClick={(e) => {
                              setAddresstype("4");
                            }}
                          >
                            Other
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-end">
                      <div class="d-flex align-items-center justify-content-end">
                        <button
                          className="next-btn common-btn border-0 rounded-3 p-1"
                          onClick={() => {
                            gotoPayment();
                          }}
                          id="button2"
                          data-testid="button2"
                        >
                          Next &nbsp;
                          <i class="fas fa-long-arrow-alt-right"></i>
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div class="d-flex align-items-center">
                    {selectedAddress && <p>{selectedAddress}</p>}
                  </div>
                )}
              </>
            )}
          </div>
          {/* <div class="px-4 py-3 pointer my-3 common_border rounded-3 payment">
            {!selectPayment && selectedPaymentMode == null && (
              <p class="headings">PAYMENT</p>
            )}
            {(selectPayment || selectedPaymentMode !== null) && (
              <>
                <div class="d-flex align-items-center justify-content-between">
                  <h6>SELECT PAYMENT OPTIONS:</h6>
                  {selectPayment && (
                    <p
                      class="common_color m-0"
                      onClick={() => {
                        setSelectPayment(false);
                      }}
                    >
                      Change
                    </p>
                  )}
                </div>
                {}
                <hr></hr>

                <>
                  <div class="row d-flex justify-content-between">
                    <div class="col-lg-4">
                      <ul>
                        {paymentTypes &&
                          paymentTypes.length > 0 &&
                          paymentTypes.map((item) => {
                            return (
                              <li
                                onClick={() => {
                                  handleSelectedPaymentType(item);
                                }}
                              >
                                <div
                                  class={`element row align-items-center p-1 list-item ${
                                    selectedPaymentType &&
                                    selectedPaymentType.method ===
                                      item.method &&
                                    "active-item"
                                  }`}
                                >
                                  <div class="col-lg-3 col-md-3 col-sm-3 m-0">
                                    <i class={item.icon}></i>
                                  </div>
                                  <div class="col-lg-9 col-md-9 col-sm-9">
                                    <p class="m-0">{item.method}</p>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                    <div className="col-lg-7 p-4 right">
                      {selectedPaymentType &&
                        selectedPaymentType.method ===
                          "Credit and Debit Card" && <CreditCardComponent />}
                    </div>
                  </div>
                </>
              </>
            )}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Checkout;
