
import { decode as atob, encode as btoa } from 'base-64'
import moment from 'moment-timezone';
export class CommonServices {
    constructor() {
        this.generateYears()
    }
    generatedYears = [];
    consoleError(message = "", obj) {
        console.error("\n**********************************************************\n");
        console.error(`${message}:::::::::::::::::\n`, obj);
    }

    setDeviceId() {
        let string = this.randomString(30);
        let time = (new Date).getTime();
        let DEVICEID = string + "." + time;
        if (!this.getLocalStrg('MMX_DEVICEID')) {
            this.setLocalStrg("MMX_DEVICEID", DEVICEID);
        }
    }
    randomString(length) {
        var randomChars = '_-abcdefghijklmnopqrstuvwxyz0123456789';
        var result = '';
        for (var i = 0; i < length; i++) {
            result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
        }
        return result;
    }

    setUserLocation(data) {
        this.userLocation = data;
    }

    getUserLocation() {
        return (this.userLocation) ? this.userLocation : this.getLocalStrgJSON('MMX_USER_LATLON');
    }
    setLocalStrg(key, Value) {
        let nval = btoa(btoa(Value));
        localStorage.setItem(key, nval);
    }

    getLocalStrg(key) {
        try {
            if (localStorage.getItem(key) !== null && localStorage.getItem(key) !== undefined) {
                let gval = localStorage.getItem(key);
                return atob(atob(gval));
            } else { return ''; }

        } catch (e) {
            return '';
        }

    }

    getLocalStrgJSON(key) {
        try {
            let strgData = this.getLocalStrg(key);
            if (strgData) {
                return JSON.parse(strgData);
            }
            return null;
        } catch (e) {
            return null;
        }
    }

    generateYears() {

        const currentYear = new Date().getFullYear();
        const startYear = currentYear - 30;
        for (let i = 0; i <= (currentYear - startYear); i++) {
            this.generatedYears.push(`${startYear + i}`);
        }
    }

    years() {
        return this.generatedYears;
    }

    states() {
        const states = ["Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat",
            "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur",
            "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana",
            "Tripura", "Uttar Pradesh", "Uttarakhand"]
        return states;
    }
    numberFormat = (value) =>
        new Intl.NumberFormat("en-IN", {
            style: "currency",
            currency: "INR",
        }).format(value);

    generateUUIDV4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    isValidData(dataToCheck) {
        return dataToCheck !== undefined && dataToCheck !== null && dataToCheck !== '' && Object.keys(dataToCheck).length !== 0;
    }

    formateDate(date) {
        let asiaTimeZone = moment(date).tz("Asia/Kolkata");
        const formattedDate = moment(asiaTimeZone).format('Do MMM YYYY, hh:mm A')
        return formattedDate;
    }
}
