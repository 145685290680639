import { useDispatch, useSelector } from "react-redux";
import {
  termsAndConditionsSelector,
  getTermsAndConditions,
} from "../../features/cms/termsAndConditionsSlice";
import { useEffect } from "react";

const TermsAndConditions = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTermsAndConditions());
  }, []);
  const { termsAndConditionsData } = useSelector(termsAndConditionsSelector);
  return (
    <>
      <div class="p-5 mt-7 terms-conditions">
        <h4 class="text-center text-decoration-underline">
          Terms And Conditions
        </h4>
        <div
          dangerouslySetInnerHTML={{
            __html: termsAndConditionsData?.description,
          }}
          style={{ textAlign: "justify" }}
        ></div>
      </div>
    </>
  );
};

export default TermsAndConditions;
