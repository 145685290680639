import { Navigate, useLocation } from "react-router-dom";
import { authSelector, logout } from "../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";

// import { isAuthorized } from '../config/NavConfig';
// import { isValidToken } from "../utils/user";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated, user } = useSelector(authSelector);
  const dispatch = useDispatch();

  if (!isAuthenticated && !user) {
    return <Navigate to="/home" state={{ from: location }} />;
  } else {
    // if (!isValidToken(user)) {
    //   dispatch(logout());
    // }
    // !isAuthorized(user, location.pathname) && (
    //   <Navigate to="/login" state={{ from: location }} />
    // );
  }

  return children;
};

export default ProtectedRoute;
