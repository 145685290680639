import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { act } from 'react-dom/test-utils';
import { STATUS_CODES } from '../../config/constants';
import { CartService } from '../../services/cart.service';
import { CommonServices } from '../../utils/commonServices';
const commonServices = new CommonServices();

const initialState = {
    isSuccess: false,
    isError: false,
    isLoading: false,
    addToCartResponse: null,
    deleteCartResponse: null,
    removeCartItemResp: null,
    successResponse: null,
    errorResponse: null,
    cartDetails: null,
    serviceCount: 0,
    opencartPage: false,
    updateCartResponse: null,
    cartItemsCount: commonServices.getLocalStrg("MMX_CART_COUNT") || 0,
    updateCartVehicleIdResp: null
};

export const addToCart = createAsyncThunk('cart/addToCart', async (data, thunkAPI) => {
    const cartService = new CartService();
    try {
        const response = await cartService.addToCart(data)
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const getCartItems = createAsyncThunk('cart/getCartItems', async (data, thunkAPI) => {
    const cartService = new CartService();
    try {
        const response = await cartService.getCartItems()
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const getServiceCount = createAsyncThunk('cart/getServiceCount', async (data, thunkAPI) => {
    const cartService = new CartService();
    try {
        const response = await cartService.getServiceCount(data)
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const deleteCart = createAsyncThunk('cart/deleteCart', async (data, thunkAPI) => {
    const cartService = new CartService();
    try {
        const response = await cartService.deleteCart(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const removeCartItem = createAsyncThunk('cart/removeCartItem', async (data, thunkAPI) => {
    const cartService = new CartService();
    try {
        const response = await cartService.removeCartItem(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const updateCart = createAsyncThunk('cart/updateCart', async (data, thunkAPI) => {
    const cartService = new CartService();
    try {
        const response = await cartService.updateCart(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const updateCartVehicleId = createAsyncThunk('cart/updateCartVehicleId', async (data, thunkAPI) => {
    const cartService = new CartService();
    try {
        const response = await cartService.updateCartVehicleId();
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const updateCartCustomerId = createAsyncThunk('cart/updateCartCustomerId', async (data, thunkAPI) => {
    const cartService = new CartService();
    try {
        const response = await cartService.updateCartCustomerId();
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

const cartSlice = createSlice({
    name: "cart",
    initialState: initialState,
    reducers: {
        reset: (state) => {
            state.addToCartResponse = null;
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.successResponse = null;
            state.errorResponse = null;
            state.deleteCartResponse = null;
            state.removeCartItemResp = null;
            state.cartItemsCount = commonServices.getLocalStrg("MMX_CART_COUNT") || 0;
            state.orderInitializationResponse = null;
            state.updateCartResponse = null
        },
        updateAddToCartResponse: (state, action) => {
            state.addToCartResponse = action.payload
        },
        setOpenCartPage: (state, action) => {
            state.opencartPage = action.payload
        },
        updateCartDetails: (state, action) => {
            state.cartDetails = action.payload
        }
    },
    extraReducers: (builder) => {
        // add your async reducers here
        builder
            .addCase(addToCart.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(addToCart.fulfilled, (state, action) => {
                state.isSuccess = true
                if (action.payload?.data) {
                    const response = action.payload.data
                    state.addToCartResponse = response
                    if (state.addToCartResponse.statuscode === STATUS_CODES[200]) {
                        commonServices.setLocalStrg("MMX_CURRENT_CARTID", state.addToCartResponse?.data?.cartId);
                        commonServices.setLocalStrg("MMX_CURRENT_CART_SERVICECENTER", state.addToCartResponse?.data?.serviceCenterId)
                    }
                }
            })
            .addCase(addToCart.rejected, (state, action) => {
                state.isError = false
                state.errorResponse = action.payload.data;
            })
            .addCase(getCartItems.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getCartItems.fulfilled, (state, action) => {
                state.isSuccess = true
                state.cartDetails = action.payload.data;
                if (state.cartDetails.statuscode === STATUS_CODES[200]) {
                    state.cartItemsCount = state.cartDetails.data?.cartIteams?.length;
                    commonServices.setLocalStrg("MMX_CART_COUNT",state.cartItemsCount)
                    commonServices.setLocalStrg("MMX_CURRENT_CARTID", state.cartDetails?.data?.cartId);
                    commonServices.setLocalStrg("MMX_CURRENT_CART_SERVICECENTER", state.cartDetails?.data?.serviceCenterId)
                } else if (state.cartDetails.statuscode === STATUS_CODES[300]) {
                    state.cartItemsCount = 0;
                    commonServices.setLocalStrg("MMX_CART_COUNT",state.cartItemsCount)
                    localStorage.removeItem("MMX_CART_COUNT")
                    localStorage.removeItem("MMX_CURRENT_CARTID")
                    localStorage.removeItem("MMX_CURRENT_CART_SERVICECENTER")
                }
            })
            .addCase(getCartItems.rejected, (state, action) => {
                state.isError = false
                state.errorResponse = action.payload.data;
            })
            .addCase(getServiceCount.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getServiceCount.fulfilled, (state, action) => {
                state.isSuccess = true
                state.serviceCount = action.payload.data
            })
            .addCase(getServiceCount.rejected, (state, action) => {
                state.isError = false
                state.errorResponse = action.payload.data;
            })
            .addCase(deleteCart.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(deleteCart.fulfilled, (state, action) => {
                state.isSuccess = true
                state.deleteCartResponse = action.payload.data;
            })
            .addCase(deleteCart.rejected, (state, action) => {
                state.isError = false
                state.errorResponse = action.payload.data;
            })
            .addCase(removeCartItem.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(removeCartItem.fulfilled, (state, action) => {
                state.isSuccess = true
                state.removeCartItemResp = action.payload.data;
            })
            .addCase(removeCartItem.rejected, (state, action) => {
                state.isError = false
                state.errorResponse = action.payload.data;
            })
            .addCase(updateCart.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(updateCart.fulfilled, (state, action) => {
                state.isSuccess = true
                state.updateCartResponse = action.payload.data;
            })
            .addCase(updateCart.rejected, (state, action) => {
                state.isError = false
                state.errorResponse = action.payload.data;
            })
            .addCase(updateCartCustomerId.fulfilled, (state, action) => {
                if (action.payload?.statuscode === STATUS_CODES[200]) {
                    commonServices.setLocalStrg("MMX_CUSTMER_SAVED_TO_CART", "true");
                }
            })
            .addCase(updateCartVehicleId.fulfilled, (state, action) => {
                state.cartDetails = action.payload.data;
                state.cartItemsCount = action.payload.data?.data?.cartIteams?.length
                if (state.cartDetails.statuscode === STATUS_CODES[200]) {
                    commonServices.setLocalStrg("MMX_CURRENT_CARTID", state.cartDetails?.data?.cartId);
                    commonServices.setLocalStrg("MMX_CURRENT_CART_SERVICECENTER", state.cartDetails?.data?.serviceCenterId)
                } else if (state.cartDetails.statuscode === STATUS_CODES[300]) {
                    localStorage.removeItem("MMX_CURRENT_CARTID")
                    localStorage.removeItem("MMX_CURRENT_CART_SERVICECENTER")
                }
            })
    }
})

export const { reset, updateAddToCartResponse, setOpenCartPage, updateCartDetails } = cartSlice.actions;

export const cartSelector = (state) => state.cart;

export default cartSlice.reducer;