import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getServiceCenters } from "../../features/garage/garageSlice";
import {
  getservices,
  servicepackageselector,
} from "../../features/servicepackage/servicepackageSlice";
import { CommonServices } from "../../utils/commonServices";
import SelectionNav from "../SelectionNav";
import "./SearchServicesModal.css";
import { emergencyserviceselector } from "../../features/emergency/emergencySlice";

const ServicesList = (props) => {
  const { servicepackageservices, isSearchEnabled, addOtherService } = props;
  return (
    <>
      <div class="mx-3">
        <SelectionNav className="mt-0" />
      </div>
      {servicepackageservices &&
        servicepackageservices.length > 0 &&
        servicepackageservices.map((service) => {
          return (
            <>
              <div
                class="service row m-2 p-2 rounded-3 justify-content-between"
                style={{ backgroundColor: "white" }}
                onClick={() => {
                  if (isSearchEnabled) {
                    props.searchServiceCenters(service);
                  }
                }}
              >
                <h6 class="col-sm-12"> {service.name}</h6>
                <div class="col-md-2 col-sm-4 p-2">
                  <img
                    src={service.photos[0]}
                    alt=""
                    class="w-100 rounded-3"
                    style={{ height: "70px" }}
                  />
                </div>
                <div class="col-md-9 col-sm-7">
                  <ul class="p-0">
                    {service.service_period &&
                      service.service_period !== "*" && (
                        <li style={{ listStyle: "disc" }}>
                          <p class="m-0">{service.service_period}</p>
                        </li>
                      )}
                    {service.warranty && (
                      <li style={{ listStyle: "disc" }}>
                        <p class="m-0">{service.warranty}</p>
                      </li>
                    )}
                    {service.avarage_time && (
                      <li style={{ listStyle: "disc" }}>
                        <p class="m-0">takes about {service.avarage_time}hrs</p>
                      </li>
                    )}
                    {service.total_features && (
                      <li style={{ listStyle: "disc" }}>
                        <p class="m-0">
                          {service.total_features} services included
                        </p>
                      </li>
                    )}
                  </ul>
                  {!isSearchEnabled && (
                    <button
                      class="w-50 border-0 rounded-3 common-btn float-end"
                      onClick={() => {
                        addOtherService(service);
                      }}
                    >
                      ADD
                    </button>
                  )}
                </div>
              </div>
            </>
          );
        })}
    </>
  );
};

const SearchServicesModal = (props) => {
  const commonServices = new CommonServices();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { servicepackageservices } = useSelector(servicepackageselector);
  const { selectedCategory } = useSelector(emergencyserviceselector);

  useEffect(() => {
    const selectedCategory = commonServices.getLocalStrgJSON("MMX_SERVICE");
    if (selectedCategory) {
      const data = { serviceCatId: selectedCategory?.serviceCatId };
      dispatch(getservices(data));
    } else {
      dispatch(getservices());
    }
  }, [selectedCategory]);

  const searchServiceCenters = (service) => {
    commonServices.setLocalStrg("MMX_SERVICE_DETAILS", JSON.stringify(service));

    const vehicle = commonServices.getLocalStrgJSON("MMX_VEHICLE");
    if (vehicle === null) {
      props.handleClose();
      toast.info("Please select your vehicle!", {
        toastId: "select vehicle",
        autoClose: 2000,
        hideProgressBar: true,
      });
    } else {
      props.handleClose();
      const data = {
        customerVehicleId:
          commonServices.getLocalStrgJSON("MMX_SELECTED_VEHICLE")
            ?.customerVehicleId || "",
        vehicleTypeId: commonServices.getLocalStrg("VEHICLE_TYPE"),
        vehicleModelId: JSON.parse(vehicle.selectedmodel).vehicleModelId,
        vehicleFueltypeId: JSON.parse(vehicle.selectedfueltype).fueltypeId,
        vehicleBodyTypeId: JSON.parse(vehicle.selectedmodel).vehicleBodyTypeId,
        servicesId: service.servicesId,
      };
      dispatch(getServiceCenters(data));
      navigate("/services/service-centers");
    }
  };

  return (
    <>
      <Modal
        className="search-services-modal"
        show={true}
        onHide={() => {}}
        style={{ paddingLeft: "0px" }}
        // backdrop="static"
        // keyboard={false}
      >
        <Modal.Body
          style={{
            height: "fit-content",
            backgroundColor: "whitesmoke",
          }}
        >
          <div>
            <div class="d-flex align-items-center justify-content-between">
              <i class="fas fa-arrow-left" onClick={props.handleClose}></i>
              <div className="search d-flex align-items-center justify-content-between">
                <input
                  type="text"
                  placeholder="search services"
                  class="border-0 bg-transparent fw-600 w-100"
                  onChange={(event) => {
                    dispatch(getservices({ value: event.target.value }));
                  }}
                />
                <i class="fas fa-search"></i>
              </div>
            </div>
            <div class="mt-3">
              <ServicesList
                servicepackageservices={servicepackageservices}
                searchServiceCenters={(service) =>
                  searchServiceCenters(service)
                }
                isSearchEnabled={props.isSearchEnabled}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SearchServicesModal;
export { ServicesList };
