/* eslint-disable no-useless-catch */


import { SEND_LOGIN_OTP, VERIFY_LOGIN_OTP, REGISTER } from '../config/constants'

import { HttpClient } from '../utils/httpClient';
import { CommonServices } from '../utils/commonServices';
import jwtDecode from 'jwt-decode';

class AuthService {
  constructor() {
    this.httpClient = new HttpClient();
    this.commonServices = new CommonServices();
  }

  async sendotp(user) {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.httpClient.post(SEND_LOGIN_OTP, JSON.stringify(user));
      return response;
    } catch (error) {
      throw error;
    }
  }

  async login(otp) {
    // eslint-disable-next-line no-useless-catch
    try {
      const resp = await this.httpClient.post(VERIFY_LOGIN_OTP, JSON.stringify(otp));
      if (resp.data.statuscode == 200) {
        const userData = resp.data.data;
        this.commonServices.setLocalStrg("MMX_AUTH_TOKEN", userData.token);
        const userD = JSON.stringify({ userId: userData.userId, full_name: userData.full_name, referral_code: userData.referral_code })
        this.commonServices.setLocalStrg("MMX_USER", userD);
      }
      return resp;
    } catch (error) {
      throw error;
    }
  }

  async logout() {
    window.localStorage.clear();
    this.commonServices.setDeviceId();
    return {
      status: 'success',
      data: {
        jwt: null,
        user: null,
      },
    };
  }

  async register(data) {
    try {
      const resp = await this.httpClient.post(REGISTER, data);
      if (resp.data.statuscode == 200) {
        const userData = resp.data.data;
        this.commonServices.setLocalStrg("MMX_AUTH_TOKEN", userData.token);
        const userD = JSON.stringify({ userId: userData.userId, full_name: userData.full_name, referral_code: userData.referral_code })
        this.commonServices.setLocalStrg("MMX_USER", userD);
      }
      return resp;
    } catch (error) {
      throw error;
    }
  }
}

export { AuthService };
