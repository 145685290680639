import { Outlet } from "react-router-dom";
import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
// import Login from "../../components/Login/Login.jsx";
import MobileFooter from "../MobileFooter/MobileFooter";
/**
 * This component is the outer layout of the application which contains the header and the footer only.
 * @returns {React.Component}
 * We will use this when user is not logged in.
 */
const OuterLayout = () => {
  return (
    <div>
      {/* <h5>OuterLayout</h5> */}
      <Header />
      {/* <Login /> */}
      <Outlet />
      <Footer />
      <MobileFooter />
    </div>
  );
};

export default OuterLayout;
