import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import { useEffect, useState } from "react";

const GoogleMaps = withScriptjs(
  withGoogleMap((props) => {
    const { location, markerDrag } = props;

    const [latLong, setLatLong] = useState({
      lat: parseFloat(location.lat),
      lng: parseFloat(location.lng),
    });

    useEffect(() => {
      setLatLong({
        lat: parseFloat(location.lat),
        lng: parseFloat(location.lng),
      });
    }, [location]);

    return (
      <GoogleMap defaultZoom={16} defaultCenter={latLong} center={latLong}>
        <Marker position={latLong} draggable onDragEnd={markerDrag} />
      </GoogleMap>
    );
  })
);

export default GoogleMaps;
