/* eslint-disable no-useless-catch */
import { SERVICE_CENTER_NEAR_ME } from '../config/constants';
import { Worktypes } from '../SampleData';
import { HttpClient } from '../utils/httpClient';

class CarSPAServices {
  constructor() {
    this.httpClient = new HttpClient();
  }

  async getCarSpaServices(data) {
    try {
      const response = await this.httpClient.post(SERVICE_CENTER_NEAR_ME, data);
      // const response = CarSapNearYouData;
      return response.data.data;
    } catch (error) {
      throw error;
    }
  }

  async getSpaGarageWorkTypes(data) {
    try {
      return Worktypes
    } catch (error) {
      throw error;
    }
  }
}

export { CarSPAServices };

