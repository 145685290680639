
import { Overlay, Tooltip } from 'react-bootstrap';
import React, { useRef, useState } from 'react';
import { useMediaQuery } from "react-responsive";
import ImageCarousel from '../ImageCarousel/ImageCarousel';

const About = () => {

    const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
    const isLaptopOrDesktop = useMediaQuery({
        query: "(min-width: 1008px) and (max-width: 1824px)",
    });
    return (
        <ImageCarousel
            images={isLaptopOrDesktop ? [
                "/assets/imgs/web-about-screens/Desktop-24.png",
                "/assets/imgs/web-about-screens/Desktop-25.png",
                "/assets/imgs/web-about-screens/Desktop-26.png",
                "/assets/imgs/web-about-screens/Desktop-27.png",
                "/assets/imgs/web-about-screens/Desktop-28.png",
            ] : (isMobile ? ["/assets/imgs/web-about-screens/iPhone13mini-11.png",
                "/assets/imgs/web-about-screens/iPhone13mini-12.png",
                "/assets/imgs/web-about-screens/iPhone13mini-13.png",
                "/assets/imgs/web-about-screens/iPhone13mini-14.png",
                "/assets/imgs/web-about-screens/iPhone13mini-15.png",] :
                [
                    "/assets/imgs/web-about-screens/iPadPro12.9_1.png",
                    "/assets/imgs/web-about-screens/iPadPro12.9_2.png",
                    "/assets/imgs/web-about-screens/iPadPro12.9_3.png",
                    "/assets/imgs/web-about-screens/iPadPro12.9_4.png",
                    "/assets/imgs/web-about-screens/iPadPro12.9_5.png"])}
        />
    );
};
const Team = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

    const isLaptopOrDesktop = useMediaQuery({
        query: "(min-width: 1008px) and (max-width: 1824px)",
    });
    return (
        <ImageCarousel
            images={isLaptopOrDesktop ? [
                "/assets/imgs/web-about-screens/Desktop-29.png",
                "/assets/imgs/web-about-screens/Desktop-30.png"
            ] : (isMobile ? ["/assets/imgs/web-about-screens/iPhone13mini-18.png",
                "/assets/imgs/web-about-screens/iPhone13mini-16.png",] : ["/assets/imgs/web-about-screens/iPadPro12.9_6.png", "/assets/imgs/web-about-screens/iPadPro12.9_7.png"])}
        />
    );
};
const Goals = () => {
    const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

    const isLaptopOrDesktop = useMediaQuery({
        query: "(min-width: 1008px) and (max-width: 1824px)",
    });
    return <ImageCarousel images={isLaptopOrDesktop ? ["/assets/imgs/web-about-screens/Desktop-31.png"] : (isMobile ? ["/assets/imgs/web-about-screens/iPhone13mini-17.png",
    ] : ["/assets/imgs/web-about-screens/iPadPro12.9_8.png"])} />;
};

const RadioGroup = ({ options, selectedValue, onChange, renderTooltip }) => {
    const [hoveredValue, setHoveredValue] = useState(null);
    const target = useRef(null);

    const handleValueHover = (event) => {
        setHoveredValue(event.target.value);
    };

    const handleValueLeave = () => {
        setHoveredValue(null);
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
            }}
        >
            {options.map((option) => (
                <React.Fragment key={option.value}>
                    <input
                        type="radio"
                        class="pointer"
                        id={option.value}
                        name="radio-group"
                        value={option.value}
                        checked={selectedValue === option.value}
                        onChange={onChange}
                        onMouseEnter={handleValueHover}
                        onMouseLeave={handleValueLeave}
                        ref={hoveredValue === option.value ? target : null}
                    />
                    {hoveredValue === option.value && renderTooltip(option.label, target)}
                </React.Fragment>
            ))}
        </div>
    );
};

const AboutPage = () => {
    const [selectedSection, setSelectedSection] = useState('About');

    const handleSectionChange = (event) => {
        setSelectedSection(event.target.value);
    };

    const renderSectionTooltip = (label, target) => (
        <Overlay target={target.current} show={true} placement="left">
            {(props) => (
                <Tooltip id="overlay-example" {...props}>
                    {label}
                </Tooltip>
            )}
        </Overlay>
    );

    const sectionOptions = [
        { label: 'About', value: 'About' },
        { label: 'Team', value: 'Team' },
        { label: 'Goals', value: 'Goals' },
    ];

    return (
        <div style={{ display: 'flex', height: '100vh' }}>
            <div style={{ flex: 1 }}>
                {selectedSection === 'About' && <About />}
                {selectedSection === 'Team' && <Team />}
                {selectedSection === 'Goals' && <Goals />}
            </div>
            <div
                style={{
                    position: 'absolute',
                    right: '5%',
                    top: '50%',
                    transform: 'translateY(-50%)',
                }}
            >
                <RadioGroup
                    options={sectionOptions}
                    selectedValue={selectedSection}
                    onChange={handleSectionChange}
                    renderTooltip={renderSectionTooltip}
                />
            </div>
        </div>
    );
};

export default AboutPage;