import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import {
  garageSelector,
  updateGarageDetailsFlag,
} from "../../features/garage/garageSlice";
import {
  getServiceCenterDetails,
  setShowMoreServices,
  updateServiceCenters,
  updateShowSuggestedServiceCentersFlag,
} from "../../features/garage/garageSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getServiceCenterAllServices, updateServicePackagesList } from "../../features/servicepackage/servicepackageSlice";
import { CommonServices } from "../../utils/commonServices";
import { updateSpaDetailsFlag } from "../../features/spa/spaserviceSlice";
import SkeletonLoader from "../../common/SkeletonLoaders/SkeletonLoaders";
import { updatecategoryselected } from "../../features/emergency/emergencySlice";

function Garages(props) {
  const commonServices = new CommonServices();
  const { garages, isLoading } = useSelector(garageSelector);
  const [garagesList, setGaragesList] = useState(null);
  const dispatch = useDispatch();
  const reviews = [1, 2, 3, 4, 5];

  useEffect(() => {
    setGaragesList(garages);
  }, [garages]);
  const options = {
    items: 4,
    nav: true,
    navText: [
      "<div className='nav-btn prev-slide'></div>",
      "<div className='nav-btn next-slide'></div>",
    ],
    loop: false,
    dots: false,
    // pagination:true,
    autoplay: true,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 2,
      },
      980: {
        items: 3,
      },
      1199: {
        items: 4,
      },
    },
  };
  const navigate = useNavigate();
  const showServiceCenter = (garage) => {
    const vehicle = commonServices.getLocalStrgJSON("MMX_VEHICLE");
    if (vehicle === null) {
      toast.info("Select your vehicle", {
        toastId: "selectVehicle",
        autoClose: 2000,
        hideProgressBar: true,
      });
      window.scrollTo(0, 0);
    } else {
      dispatch(getServiceCenterDetails(garage?.serviceCenterId));
      dispatch(
        getServiceCenterAllServices({
          serviceCenterId: garage?.serviceCenterId,
        })
      );
      localStorage.removeItem('MMX_SERVICE_DETAILS')
      localStorage.removeItem("MMX_SERVICE");
      dispatch(updateServicePackagesList([]))
      dispatch(updatecategoryselected(null))
      commonServices.setLocalStrg("MMX_SERVICE_CENTER", JSON.stringify({serviceCenterId:garage.serviceCenterId, service_center_dispaly_name:garage.name}));
      dispatch(updateGarageDetailsFlag(true));
      dispatch(updateSpaDetailsFlag(false));
      dispatch(setShowMoreServices(true));
      dispatch(updateShowSuggestedServiceCentersFlag(true));
      // const newArray = garages.filter((center) => {
      //   if (center.serviceCenterId != garage.serviceCenterId) {
      //     return center;
      //   }
      // });
      dispatch(updateServiceCenters(garages));
      navigate("/services/service-centers");
    }
  };

  return (
    <React.Fragment>
      <link
        href="assets/css/garages.css"
        type="text/css"
        rel="stylesheet"
      ></link>
      <div className="garages my-4">
        <div className="row">
          <div className="col-md-12">
            <h4>GARAGES NEAR YOU</h4>
            <p>
              Search from our service centres and book a slot closest to you and
              at your convenience
            </p>
            <OwlCarousel className="owl-carousel" {...options}>
              {garages &&
                garages.map((garage, i) => {
                  return (
                    <div
                      key={i}
                      className="post-slide pointer"
                      onClick={() => {
                        showServiceCenter(garage);
                      }}
                    >
                      <div className="post-img">
                        <img
                          src="/assets/imgs/garages/garage-image.png"
                          // src={garage.garagePic}
                          alt=""
                        />
                        {/* <a href="#" className="over-layer">
                          <i className="fa fa-link" />
                        </a> */}
                      </div>
                      <div className="post-content">
                        <div class="d-flex align-items-center justify-content-start">
                          <ul>
                            {reviews.map((item) => {
                              return (
                                <>
                                  {garage?.overAllRatingData?.avarageRate !=
                                    0 &&
                                  item <=
                                    garage?.overAllRatingData?.avarageRate ? (
                                    <img
                                      class="rating"
                                      style={{ width: "12px" }}
                                      src="assets/icons/star-selected.svg"
                                    ></img>
                                  ) : (
                                    <img
                                      class="rating blue-star"
                                      style={{ width: "12px" }}
                                      src="assets/icons/star.svg"
                                    ></img>
                                  )}
                                </>
                              );
                            })}
                          </ul>
                          {garage?.overAllRatingData?.totalRatedUsers > 0 ? (
                            <p class="m-0 reviews">
                              ({garage.overAllRatingData.totalRatedUsers} -
                              reviews)
                            </p>
                          ) : (
                            <p class="m-0 reviews">(0 - reviews)</p>
                          )}
                        </div>
                        <p className="post-title  fw-bold m-0">{garage.name}</p>
                        <p class="m-0">{garage.distance} Km away</p>
                        {/* <p>{garage.full_address}</p> */}
                        <p
                          style={{
                            maxWidth: "100%",
                          }}
                          class="m-0 d-inline-block text-truncate"
                        >
                          {garage.full_address}
                        </p>
                      </div>
                    </div>
                  );
                })}
              {isLoading &&
                Array(4)
                  .fill(0)
                  .map((item) => {
                    return <SkeletonLoader isCardSkeleton={true} />;
                  })}
            </OwlCarousel>
            {!isLoading && garages && garages.length <= 0 && (
              <div class="d-flex align-items-center">
                <img
                  src="../assets/imgs/garages/automobile_repair_ywci.png"
                  style={{ width: "35%" }}
                />
                <p>No Garage is available at your location</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Garages;
