import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getCartItems } from "../../features/cart/cartSlice";
import { emergencyserviceselector } from "../../features/emergency/emergencySlice";
import {
  setShowMoreServices,
  updateGarageDetailsFlag,
  updateShowSuggestedServiceCentersFlag,
  updateServiceCenters,
} from "../../features/garage/garageSlice";
import { updateSpaDetailsFlag } from "../../features/spa/spaserviceSlice";
import {
  getservices,
  servicepackageselector,
  setVehicleModal,
  updateServicesSearchKey,
  reset
} from "../../features/servicepackage/servicepackageSlice";
import { useMediaQuery } from "react-responsive";
import { CommonServices } from "../../utils/commonServices";
import SkeletonLoader from "../../common/SkeletonLoaders/SkeletonLoaders";
import { getbrands } from "../../features/home/homeSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import ServiceFeatures from "./ServiceFeatures";

function ServicePackages(props) {
  const commonServices = new CommonServices();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [servicesList, setServicesList] = useState([]);
  const [showAllFeatures, setShowAllFeatures] = useState(false)
  const [getListBody, setGetListBody] = useState({
    length: 10,
    start: 0,
    filter: {
      value: "",
      serviceCatId: "",
    }
  });

  const { getServicesResp, servicepackageservices, showSelectVehicleModal, isLoading } =
    useSelector(servicepackageselector);
  const { selectedCategory } = useSelector(emergencyserviceselector);
  const isLaptopOrDesktop = useMediaQuery({
    query: "(min-width: 1008px) and (max-width: 1824px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width: 500px) and (max-width: 912px)",
  });

  useEffect(() => {
    if (servicepackageservices?.length > 0) {
      if (servicesList?.length>0) {
        setServicesList([...servicesList, ...servicepackageservices]);
      } else {
        setServicesList([...servicepackageservices]);
      }
      setGetListBody({ ...getListBody, start: getListBody.start + 10 });
    } else if (servicepackageservices === null) {
      setServicesList(servicepackageservices);
    }
  }, [servicepackageservices])

  useEffect(() => {
    const selectedCategory = commonServices.getLocalStrgJSON("MMX_SERVICE");
    if (selectedCategory) {
      setServicesList([]);
      setGetListBody({
        length: 10,
        start: 0,
        filter: { ...getListBody.filter, serviceCatId: selectedCategory?.serviceCatId }
      })
      const data = { length: 10, start: 0, serviceCatId: selectedCategory?.serviceCatId };
      dispatch(getservices(data));
    } else {
      dispatch(getservices({ ...getListBody }));
    }
  }, [selectedCategory]);

  const searchServiceCenters = (service) => {
    commonServices.setLocalStrg("MMX_SERVICE_DETAILS", JSON.stringify(service));
    const vehicle = commonServices.getLocalStrgJSON("MMX_VEHICLE");
    if (vehicle === null) {
      if (isLaptopOrDesktop) {
        const data = {
          vehicleType: commonServices.getLocalStrg("VEHICLE_TYPE"),
        };
        dispatch(getbrands(data));
        dispatch(setVehicleModal(true));
        dispatch(updateServicesSearchKey(service));
      } else {
        navigate("/home");
        toast.info("Please select your vehicle!", {
          toastId: "selectVehicle",
          autoClose: 2000,
          hideProgressBar: true,
        });
        window.scrollTo(0, 0);
      }
    } else {
      navigate("/services/service-centers");
      dispatch(getCartItems());
      dispatch(updateServicesSearchKey(service));
      dispatch(updateShowSuggestedServiceCentersFlag(false));
      dispatch(updateGarageDetailsFlag(false));
      dispatch(updateSpaDetailsFlag(false));
      dispatch(setShowMoreServices(false));
      dispatch(updateServiceCenters(null));
    }
  };

  const loadData = () => {
    dispatch(getservices({ ...getListBody, ...getListBody.filter }));
  }

  useEffect(()=>{
    return ()=>{
      dispatch(reset())
      setServicesList([])
    }
  },[])

  return (
    <div className="service_packages mt-3">
      <div>
        <h4 style={{ color: "#62a781" }}>
          {selectedCategory ? selectedCategory.name : ""}
        </h4>
        <h5 class={` ${selectedCategory != null ? "fw-500 " : "fw-700"}`}>
          Select Your Service Package
        </h5>
      </div>
      <InfiniteScroll
        style={{ overflowX: "hidden" }}
        dataLength={servicesList?.length} //This is important field to render the next data
        next={(event) => { loadData() }}
        hasMore={
          (getServicesResp && getServicesResp?.data?.length > 0)
            ? true
            : false
        }
        loader={<SkeletonLoader isRectangle={true} count={1} />}
      >
        {servicesList?.length > 0 ? (
          servicesList.map((service, i) => {
            return (
              <div key={i} className="card my-3">
                <div className="row">
                  <div className=" col-lg-3 col-md-4 col-sm-12 package_img">
                    <img src={service?.serviceIcon ? service.serviceIcon : service?.photos[0]} className="img-fluid" />
                  </div>
                  <div className="col-lg-9 col-md-8 col-sm-12 package_text mt-3">
                    <div class="mx-2">
                      <h5 className="mt-0 service-name">{service?.name}</h5>
                      <ul className="self row">
                        {service?.avarage_time && (
                          <li class="col-lg-4 col-md-6 col-sm-12">
                            <img
                              src="/assets/imgs/service_package/time.png"
                              alt=""
                              width={20}
                            />{" "}
                            &nbsp; Takes about {service.avarage_time} hrs
                          </li>
                        )}
                        {service?.warranty && (
                          <li class="col-lg-4 col-md-6 col-sm-12">
                            <img
                              src="/assets/imgs/service_package/warrenty.png"
                              alt=""
                              width={20}
                            />{" "}
                            &nbsp;
                            {service?.warranty}
                          </li>
                        )}
                        {service.service_period &&
                          service.service_period !== "*" && (
                            <li class="col-lg-4 col-md-12 col-sm-12">
                              <img
                                src="/assets/imgs/service_package/period.png"
                                alt=""
                                width={20}
                              />{" "}
                              &nbsp;
                              {service.service_period}
                            </li>
                          )}
                      </ul>
                      {!isTablet && <ServiceFeatures service={service} showAllFeatures={showAllFeatures} setShowAllFeatures={(content) => setShowAllFeatures(content)} />
                      }
                    </div>
                  </div>
                </div>
                {isTablet && <ServiceFeatures service={service} showAllFeatures={showAllFeatures} setShowAllFeatures={(content) => setShowAllFeatures(content)} />
                      }
                <div class="row">
                  <div class="col-lg-12 show-center-btn d-flex justify-content-end s_center">
                    <button
                      className=" d-flex align-items-center justify-content-center m-3 fw-600 pointer"
                      onClick={() => searchServiceCenters(service)}
                    >
                      &nbsp;
                      <span
                        style={{
                          lineHeight: "24px",
                        }}
                      >
                        {showSelectVehicleModal
                          ? "Select Vehicle"
                          : "Show Service Center"}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <>
            {!isLoading && (
              <div class="d-flex align-items-center mt-5">
                <img
                  src="../assets/imgs/garages/automobile_repair_ywci.png"
                  style={{ width: "35%" }}
                />
                <p>Sorry! No Services available </p>
              </div>
            )}
          </>
        )}
        {isLoading && (
          <>
            <SkeletonLoader isRectangle={true} count={3} />
          </>
        )}
      </InfiniteScroll>
    </div>
  );
}

export default ServicePackages;



