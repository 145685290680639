import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AddressesService } from "../../services/addresses.service";
import { CommonServices } from "../../utils/commonServices";
const initialState = {
    addressList: [],
    getAddressResponse: null,
    createAddressResponse: null,
    addresstypedescr: null,
    updateAddressResp: null,
    removeAddressResp: null,
    address:null
}

export const createAddress = createAsyncThunk("address/createAddress", async (data, thunkAPI) => {
    const addressService = new AddressesService();
    try {
        const response = addressService.addAddress(data);
        return response
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const getAddressesList = createAsyncThunk("address/getAddressesList", async (data, thunkAPI) => {
    const addressService = new AddressesService();
    try {
        const response = addressService.getAddressesList(data);
        return response
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const updateAddress = createAsyncThunk("address/updateAddress", async (data, thunkAPI) => {
    const addressService = new AddressesService();
    try {
        const response = addressService.editAddress(data);
        return response
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const removeAddress = createAsyncThunk("address/removeAddress", async (data, thunkAPI) => {
    const addressService = new AddressesService();
    try {
        const response = addressService.removeAddress(data);
        return response
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

const addressSlice = createSlice({
    name: "address",
    initialState,
    reducers: {
        reset: (state) => {
            state.addressList = [];
            state.getAddressResponse = null;
            state.createAddressResponse = null;
            state.updateAddressResp =null;
            state.removeAddressResp = null;
        },
        updateAddressesList: (state, action) => {
            state.addressList = action.payload
        },
        updateAddressTypeDesc: (state, action) => {
            state.addresstypedescr = action.payload
        },
        setAddress:(state,action)=>{
            state.address = action.payload
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getAddressesList.fulfilled, (state, action) => {
                if (action.payload && action.payload.data) {
                    state.addressList = action.payload.data;
                }
            })
            .addCase(createAddress.fulfilled, (state, action) => {
                const commonServices = new CommonServices()
                state.createAddressResponse = action.payload.data
                commonServices.setLocalStrg("MMX_ADDRESSID", action.payload.data.data.customerAddressId);

            })
            .addCase(updateAddress.fulfilled, (state, action) => {
                state.updateAddressResp = action.payload.data
            })
            .addCase(removeAddress.fulfilled, (state, action) => {
                state.removeAddressResp = action.payload.data
            })
    }
})

export const { reset,setAddress, setUserSelectedAddress, updateAddressesList, updateAddressTypeDesc } = addressSlice.actions;
export const addressSelector = (state) => state.address;
export default addressSlice.reducer