import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CarSPAServices } from '../../services/carspa.services';

const intialState = {
  carSpasNearMeList: null,
  carSpaWorkTypes: null,
  message: null,
  showSpaDetailsFlag: null,
  isLoading: null
};

export const getspaservices = createAsyncThunk('carspaservices/getspaservices', async (data, thunkAPI) => {
  const spaservice = new CarSPAServices();
  try {
    const response = await spaservice.getCarSpaServices(data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getSpaGarageWorkTypes = createAsyncThunk('carspaservices/getSpaGarageWorkTypes', async (data, thunkAPI) => {
  const spaservice = new CarSPAServices();
  try {
    const response = await spaservice.getSpaGarageWorkTypes();
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
})

const carspaserviceSlice = createSlice({
  name: 'carspaservices',
  initialState: intialState,
  reducers: {
    reset: (state) => {
      state.carSpasNearMeList = null
      state.message = null
    },
    updateSpaDetailsFlag: (state, action) => {
      state.showSpaDetailsFlag = action.payload;
    }
  },
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getspaservices.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getspaservices.fulfilled, (state, action) => {
        state.isLoading = false
        state.carSpasNearMeList = action.payload;

      })
      .addCase(getSpaGarageWorkTypes.pending, (state, action) => {

      })
      .addCase(getSpaGarageWorkTypes.fulfilled, (state, action) => {

        state.carSpaWorkTypes = action.payload;
      })
      .addCase(getSpaGarageWorkTypes.rejected, (state, action) => {
      });
  }
});

export const { reset, updateSpaDetailsFlag } = carspaserviceSlice.actions;

export const carspaserviceselector = (state) => state.carspaservices;

export default carspaserviceSlice.reducer;