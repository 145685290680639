import React from 'react';
import '../Benefits/benefits.css';
import { benifitData } from '../../SampleData'
function Benefits(props) {
    return (
        <div className="benefits">
            <h4>BENEFITS OF USING METAMECHX</h4>
            <div className="row">
                {benifitData && benifitData.map((item) => {
                    if (item.id == 0 || item.id == 1) {
                        return (
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="card d-flex align-items-center">
                                    <img src={item.ImageURL} className="card-img-top benefits-image" alt="..." />
                                    <div className="card-body">
                                        <h5>{item.Header}</h5>
                                        <p>{item.Para}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
            <div className="row">
                {benifitData && benifitData.map((item) => {
                    if (item.id == 2 || item.id == 3) {
                        return (
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="card d-flex align-items-center">
                                    <img src={item.ImageURL} className="card-img-top benefits-image" alt="..." />
                                    <div className="card-body d-contents">
                                        <h5>{item.Header}</h5>
                                        <p>{item.Para}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
        </div>
    );
}

export default Benefits;