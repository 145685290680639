import "./Account.css";
import { Link, Outlet } from "react-router-dom";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  customerProfileSelector,
  displayLogoutModal,
} from "../../features/account/customerProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import { accountSelector,setIsAnyAccountModuleSelectedMobile } from "../../features/account/accountSlice";

const Account = () => {
  window.scrollTo(0,0)
  const [isComponentSelected, setIsComponentSelected] = useState(false);
  const { activeModule } = useSelector(customerProfileSelector);
  const {isAnyAccountModuleSelectedMobile} = useSelector(accountSelector)
  const [active, setActive] = useState(
    activeModule ? activeModule : "Profile Details"
  );
  const dispatch = useDispatch();

  const handleClick = (item) => {
    if (isMobile) {
      dispatch(setIsAnyAccountModuleSelectedMobile(true));
    }
    window.scrollTo(0, 0);
    setActive(item.header);
    if (item.header === "Logout") {
      dispatch(displayLogoutModal(true));
    }
  };

  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 500px) and (max-width: 912px)",
  });

  const menuItems = [
    {
      icon: "/assets/imgs/account/profile.png",
      header: "Profile Details",
      desc: "Edit Name, Phone, E-mail",
      path: "/profile",
    },
    {
      icon: "/assets/imgs/account/wallet.png",
      header: "MMX Wallet",
      desc: "Check Balance, History",
      path: "/profile/wallet",
    },
    // {
    //   icon: "fa-solid fa-credit-card fa-2x",
    //   header: "Payment Options",
    //   desc: "See, Edit or add options",
    //   path: "/profile/payment",
    // },
    {
      icon: "/assets/imgs/account/vehicles.png",
      header: "My Vehicles",
      desc: "Vehicle list, add, remove",
      path: "/profile/myvehicles",
    },
    {
      icon: "/assets/imgs/account/address.png",
      header: "Saved Addresses",
      desc: "Edit / Manage addresses",
      path: "/profile/myaddresses",
    },
    {
      icon: "/assets/imgs/account/orderHistory.png",
      header: "Order History",
      desc: "All Services list, status, Invoices",
      path: "/profile/order-history",
    },
    {
      icon: "/assets/imgs/account/refer&earn.png",
      header: "Refer and Earn",
      desc: "Check Credit points, Earn more",
      path: "/profile/refer&earn",
    },

    {
      icon: "/assets/imgs/account/contactus.png",
      header: "Contact Us",
      desc: "For queries call, email",
      path: "/profile/contactus",
    },
    {
      icon: "/assets/imgs/account/logout.png",
      header: "Logout",
      desc: "Logout ",
      path: "/profile/logout",
    },
  ];
  return (
    <>
      {(isTablet || !isAnyAccountModuleSelectedMobile )&& (
        <div class="account_class">
          <div class="header">
            <h3>My Account,</h3>
            <p>
              Search from our service centres and book a slot closest to you and
              at your convenience
            </p>
          </div>
          <div class="body">
            <div class="row">
              <div className="left-list col-lg-2 col-md-3 col-sm-12 w-25 mt-0">
                <ul>
                  {menuItems &&
                    menuItems.map((item) => {
                      return (
                        <>
                          <li
                            onClick={() => {
                              handleClick(item);
                            }}
                            tabindex="0"
                          >
                            <Link to={item.path} className="nav-link">
                              <div
                                class={`element p-2 row list-item align-items-center ${
                                  active == item.header && "active"
                                }`}
                              >
                                <div class="col-lg-2 col-md-2 col-sm-1 m-auto">
                                  <img
                                    src={item.icon}
                                    class="w-100 m-auto"
                                  ></img>
                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9">
                                  <p class="m-0 item-header">{item.header}</p>
                                  <p class="m-0 item-desc">{item.desc}</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </>
                      );
                    })}
                </ul>
              </div>
              <div className="right col-lg-9 col-md-8 col-sm-12">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      )}
      {isAnyAccountModuleSelectedMobile && ( isMobile) && (
        <div class="m-3 accounts_submodule overflow-hidden">
          <div style={{ backgroundColor: "#61ffa8" }} class="mb-2 p-1 rounded">
            <i
              class="fas fa-chevron-circle-left"
              onClick={() => {
                dispatch(setIsAnyAccountModuleSelectedMobile(false));
              }}
            ></i>{" "}
          </div>
          <div className="common_border p-3 right col-lg-9 col-md-12 col-sm-12">
            <Outlet />
          </div>
        </div>
      )}
    </>
  );
};

export default Account;
