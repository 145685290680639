import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WalletService } from "../../services/wallet.service";
const initialState = {
    customerWalletDetails: null,
    updateWalletResp: null,
    walletHistory: null
}


export const getCustomerWalletDetails = createAsyncThunk('wallet/getCustomerWalletDetails', async (data, thunkAPI) => {
    const walletService = new WalletService();
    try {
        const response = await walletService.getCustomerWalletDetails();
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const updateWallet = createAsyncThunk('wallet/updateWallet', async (data, thunkAPI) => {
    const walletService = new WalletService();
    try {
        const response = await walletService.updateWallet(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const getWalletHistory = createAsyncThunk('wallet/getWalletHistory', async (data, thunkAPI) => {
    const walletService = new WalletService();
    try {
        const response = await walletService.getWalletHistory();
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});




const walletSlice = createSlice({
    name: "wallet",
    initialState,
    reducers: {
        reset: (state) => {
            state.updateWalletResp = null;
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(updateWallet.fulfilled, (state, action) => {
                state.updateWalletResp = action.payload.data;
            })
            .addCase(getCustomerWalletDetails.fulfilled, (state, action) => {
                state.customerWalletDetails = action.payload.data
            })
            .addCase(getWalletHistory.fulfilled, (state, action) => {
                if (action.payload && action.payload.statuscode === 200) {
                    state.walletHistory = action.payload.data
                }
            })
    }
})

export const { reset } = walletSlice.actions;
export const walletSelector = (state) => state.wallet;
export default walletSlice.reducer;