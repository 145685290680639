import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    status: undefined,
    show: false,
    message: '',
    type: ''
};

export const alertSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        setMessage: (state, action) => {

            const { status, message } = action.payload;
            state.show = true;

            if (status === 'Success') {
                state.status = 'success';
                state.type = 'alert-success'
            } else if (status === 'error' || status === 'warning') {
                state.status = 'error';
                state.type = 'alert-danger'
            } else {
                state.status = 'info';
                state.type = 'alert-info'
            }
            if (message) {
                state.message = message;
            }
        },
        clearMessage: (state) => {
            state.show = false;
            state.status = undefined;
            state.message = '';
        },
    },
    extraReducers: {},
});

export const { setMessage, clearMessage } = alertSlice.actions;

export const alertSelector = (state) => state.alerts;

export default alertSlice.reducer;