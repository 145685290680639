import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GaragesService } from '../../services/garages.services';

const intialState = {
  isLoading: false,
  isError: false,
  successResponse: null,
  errorResponse: null,
  garages: null,
  message: null,
  serviceCenters: null,
  serviceCenterDetails: null,
  showMoreServices: false,
  showSuggestedServiceCentersFlag: false,
  showGarageDetailsFlag: false,
  isServicecentersLoading: false,
  isServicecenterDetailsLoading: false
};

export const getgarages = createAsyncThunk('garages/getgarages', async (data, thunkAPI) => {
  const garageservice = new GaragesService();
  try {
    const response = await garageservice.getGaragesServices(data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getServiceCenters = createAsyncThunk('garages/getServiceCenters', async (data, thunkAPI) => {
  const garageservice = new GaragesService();
  try {
    const response = await garageservice.getServiceCenters(data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getServiceCenterDetails = createAsyncThunk('garages/getServiceCenterDetails', async (data, thunkAPI) => {
  const garageservice = new GaragesService();
  try {
    const response = await garageservice.getServiceCenterDetails(data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
})

const garageSlice = createSlice({
  name: 'garage',
  initialState: intialState,
  reducers: {
    reset: (state) => {
      state.garages = null
      state.message = null
      state.serviceCenters = null
      state.serviceCenterDetails = null
      state.isServicecentersLoading = false
      state.isServicecenterDetailsLoading = false
    },
    setShowMoreServices: (state, action) => {
      state.showMoreServices = action.payload;
    },
    updateServiceCenters: (state, action) => {
      state.serviceCenters = action.payload
    },
    updateShowSuggestedServiceCentersFlag: (state, action) => {
      state.showSuggestedServiceCentersFlag = action.payload;
    },
    updateGarageDetailsFlag: (state, action) => {

      state.showGarageDetailsFlag = action.payload;
    }
  },
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getgarages.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getgarages.fulfilled, (state, action) => {
        state.isLoading = false
        state.garages = action.payload.data.data;
      })
      .addCase(getgarages.rejected, (state, action) => {
        state.isLoading = false
      })
      .addCase(getServiceCenters.pending, (state, action) => {
        state.isServicecentersLoading = true
      })
      .addCase(getServiceCenters.fulfilled, (state, action) => {
        state.isServicecentersLoading = false
        state.serviceCenters = action.payload.data.data;
      })
      .addCase(getServiceCenterDetails.pending, (state, action) => {
        state.isServicecenterDetailsLoading = true;
      })
      .addCase(getServiceCenterDetails.fulfilled, (state, action) => {
        state.isServicecenterDetailsLoading = false
        state.serviceCenterDetails = action.payload.data.data;
      })
      .addCase(getServiceCenterDetails.rejected, (state, action) => {
        state.isError = true;
        state.errorResponse = action.payload.data
      })
  }
});

export const { reset, setShowMoreServices, updateServiceCenters, updateShowSuggestedServiceCentersFlag, updateGarageDetailsFlag } = garageSlice.actions;

export const garageSelector = (state) => state.garage;

export default garageSlice.reducer;