/* eslint-disable no-useless-catch */


import { HOME_SERVICES } from '../config/constants';
import { HttpClient } from '../utils/httpClient';

class EmergencyService {
  constructor() {
    this.httpClient = new HttpClient();
  }

  async getServiceCategories(data) {
    try {
      const vehicleTypeId = data.vehicleType;
      const response = await this.httpClient.get(HOME_SERVICES + `?vehicleTypeId=${vehicleTypeId}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
}

export { EmergencyService };
