import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SkeletonLoader from "../../common/SkeletonLoaders/SkeletonLoaders";
import { vehicleSelector } from "../../features/account/vehicleSlice";
import { getCartItems } from "../../features/cart/cartSlice";
import {
  getfuelType,
  homeselector,
  updatefueltype,
} from "../../features/home/homeSlice";
import { setVehicleModal } from "../../features/servicepackage/servicepackageSlice";
import { CommonServices } from "../../utils/commonServices";

const SelectFuelType = (props) => {
  const commonServices = new CommonServices();

  const dispatch = useDispatch();
  const { selectedbrand, selectedmodel, fueltypes } = useSelector(homeselector);
  const { vehiclesList, getvehiclesListResp, addVehicleResponse } =
    useSelector(vehicleSelector);

  useEffect(() => {
    if (selectedmodel != null) {
      const vehicleModelId = JSON.parse(selectedmodel).vehicleModelId;
      const data = { vehicelModelId: vehicleModelId };
      dispatch(getfuelType(data));
    }
  }, [selectedmodel]);

  const fuelTypesList = [
    { id: "6043c2ad91ae0b1ca804478b", img: "/assets/icons/Diesel.svg" },
    { id: "6043c2ae91ae0b1ca804478e", img: "/assets/icons/Petrol.svg" },
    { id: "6043c2ae91ae0b1ca804478f", img: "/assets/icons/CNG.svg" },
    { id: "605cf419eaf9085070c96e9c", img: "" },
  ];

  const handlefueltypechange = (fuelType) => {
    props.changeFuelType(fuelType);
    dispatch(setVehicleModal(false));
    dispatch(updatefueltype(fuelType));
    // dispatch(getCartItems());
  };

  return (
    <>
      <form action="" className="side-box select-fueltype">
        <div class="header">
          <i
            class="fas fa-arrow-left"
            onClick={() => {
              props.gotoModelScreen();
            }}
          ></i>
          <label htmlFor="model">
            Select Fuel Type ({JSON.parse(selectedbrand).brandName} -{" "}
            {JSON.parse(selectedmodel).modelName})
          </label>
        </div>
        <div class=" brand-logos">
          {fueltypes ? (
            fueltypes.map((fuelType) => {
              let matchedObj = fuelTypesList.find((elem) => {
                if (fuelType.fueltypeId === elem.id) {
                  return elem;
                }
              });

              return (
                <>
                  <div
                    class="each-modal"
                    onClick={() => {
                      handlefueltypechange(JSON.stringify(fuelType));
                    }}
                  >
                    <img
                      src={matchedObj.img}
                      className="img-fluid"
                      style={{ height: "50px" }}
                    />
                    <div class=" card-body p-2 d-flex align-items-center justify-content-around">
                      {" "}
                      <p class="m-0">{fuelType.fueltype}</p>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <SkeletonLoader
              isSmallLoader={true}
              count={3}
              loopCount={9}
              width="20%"
            />
          )}
        </div>
      </form>
    </>
  );
};

export default SelectFuelType;
