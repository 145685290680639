import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EmergencyService } from '../../services/emergency.service';
import { CommonServices } from '../../utils/commonServices';
const commonServices = new CommonServices();
const services = commonServices.getLocalStrgJSON("CATEGORY");
const selectedCategory = commonServices.getLocalStrgJSON("MMX_SERVICE")
let intialState;
if (services != null) {
  intialState = {...services,selectedCategory: selectedCategory ? selectedCategory : null}
}
else {
  intialState = {
    categories: null,
    selectedCategory: selectedCategory ? selectedCategory : null,
    message: null,
    selectedCategoryIndex: null
  };
}


/**
 * Write your Async reducers here and add builders for them to the extraReducers object
 */
export const getServiceCategories = createAsyncThunk('emergencyservices/getServiceCategories', async (vehicleType, thunkAPI) => {
  const emergencyservice = new EmergencyService();
  try {
    const response = await emergencyservice.getServiceCategories(vehicleType);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});


const emergencyserviceSlice = createSlice({
  name: 'emergencyservices',
  initialState: intialState,
  reducers: {
    reset: (state) => {
      state.services = null
      state.message = null
    },
    updatecategoryselected: (state, data) => {
      state.selectedCategory = data.payload
    },
    updateCategories: (state, action) => {
      state.categories = action.payload
    },
    updateSelectedCategoryIndex: (state, action) => {
      state.selectedCategoryIndex = action.payload
    }
  },
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getServiceCategories.fulfilled, (state, action) => {
        let commonServices = new CommonServices();

        if (action.payload && action.payload.data) {
          var data = action.payload.data;
          if (data.statuscode == 200) {
            state.categories = action.payload.data.data;
            state.message = null;
            commonServices.setLocalStrg("CATEGORY", JSON.stringify(state));
          }
          else {
            state.message = data.message;
          }
        }
      });
  }
});

export const { reset, updatecategoryselected, updateCategories, updateSelectedCategoryIndex } = emergencyserviceSlice.actions;

export const emergencyserviceselector = (state) => state.emergencyservices;

export default emergencyserviceSlice.reducer;
