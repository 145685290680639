import { API_URL } from "../config/index";


const SEND_LOGIN_OTP = API_URL + "auth/send-login-otp";
const VERIFY_LOGIN_OTP = API_URL + "auth/verify-login-otp";
const REGISTER = API_URL + "auth/register";

// const CREATEWALLET = API_URL + "wallet/add-wallet";
// const WALLET_TRANSACTION = API_URL + "wallet/add-wallet-transaction";
const GETWALLET = API_URL + "wallet/get-wallet";
const WALLETHISTORY = API_URL + "wallet/wallet-history";
const APPLYWALLET = API_URL + "cart/apply-wallet-amount";
const UPDATEWALLET = API_URL + "wallet/update-wallet";
// const UPDATEREFERREDBYWALLET = API_URL + "wallet/update-referredby-wallet";
const GETAMOUNTTOBEREDEEMED = API_URL + "wallet/get-amount-toredeem";

const SLIDER = API_URL + "common/slider";
const BRAND_LIST = API_URL + "vehicle/brand";

const PROFILE = API_URL + "customer/profile"; // 2 APIs
const ADDRESS_LIST = API_URL + "customer/address-list";
const ADD_ADDRESS = API_URL + "customer/address";
const UPDATE_ADDRESS = API_URL + "customer/address/";
const DELETE_ADDRESS = API_URL + "customer/delete-address/";
const UPLOAD_PROFILE_PIC = API_URL + "customer/profile-pic";
// const DELETE_PROFILE = API_URL + "customer/delete-profile";
// const UPDATE_TOKEN = API_URL + "customer/update-token";
const VEHICLE_ADD = API_URL + "customer/vehicle-add";
const VEHICLE_LIST = API_URL + "customer/vehicle-list";
// const VEHICLE_DETAILS = API_URL + "customer/vehicle-details/";
const VEHICLE_UPDATE = API_URL + "customer/vehicle-update/";
const VEHICLE_DELETE = API_URL + "customer/vehicle-delete/";

const VEHICLE_TYPE = API_URL + "vehicle/type";
const VEHICLE_MODEL = API_URL + "vehicle/model";
// const VEHICLE_ALL_FUEL_TYPE = API_URL + "vehicle/fuel-type";
const VEHICLE_FUEL_TYPE_MODEL = API_URL + "vehicle/model-fuel-type";
// const VEHICLE_BODY_TYPE = API_URL + "vehicle/body-type";

// const SERVICES_CAT = API_URL + "services/cat"; // 2 APIs
// const SERVICES_LIST_ALL = API_URL + "services";
const SERVICES_LIST_WITH_PAGINATION = API_URL + "services/list";
// const SERVICES_DETAILS = API_URL + "services/";
const SERVICES_CENTER = API_URL + "service-center"; // 2 APIs
const SERVICE_CENTER_NEAR_ME = API_URL + "service-center/near-me"
const ALL_SERVICES = API_URL + "service-center/allservice/";
// const SERVICE_CENTER_RATING = API_URL + "service-center/rating-list";

const CART_ADD = API_URL + "cart/add";
const CART_REMOVE = API_URL + "cart/remove";
const CART_EMPTY = API_URL + "cart/delete";
const CART_UPDATE = API_URL + "cart/update";
const CART_DETAILS = API_URL + "cart/details";
const CART_COUNT = API_URL + "cart/service-count";
const CART_UPDATE_VEHICLE_ID = API_URL + "cart/update-vehicleid";
const CART_UPDATE_CUSTOMER_ID = API_URL + "cart/update-customer"

const COUPON_APPLY = API_URL + "coupon/apply";
const COUPON_LIST = API_URL + "coupon/list";
const COUPON_REMOVE = API_URL + "coupon/remove";

const AVAILABLE_SLOTS = API_URL + "service-center/available-slot/";

const ORDER_INITIALIZE = API_URL + "order/initialize";
const ORDER_PAYMENT = API_URL + "order/payment";
const ORDER_INIT_PAYMENT = API_URL + "order/init-payment";
const ORDER_LIST = API_URL + "order/list";
const ORDER_DETAILS = API_URL + "order/details/";
const ORDER_CANCEL = API_URL + "order/cancel/";
const ORDER_TRACKING_LIST = API_URL + "order/tracking-list";
// const REMAIN_PAYMENT_INIT = API_URL + "order/remain-payment-init/";
// const REMAIN_PAYMENT_UPDATE = API_URL + "order/remain-payment-update/";

// const ORDER_RATING = API_URL + "order/rating";
// const UPDATE_ESTIMATION = API_URL + "order/update-estimation/";

const CMS_PAGE_CONTENT = API_URL + "common/page-content";
// const NOTIFICATION_UNREAD_COUNT = API_URL + "common/notification/unread-count";
// const NOTIFICATION_UPDATE_AS_READ = API_URL + "common/notification/read";
// const NOTIFICATION_LIST = API_URL + "common/notification";
// const NOTIFICATION_DETAILS = API_URL + "common/notification/";

// // HOME API======
const HOME_SERVICES = API_URL + "home/services";

const WALLET_TRANSACTION_TYPE = {
    1: "On Board",
    2: "Referral",
    3: "Order",
    4: "Cancel Order"
};

const STATUS_CODES = {
    200: 200,
    201: 201,
    202: 202,
    204: 204,
    300: 300,
    301: 301,
    302: 302,
    303: 303,
    310: 310,
    311: 311,
    400: 400,
    401: 401
}
const API_key = "AIzaSyCxNa6LkaXwl47ldxxSsFLgIyFAFZ2GfcI";
const METAMECHX_ADDRESS = "#19, Kariyappa building, ISEC Road, 6th Cross,Nagarabhavi, Bengaluru, Karnataka, 560072";
const METAMECHX_MOBILE = "+91 83101 85672";
const METAMECHX_EMAIL = "info@metamechx.com"

export {
    METAMECHX_ADDRESS, SEND_LOGIN_OTP, VERIFY_LOGIN_OTP, REGISTER, HOME_SERVICES, ALL_SERVICES, VEHICLE_TYPE, BRAND_LIST, VEHICLE_MODEL,
    CART_ADD, CART_DETAILS, CART_REMOVE, CART_UPDATE, CART_EMPTY, CART_COUNT, CART_UPDATE_VEHICLE_ID, AVAILABLE_SLOTS
    , COUPON_REMOVE, COUPON_LIST, COUPON_APPLY, GETAMOUNTTOBEREDEEMED, APPLYWALLET, VEHICLE_FUEL_TYPE_MODEL,
    SERVICES_LIST_WITH_PAGINATION, SERVICES_CENTER, SERVICE_CENTER_NEAR_ME, ADDRESS_LIST, ADD_ADDRESS,
    UPDATE_ADDRESS, DELETE_ADDRESS, STATUS_CODES, API_key, SLIDER, ORDER_INITIALIZE, VEHICLE_ADD, VEHICLE_LIST, VEHICLE_UPDATE,
    ORDER_INIT_PAYMENT, UPDATEWALLET, CART_UPDATE_CUSTOMER_ID, PROFILE, ORDER_LIST, ORDER_DETAILS,ORDER_CANCEL, VEHICLE_DELETE, GETWALLET,
    WALLETHISTORY, UPLOAD_PROFILE_PIC, METAMECHX_MOBILE, METAMECHX_EMAIL, CMS_PAGE_CONTENT
};
