import { COUPON_APPLY, COUPON_LIST, COUPON_REMOVE, GETAMOUNTTOBEREDEEMED, APPLYWALLET } from '../config/constants';
import { HttpClient } from '../utils/httpClient';

class CouponsAndWalletService {
    constructor() {
        this.httpClient = new HttpClient();
    }

    async getCoupons(cartId) {
        try {
            const response = this.httpClient.get(COUPON_LIST + `?cartId=${cartId}`)
            return response;
        } catch (error) {

            throw error;
        }
    }

    async applyCoupon(data) {
        try {
            const response = this.httpClient.post(COUPON_APPLY, data)
            return response;
        } catch (error) {
            throw error;
        }
    }

    async removeCoupon(cartId) {
        try {
            const response = this.httpClient.delete(COUPON_REMOVE + `?cartId=${cartId}`)
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getWalletAmountToRedeem(orderAmount) {
        try {
            const response = this.httpClient.get(GETAMOUNTTOBEREDEEMED + `?orderamount=${orderAmount}`)
            return response;
        } catch (error) {
            throw error;
        }
    }

    async applyWalletAmount(data) {
        try {
            const response = this.httpClient.post(APPLYWALLET, data)
            return response;
        } catch (error) {
            throw error;
        }
    }
}

export { CouponsAndWalletService };
