import { memo } from "react";
import WritetoUs from "./WritetoUs";
import PartnerFAQs from "./PartnerFAQs";

const Partner = memo(() => {
  const data = [
    {
      icon: "/assets/imgs/partners/partner1.png",
      desc: "We wish to grow your business by us! Together we can make it!!",
    },
    {
      icon: "/assets/imgs/partners/partner2.png",
      desc: "Exponential reach for your service center through MetaMechX!",
    },
    {
      icon: "/assets/imgs/partners/partner3.png",
      desc: "Affordable and easy access to new marketing tools",
    },
    {
      icon: "/assets/imgs/partners/partner4.png",
      desc: "Online business is growing recently, Let us make your service center online.",
    },
    {
      icon: "/assets/imgs/partners/partner5.png",
      desc: "Get more consumers with our help.",
    },
    {
      icon: "/assets/imgs/partners/partner6.png",
      desc: "Increase your service center reach!",
    },
    {
      icon: "/assets/imgs/partners/partner7.png",
      desc: "Easy of customer insights, get reviews real time.",
    },
  ];

  return (
    <>
      <div class="container-fluid partners-page">
        <div className="main-device">
          <h1 class="partner-header mb-4">Why Partner With Us</h1>
        </div>
        <BenifitsCards cardsData={data} />
        <div className="d-flex align-items-center justify-content-center my-3">
          <a href="https://partner.metamechx.com/auth/signin" target="_blank">
            {" "}
            <button class="partner-login-btn">
              Login to Partner
            </button>
          </a>
        </div>
        <PartnerFAQs />
        <WritetoUs />
      </div>
    </>
  );
});
const BenifitsCards = (props) => {
  const { cardsData } = props;
  return (
    <>
      <div class="row justify-content-center">
        {cardsData?.length > 0 &&
          cardsData.map((card) => {
            return (
              <div class="each-card col-lg-2 col-md-5 col-sm-5">
                <div class="d-flex align-items-center justify-content-center">
                  <img src={card.icon} class="w-25 card-image" alt="" />
                </div>
                <div class="card-desc">
                  {" "}
                  <p class="m-0 text-center">{card.desc}</p>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Partner;
