import React, { useEffect, useRef, useState } from "react";

// import MouseIcon from "@mui/icons-material/Mouse";

const ImageCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    container.scrollTo({
      top: currentIndex * container.clientHeight,
      behavior: "smooth",
    });
  }, [currentIndex]);

  const handleScroll = (e) => {
    const container = containerRef.current;
    const scrollDistance = e.deltaY;
    const containerHeight = container.clientHeight;
    const maxIndex = images.length - 1;
    let newIndex = currentIndex;

    if (scrollDistance < 0 && currentIndex > 0) {
      newIndex = currentIndex - 1;
    } else if (scrollDistance > 0 && currentIndex < maxIndex) {
      newIndex = currentIndex + 1;
    }

    if (newIndex !== currentIndex) {
      setCurrentIndex(newIndex);
      container.scrollTo({
        top: newIndex * containerHeight,
        behavior: "smooth",
      });
    }
  };

  const currentImage = images[currentIndex];
  const hasMoreImages = currentIndex < images.length - 1;

  return (
    <div style={{ height: "100vh", overflow: "hidden" }} onWheel={handleScroll}>
      <div ref={containerRef} style={{ height: "100vh" }}>
        {images.map((image, index) => (
          <div
            key={index}
            style={{
              height: "100%",
              display: index === currentIndex ? "block" : "none",
              position: "relative",
            }}
          >
            <img
              src={image}
              alt="carousel"
              style={{ height: "100%", width: "100%" }}
              loading="lazy"
            />
            {index === currentIndex && hasMoreImages && (
              <div
                class="text-center pointer"
                style={{
                  position: "absolute",
                  bottom: "2%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                }}
                onClick={() => {
                  setCurrentIndex(currentIndex + 1);
                }}
              >
                <p class="m-2">Scroll Down </p>
                <i
                  style={{ color: "white" }}
                  class="fas fa-chevron-down text-center"
                ></i>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageCarousel;
