import { SERVICE_CENTER_NEAR_ME, SERVICES_CENTER } from '../config/constants';
import { HttpClient } from '../utils/httpClient';
import { CommonServices } from '../utils/commonServices';
class GaragesService {
  constructor() {
    this.httpClient = new HttpClient();
    this.commonServices = new CommonServices()
  }

  async getGaragesServices(data) {
    try {
      const response = await this.httpClient.post(SERVICE_CENTER_NEAR_ME, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getServiceCenters(data) {
    try {
      const filterData = {
        ...data,
        filter: {
          search: "",
          price: "",
          rating: 0,
          distance: 0,
          userLocation: {
            lat: this.commonServices.getLocalStrgJSON("MMX_USER_LATLON")?.lat ? this.commonServices.getLocalStrgJSON("MMX_USER_LATLON")?.lat : 12.9521566,
            lng: this.commonServices.getLocalStrgJSON("MMX_USER_LATLON")?.lng ? this.commonServices.getLocalStrgJSON("MMX_USER_LATLON")?.lng : 77.4990377,
            pincode: this.commonServices.getLocalStrg("MMX_USER_PINCODE") || "560056"
          }
        },
      }
      const response = await this.httpClient.post(SERVICES_CENTER, filterData);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getServiceCenterDetails(serviceCenterId) {
    try {
      const response = await this.httpClient.get(SERVICES_CENTER + "/" + serviceCenterId);
      return response;
    } catch (error) {
      throw error;
    }
  }
}

export { GaragesService };
