import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { STATUS_CODES } from "../../config/constants";
import {
  customerProfileSelector,
  getCustomerDetails,
  reset,
  updateProfile,
  updateProfilePic,
} from "../../features/account/customerProfileSlice";
import "./Profile.css";

const Profile = () => {
  const dispatch = useDispatch();
  const [profile, setProfile] = useState({ file: null, base64URL: "" });
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [mobileNum, setMobileNum] = useState(null);

  const { customerDetails, updateProfileResp, updateProfilePicResp } =
    useSelector(customerProfileSelector);

  useEffect(() => {
    dispatch(getCustomerDetails());
  }, []);

  useEffect(() => {
    if (customerDetails) {
      setProfile(customerDetails.profile_pic);
      setName(customerDetails.full_name);
      setMobileNum(customerDetails.mobile);
      setEmail(customerDetails.email);
    }
  }, [customerDetails]);

  const handleSave = () => {
    const body = {
      full_name: name,
      email,
      // mobile: mobileNum,
    };
    dispatch(updateProfile(body));
  };

  useEffect(() => {
    if (
      updateProfileResp &&
      updateProfileResp.statuscode === STATUS_CODES[200]
    ) {
      toast.success(updateProfileResp?.message, {
        toastId: "updateProfile1",
        autoClose: 2000,
        hideProgressBar: true,
      });
      dispatch(getCustomerDetails());
    } else {
      toast.error(updateProfileResp?.message, {
        toastId: "updateProfile2",
        autoClose: 2000,
        hideProgressBar: true,
      });
    }
    dispatch(reset());
  }, [updateProfileResp]);

  const onProfileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      // let file = profile.file;
      let file = e.target.files[0];
      const encodedData = getBase64(file).then((result) => {
        file["base64"] = result;
        const body = { imageData: result };
        dispatch(updateProfilePic(body));
      });

      let img = URL.createObjectURL(e.target.files[0]);
      setProfile(img);
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  useEffect(() => {
    if (updateProfilePicResp?.statuscode === STATUS_CODES[200]) {
      toast.success(updateProfilePicResp?.message, {
        toastId: "updateProfilePic1",
        autoClose: 2000,
        hideProgressBar: true,
      });
      dispatch(getCustomerDetails());
    } else {
      toast.error(updateProfilePicResp?.message, {
        toastId: "updateProfilePic2",
        autoClose: 2000,
        hideProgressBar: true,
      });
    }
    dispatch(reset());
  }, [updateProfilePicResp]);
  return (
    <>
      <div>
        <h3>Profile Details</h3>
        <p>An overview of personal details- name, email, phone etc.</p>
      </div>
      <div class="profile-logo mx-auto rounded-circle mt-4 position-relative">
        <div type="button" class="btn btn-circle btn-md border-2 ">
          <img
            // src="assets/imgs/testimonials/img-1.jpg"
            src={profile || "assets/imgs/testimonials/img-1.png"}
            class="profile_img"
            alt=""
          />
          <div class="edit-icon pointer">
            {" "}
            <img
              src="/assets/imgs/account/edit-icon.png"
              class="far fa-edit "
            ></img>
          </div>
          <input
            type="file"
            class="pointer"
            onChange={onProfileChange}
            accept="image/png, image/jpeg"
          />
        </div>
      </div>

      <div class="profile_details mt-5">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-5 col-sm-12 mb-3">
            <p class="mb-2 label">Name</p>
            <input
              type="text"
              placeholder="Ex : Pratyusha"
              class="w-100"
              value={name || ""}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </div>
          <div class="col-lg-4 col-md-5 col-sm-12 mb-3">
            <p class="mb-2 label">Mobile</p>
            <input
              type="text"
              placeholder="Ex : 8888888888"
              disabled
              value={mobileNum}
              class="bg-transparent w-100"
            />
          </div>
        </div>
        <div class="row justify-content-center mb-3">
          <div class="col-lg-8 col-md-10 col-sm-12">
            <p class="mb-2 label">Email</p>
            <input
              type="email"
              placeholder="Ex : abc@gmail.com"
              class="w-100"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </div>
        </div>
        <div class="my-5 d-flex justify-content-center">
          <button
            class="save-btn"
            onClick={() => {
              handleSave();
            }}
          >
            Save Details
          </button>
        </div>
      </div>
    </>
  );
};

export default Profile;
