import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { STATUS_CODES } from "../../config/constants";
import {
  addVehicle,
  getUserVehiclesList,
  vehicleSelector,
  reset,
} from "../../features/account/vehicleSlice";
import { authSelector } from "../../features/auth/authSlice";
import { updateCartVehicleId } from "../../features/cart/cartSlice";
import { homeselector } from "../../features/home/homeSlice";
import { CommonServices } from "../../utils/commonServices";
import "./search-services.css";
import SelectFuelType from "./SelectFuelType";
import SelectManufacturer from "./SelectManufacturer";
import SelectVehicleModel from "./SelectVehicleModel";

const SearchServices = () => {
  const { isAuthenticated } = useSelector(authSelector);
  const { vehiclesList, getvehiclesListResp, addVehicleResponse } =
    useSelector(vehicleSelector);
  const [isManufacturerInputSelected, setIsManufacturerInputSelected] =
    useState(false);
  const [isModelInputSelected, setIsModelInputSelected] = useState(false);
  const [isFuelTypeSelected, SetIsFuelTypeSelected] = useState(false);
  // const [isModelYearSelected, setIsModelYearSelected] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 500px) and (max-width: 912px)",
  });
  const isLaptopOrDesktop = useMediaQuery({
    query: "(min-width: 1008px) and (max-width: 1824px)",
  });
  const {
    vehicleType,
    brands,
    selectedbrand,
    models,
    selectedfueltype,
    selectedYear,
    selectedmodel,
    fueltypes,
    selectedvehicle,
  } = useSelector(homeselector);

  const commonServices = new CommonServices();

  const handleClose = () => {
    setShowModal(false);
  };

  const handleBrandChange = () => {
    setIsManufacturerInputSelected(false);
    setIsModelInputSelected(true);
    SetIsFuelTypeSelected(false);
  };

  const closeManufacturerScreen = () => {
    setIsManufacturerInputSelected(false);
    setIsModelInputSelected(false);
    SetIsFuelTypeSelected(false);
    setShowModal(false);
  };

  const handleVehicleModelChange = () => {
    setIsModelInputSelected(false);
    SetIsFuelTypeSelected(true);
  };

  const gotoManufacturerScreen = () => {
    setIsModelInputSelected(false);
    setIsManufacturerInputSelected(true);
  };

  const handleFueltypeChange = (fuelType) => {
    SetIsFuelTypeSelected(false);
    setShowModal(false);
    if (isAuthenticated) {
      addSelectedVehicle(fuelType);
    }
  };

  useEffect(()=>{
    const vehicleId = commonServices.getLocalStrgJSON("MMX_SELECTED_VEHICLE")?.customerVehicleId;
    if(isAuthenticated &&  vehicleId === undefined){
      addSelectedVehicle("")
    }
  },[isAuthenticated])
  
  const gotoModelScreen = () => {
    setIsModelInputSelected(true);
  };
  
  const addSelectedVehicle = (fuelType="") => {
    if (isAuthenticated && selectedmodel && (fuelType || selectedfueltype)) {
      const body = {
        vehicleBrandId: JSON.parse(selectedbrand).brandId,
        vehicleModelId: JSON.parse(selectedmodel).vehicleModelId,
        vehicleFueltypeId: JSON.parse(selectedfueltype)?.fueltypeId?  JSON.parse(selectedfueltype).fueltypeId : JSON.parse(fuelType).fueltypeId,
        vehicleTypeId: vehicleType,
        manufactured_year: selectedYear && selectedYear,
      };
      dispatch(addVehicle(body));
    }
  };

  useEffect(() => {
    if (
      addVehicleResponse &&
      addVehicleResponse.statuscode === STATUS_CODES[200]
    ) {
      commonServices.setLocalStrg(
        "MMX_SELECTED_VEHICLE",
        JSON.stringify(addVehicleResponse?.data)
      );
      const cartId = commonServices.getLocalStrg("MMX_CURRENT_CARTID");
      if (cartId !== null && cartId !== undefined && cartId !== "") {
        dispatch(updateCartVehicleId());
      }
      dispatch(reset());
    }
  }, [addVehicleResponse]);

  const searchComponents = () => {
    return (
      <>
        {isManufacturerInputSelected && (
          <SelectManufacturer
            changeBrand={handleBrandChange}
            closeManufacturerScreen={closeManufacturerScreen}
          />
        )}
        {isModelInputSelected && (
          <SelectVehicleModel
            changeVehicleModel={handleVehicleModelChange}
            gotoManufacturerScreen={gotoManufacturerScreen}
          />
        )}
        {selectedbrand != null &&
          selectedmodel != null &&
          selectedfueltype == null &&
          !isModelInputSelected &&
          !isManufacturerInputSelected &&
          isFuelTypeSelected && (
            <SelectFuelType
              changeFuelType={handleFueltypeChange}
              gotoModelScreen={gotoModelScreen}
            />
          )}
      </>
    );
  };

  const searchServices = () => {
    navigate("/services");
  };

  return (
    <>
      <div className="col-lg-12 fix_strip">
        <div className="card">
          <div className="card-body">
            <div className="input-form">
              {!isManufacturerInputSelected &&
                !isModelInputSelected &&
                !isFuelTypeSelected && (
                  <>
                    <h4>Get YOUR CAR serviced by experts.</h4>
                    <p>
                      Search from our service centers and book a slot closest to
                      you and at your convenience{" "}
                    </p>
                    <form action="" className="side-box">
                      <label htmlFor="Manufacturer" className="form-label">
                        Search Manufacturer
                      </label>
                      <input
                        type="text"
                        autocomplete="off"
                        placeholder="Select Your Vehicle"
                        value={selectedvehicle}
                        name="Manufacturer"
                        className="inside form-control"
                        id=" Manufacturer"
                        onSelect={() => {
                          setShowModal(true);
                          setIsManufacturerInputSelected(true);
                        }}
                      />
                      <div class="d-flex justify-content-around">
                        <button
                          type="submit"
                          onClick={() => {
                            searchServices();
                          }}
                          className="text-center search_main btn"
                        >
                          Search Services
                        </button>
                      </div>
                    </form>
                  </>
                )}
              {isLaptopOrDesktop && searchComponents()}
            </div>
          </div>
        </div>
      </div>
      {(isMobile || isTablet) && (
        <Modal
          className="select-manufacturer-modal"
          show={showModal}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body>{searchComponents()}</Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default SearchServices;
