const { REACT_APP_SECURE_KEY, REACT_APP_API_URL,REACT_APP_NODE_ENV,REACT_APP_S3_BUCKET_URL, REACT_APP_CRONJOB_URL } = process.env;

// Get the API URL from the environment variables
let API_URL;
let SECURE_KEY;
let S3_BUCKET_URL = REACT_APP_S3_BUCKET_URL;
let NODE_ENV = REACT_APP_NODE_ENV
if (process.env.REACT_APP_API_URL) {
  API_URL = REACT_APP_API_URL;
  SECURE_KEY = REACT_APP_SECURE_KEY;
} else {
  API_URL = "http://144.217.85.73:3330/";
  SECURE_KEY = "JVYy6a3xNeE3yaUs";
}

export { API_URL, SECURE_KEY, S3_BUCKET_URL,REACT_APP_CRONJOB_URL,NODE_ENV };
