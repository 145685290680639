import { HttpClient } from '../utils/httpClient';
import { CART_ADD, CART_DETAILS, CART_EMPTY, CART_REMOVE, CART_UPDATE, CART_UPDATE_CUSTOMER_ID, CART_UPDATE_VEHICLE_ID } from '../config/constants';
import { CommonServices } from '../utils/commonServices';
class CartService {
    constructor() {
        this.httpClient = new HttpClient();
        this.commonServices = new CommonServices();
    }

    async addToCart(data) {
        try {
            const response = await this.httpClient.post(CART_ADD, data);
            return response
        } catch (error) {
            throw error;
        }
    }

    async getCartItems() {
        try {
            const cartId = this.commonServices.getLocalStrg("MMX_CURRENT_CARTID")
            const { selectedmodel, selectedfueltype } = this.commonServices.getLocalStrgJSON("MMX_VEHICLE");
            const customerVehicleId = this.commonServices.getLocalStrgJSON("MMX_SELECTED_VEHICLE")?.customerVehicleId
            const vehicleModelId = JSON.parse(selectedmodel).vehicleModelId;
            const vehicleFueltypeId = JSON.parse(selectedfueltype).fueltypeId;

            let query = `?vehicleModelId=${vehicleModelId}&vehicleFueltypeId=${vehicleFueltypeId}`;

            if (customerVehicleId != null && customerVehicleId != undefined && customerVehicleId != "") {
                query = query + `&customerVehicleId=${customerVehicleId}`
            }
            if (cartId != null && cartId != undefined && cartId != "") {
                query = query + `&cartId=${cartId}`
            }
            const response = await this.httpClient.get(CART_DETAILS + query);

            return response;
        } catch (error) {
            throw error;
        }
    }

    async deleteCart(cartId) {
        try {
            const response = await this.httpClient.delete(CART_EMPTY + `?cartId=${cartId}`);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async removeCartItem(data) {
        try {
            const { cartId, cartItemId } = data
            const response = await this.httpClient.delete(CART_REMOVE + `?cartId=${cartId}&cartItemId=${cartItemId}`);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async updateCart(data) {
        try {
            const response = await this.httpClient.put(CART_UPDATE, data);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async updateCartVehicleId() {
        try {
            const cartId = this.commonServices.getLocalStrg("MMX_CURRENT_CARTID");
            const customerVehicleId = this.commonServices.getLocalStrgJSON("MMX_SELECTED_VEHICLE")?.customerVehicleId
            const updateResponse = await this.httpClient.put(CART_UPDATE_VEHICLE_ID, { cartId, customerVehicleId });
            const detailsResponse = this.getCartItems()
            return detailsResponse;
        } catch (error) {
            throw error;
        }
    }

    async updateCartCustomerId() {
        try {
            const cartId = this.commonServices.getLocalStrg("MMX_CURRENT_CARTID");
            const response = await this.httpClient.put(CART_UPDATE_CUSTOMER_ID, { cartId });
            return response;
        } catch (error) {
            throw error;
        }
    }
}

export { CartService }