import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PaymentPageService } from '../../services/paymentPage.sevice';

const initialState = {
    isLoading: false,
    isError: false,
    isSuccess: false,
    isOrderDetailByOrderNumberSuccess: false,
    isOrderDetailByOrderNumberFailure: false,
    isInitializeOrderPaymentSuccess: false,
    isInitializeOrderPaymentFailure: false,
    orderDetailByOrderNumberResp: null,
    initializeOrderPaymentResp: null,
    paymentUpdateResp: null,
    isPaymentUpdatedSuccess: false,
    isPaymentUpdatedFailure: false
}

export const getOrderDetailsByOrderNumber = createAsyncThunk('outsidePayment/getOrderDetailsByOrderNumber', async (data, thunkAPI) => {
    const paymentPageService = new PaymentPageService()
    try {
        const response = await paymentPageService.getOrderDetailsByOrderNumber(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const initializeOrderPayment = createAsyncThunk('outsidePayment/initializeOrderPayment', async (data, thunkAPI) => {
    const paymentPageService = new PaymentPageService()
    try {
        const response = await paymentPageService.initializeOrderPayment(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const orderPaymentUpdate = createAsyncThunk('outsidePayment/orderPaymentUpdate', async (data, thunkAPI) => {
    const paymentPageService = new PaymentPageService()
    try {
        const response = await paymentPageService.orderPaymentUpdate(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

const outsidePaymentSlice = createSlice({
    name: 'outsidePayment',
    initialState: initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = false;
            state.isOrderDetailByOrderNumberSuccess = false;
            state.isOrderDetailByOrderNumberFailure = false;
            state.isInitializeOrderPaymentSuccess = false;
            state.isInitializeOrderPaymentFailure = false;
            state.orderDetailByOrderNumberResp = null;
            state.initializeOrderPaymentResp = null;
        }
    },
    extraReducers: (builder) => {
        //add your async reducers here
        builder
            .addCase(getOrderDetailsByOrderNumber.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getOrderDetailsByOrderNumber.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isOrderDetailByOrderNumberSuccess = true;
                if (action.payload) {
                    state.orderDetailByOrderNumberResp = action.payload.data
                }
            })
            .addCase(getOrderDetailsByOrderNumber.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isOrderDetailByOrderNumberFailure = false;
                if (action.payload) {
                    state.orderDetailByOrderNumberResp = action.payload.data
                }
            })
            .addCase(initializeOrderPayment.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(initializeOrderPayment.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isInitializeOrderPaymentSuccess = true;
                if (action.payload) {
                    state.initializeOrderPaymentResp = action.payload.data
                }
            })
            .addCase(initializeOrderPayment.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isInitializeOrderPaymentFailure = true;
                if (action.payload) {
                    state.initializeOrderPaymentResp = action.payload.data
                }
            })
            .addCase(orderPaymentUpdate.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(orderPaymentUpdate.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isPaymentUpdatedSuccess = true;
                if (action.payload) {
                    state.paymentUpdateResp = action.payload;
                    console.log("state.paymentUpdateResp::", state.paymentUpdateResp)
                }
            })
            .addCase(orderPaymentUpdate.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isPaymentUpdatedFailure = true;
                if (action.payload) {
                    state.paymentUpdateResp = action.payload.data
                }
            })
    }
});

export const { reset } = outsidePaymentSlice.actions;
export const outsidePaymentSelector = (state) => state.outsidePayment;
export default outsidePaymentSlice.reducer;