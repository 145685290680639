import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { WalletService } from "../../services/wallet.service";
import {CronjobService} from '../../services/cronjob.service'
const initialState = {
    contactUsResp:null
}


export const contactUs = createAsyncThunk('partner/contactUs', async (data, thunkAPI) => {
    const cronjobService = new CronjobService();
    try {
        const response = await cronjobService.contactUs(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

const partnerSlice = createSlice({
    name: "partner",
    initialState,
    reducers: {
        reset: (state) => {
            state.contactUsResp = null;
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(contactUs.fulfilled, (state, action) => {
                state.contactUsResp = action.payload.data;
            })
    }
})

export const { reset } = partnerSlice.actions;
export const partnerSelector = (state) => state.partner;
export default partnerSlice.reducer;