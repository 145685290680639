/* eslint-disable no-useless-catch */
import { HttpClient } from '../utils/httpClient';
import {REACT_APP_CRONJOB_URL} from "../config/"
class CronjobService {
  constructor() {
    this.httpClient = new HttpClient(REACT_APP_CRONJOB_URL);
  }

  async contactUs(data) {
    try {
      const response = await this.httpClient.post("common/contact-us", data);
      // const response = CarSapNearYouData;
      return response;
    } catch (error) {
      throw error;
    }
  }

}

export { CronjobService };

