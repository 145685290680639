import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { authSelector } from '../../features/auth/authSlice';
import { CommonServices } from '../../utils/commonServices';
import "../ReferandEarn/reference.css";

function Refer(props) {

  const commonServices = new CommonServices();

  const { isAuthenticated } = useSelector(authSelector)
  const [referralCode, setreferralCode] = useState(null)
  useEffect(() => {
    if (isAuthenticated) {
      const referral_code = commonServices.getLocalStrgJSON("MMX_USER").referral_code
      setreferralCode(referral_code)
    } else {
      setreferralCode(null)
    }
  }, [isAuthenticated])

  const handleClick = () => {
    if (!isAuthenticated) {
      toast.error("please login to refer", {
        toastId: "doLogin",
        autoClose: 1000,
        hideProgressBar: true,
      })
    }
  }

  return (
    <div className="refer my-4">
      <h4>REFER AND EARN!!</h4>
      <div className="row">
        <div className="col-lg-6 referImg">
          <img src="/assets/imgs/reference/reference.png" className="img-fluid" />
        </div>
        <div className="col-lg-6 col-sm-12">
          <div className="my-7 fw-600">
            <strong> <p class="fw-bold m-0">Get a friend to start using MMX today and earn<br /> ₹ 100 when they complete their first transaction.</p></strong>
            <br />
          </div>
          <h5>Share your Referral Code</h5>
          <button className="refer-btn" onClick={handleClick}>{referralCode ? referralCode : "Refer Now"}</button>
        </div>
      </div>
    </div>
  );
}

export default Refer;