/* eslint-disable no-useless-catch */


import { VEHICLE_TYPE, BRAND_LIST, VEHICLE_MODEL, VEHICLE_FUEL_TYPE_MODEL, VEHICLE_ADD } from '../config/constants';
import { HttpClient } from '../utils/httpClient';

class HomeService {
    constructor() {
        this.httpClient = new HttpClient();
    }

    async getvehicletype(data) {
        try {
            const response = await this.httpClient.get(VEHICLE_TYPE);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getBrandList(data) {
        try {
            const vehicleTypeId = data.vehicleType;
            const searchKey = data.search;
            let url = `${BRAND_LIST}?vehicleTypeIds=${vehicleTypeId}`;
            if (data.search !== undefined && data.search !== null && data.search !== "") {
                url = `${BRAND_LIST}?search=${searchKey}&vehicleTypeIds=${vehicleTypeId}`;
            }
            const response = await this.httpClient.get(url);
            return response;
        } catch (error) {
            throw error;
        }

    }

    async getFuelList(data) {
        try {
            const vehicelModelId = data.vehicelModelId;
            const response = await this.httpClient.get(VEHICLE_FUEL_TYPE_MODEL + `?vehicleModelId=${vehicelModelId}`);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async getModelList(data) {
        try {
            const brandId = data.brandId;
            const vehicleTypeId = data.vehicleType;
            const searchKey = data.search;
            let url = `${VEHICLE_MODEL}?brandId=${brandId}&vehicleTypeId=${vehicleTypeId}`;
            if (data.search !== undefined && data.search !== null) {
                url = `${VEHICLE_MODEL}?brandId=${brandId}&search=${searchKey}&vehicleTypeId=${vehicleTypeId}`;
            }
            const response = await this.httpClient.get(url);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async addVehicle(body) {
        try {
            const response = this.httpClient.post(VEHICLE_ADD, body)
            return response;
        } catch (error) {
            throw error;
        }
    }
}

export { HomeService };
