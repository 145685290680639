import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SkeletonLoader from "../../common/SkeletonLoaders/SkeletonLoaders";
import { STATUS_CODES } from "../../config/constants";
import {
  addToCart,
  cartSelector,
  getCartItems,
  reset,
  updateAddToCartResponse
} from "../../features/cart/cartSlice";
import {
  garageSelector,
  getServiceCenterDetails,
  getServiceCenters,
  updateShowSuggestedServiceCentersFlag,
} from "../../features/garage/garageSlice";
import { carspaserviceselector } from "../../features/spa/spaserviceSlice";
import { CommonServices } from "../../utils/commonServices";
import SuggestedSpaOrGarages from "../suggestedSpaOrGarages/SuggestedSpaOrGarages";
import "./Service-center.css";
import ShowChangeServiceCenterConfirmModal from "./ShowChangeServiceCenterConfirmModal";
import { filterSuggestedServiceCenters } from "../../utils";

const ServiceCenter = (props) => {
  const commonServices = new CommonServices();
  const {
    serviceCenters,
    showSuggestedServiceCentersFlag,
    showGarageDetailsFlag,
    isServicecentersLoading,
    showMoreServices
  } = useSelector(garageSelector);
  const { showServiceCenterDetails } = props;
  const [serviceCentersList, setServiceCentersList] = useState(null);
  const [customerVehicleId, setCustomerVehicleId] = useState(null);
  const [cart, setCart] = useState(null);
  const [show, setShow] = useState(false);
  const { addToCartResponse, cartDetails, deleteCartResponse } =
    useSelector(cartSelector);
  const { showSpaDetailsFlag } = useSelector(carspaserviceselector);
  const dispatch = useDispatch();
  const vehicle = commonServices.getLocalStrgJSON("MMX_VEHICLE");
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 500px) and (max-width: 912px)",
  });

  useEffect(() => {
    if (!showGarageDetailsFlag && !showSpaDetailsFlag) {
      getServiceCentersList();
    }
    dispatch(updateAddToCartResponse(null));
  }, []);

  useEffect(() => {
    if (serviceCenters) {
      if (showSuggestedServiceCentersFlag) {
        const currentServiceCenter =
          commonServices.getLocalStrgJSON("MMX_SERVICE_CENTER");
        const filteredServiceCenters = filterSuggestedServiceCenters(serviceCenters, currentServiceCenter);
        setServiceCentersList(filteredServiceCenters);
      } else {
        setServiceCentersList(serviceCenters);
      }
    } else {
      setServiceCentersList(null);
    }
  }, [serviceCenters]);

  useEffect(() => {
    if (cartDetails) {
      if (cartDetails.statuscode === STATUS_CODES[200]) {
        setCart(cartDetails.data);
        commonServices.setLocalStrg(
          "MMX_CURRENT_CARTID",
          cartDetails.data.cartId
        );
      } else if (cartDetails.statuscode === STATUS_CODES[300]) {
        localStorage.removeItem("MMX_CART");
        localStorage.removeItem("MMX_CURRENT_CARTID");
      } else {
        // this.getServiceCenters(false, true, {});
        // this.commonService.presentToast(cartDetails.message, "warning");
      }
    }
  }, [cartDetails]);

  const getServiceCentersList = () => {
    const service = commonServices.getLocalStrgJSON("MMX_SERVICE_DETAILS");
    const data = {
      customerVehicleId: customerVehicleId || "",
      vehicleTypeId: commonServices.getLocalStrg("VEHICLE_TYPE"),
      vehicleModelId: JSON.parse(vehicle?.selectedmodel)?.vehicleModelId,
      vehicleFueltypeId: JSON.parse(vehicle?.selectedfueltype)?.fueltypeId,
      vehicleBodyTypeId: JSON.parse(vehicle?.selectedmodel)?.vehicleBodyTypeId,
      servicesId: service?.servicesId,
    };
    dispatch(getServiceCenters(data));
  };

  const addToCartList = (center) => {
    commonServices.setLocalStrg("MMX_SERVICE_CENTER", JSON.stringify({serviceCenterId:center.serviceCenterId,servicesId:center.servicesId,scsId:center.scsId,service_center_dispaly_name:center.service_center_dispaly_name}));
    const service = commonServices.getLocalStrgJSON(
      "MMX_OTHER_SERVICE_DETAILS"
    );
    const data = {
      customerVehicleId:
        commonServices.getLocalStrgJSON("MMX_SELECTED_VEHICLE")
          ?.customerVehicleId || "",
      servicesId: service?.servicesId ? service?.servicesId : center.servicesId,
      vehicleModelId: JSON.parse(vehicle.selectedmodel).vehicleModelId,
      vehicleFueltypeId: JSON.parse(vehicle.selectedfueltype).fueltypeId,
      serviceCenterId: center.serviceCenterId,
      scsId: service?.scsId ? service.scsId : center.scsId,
    };
    dispatch(addToCart(data));
  };

  useEffect(() => {
    if (addToCartResponse !== null) {
      if (addToCartResponse.statuscode === STATUS_CODES[200]) {
        toast.info("1 Service Added", {
          toastId: "success1",
          autoClose: 1000,
          hideProgressBar: true,
        });
        localStorage.removeItem("MMX_OTHER_SERVICE_DETAILS");
        dispatch(getCartItems());
      } else if (addToCartResponse.statuscode === STATUS_CODES[300]) {
        setShow(true);
      } else if (addToCartResponse.statuscode === STATUS_CODES[301]) {
        toast.warning("Service already exist in cart", {
          toastId: "warning1",
          autoClose: 1000,
          hideProgressBar: true,
        });
        dispatch(getCartItems());
      } else if (addToCartResponse.statuscode === STATUS_CODES[400]) {
        toast.warning("Validation error", {
          toastId: "Validation",
          autoClose: 1000,
          hideProgressBar: true,
        });
      } else {
        toast.error(addToCartResponse.message, {
          toastId: "error",
          autoClose: 1000,
          hideProgressBar: true,
        });
      }
    }
    dispatch(reset());
  }, [addToCartResponse]);

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (deleteCartResponse) {
      const currentServiceCenter =
        commonServices.getLocalStrgJSON("MMX_SERVICE_CENTER");
      addToCartList(currentServiceCenter);
      if (showSuggestedServiceCentersFlag) {
        const filteredServiceCenters = filterSuggestedServiceCenters(serviceCenters, currentServiceCenter);
        setServiceCentersList(filteredServiceCenters);
      }
    }
  }, [deleteCartResponse]);


  const showDetails = (center) => {
    window.scrollTo(0, 0);
    showServiceCenterDetails(center);
    getServiceCenterCompleteDetails(center);
    dispatch(updateShowSuggestedServiceCentersFlag(true));
    const filteredServiceCenters = filterSuggestedServiceCenters(serviceCenters, center);
    setServiceCentersList(filteredServiceCenters);
  };

  const getServiceCenterCompleteDetails = (center) => {
    commonServices.setLocalStrg("MMX_SERVICE_CENTER", JSON.stringify({serviceCenterId:center.serviceCenterId,servicesId:center.servicesId,scsId:center.scsId,service_center_dispaly_name:center.service_center_dispaly_name}));
    dispatch(getServiceCenterDetails(center.serviceCenterId));
  };

  return (
    <>
      {showSuggestedServiceCentersFlag && serviceCentersList?.length > 0 && (
        <>
          <h5 class="mt-5">
            Suggested{" "}
            {showGarageDetailsFlag
              ? "Garages Near You"
              : showSpaDetailsFlag
                ? "CarSpas Near You"
                : "Service center"}
          </h5>
        </>
      )}
      {serviceCentersList ? (
        !showGarageDetailsFlag && !showSpaDetailsFlag ? (
          serviceCentersList.map((center) => {
            return (
              <>
                <div
                  class="service-center"
                  onClick={() => {
                    if (isMobile || isTablet) {
                      showDetails(center);
                      // showServiceCenterDetails(center);
                      // displayServiceCenterDetails(center);
                    }
                  }}
                >
                  <div class="row">
                    <div class="col-lg-2 col-md-4 col-sm-3 m-0">
                      <img
                        src="/assets/imgs/garages/garage-image.png"
                        // src={center.service_center_pic}
                        className="img-fluid h-100"
                      />
                    </div>
                    <div class="col-lg-10 col-md-8 col-sm-9">
                      <div class="garageName">
                        <h5>{center.service_center_name}</h5>
                      </div>
                      <ul class="p-0 row">
                        <li class="col-lg-3 m-0 col-sm-12">
                          {/* <i class="fas fa-route"></i> */}
                          <img
                            src="/assets/imgs/service-center/distance.png"
                            alt=""
                            width={18}
                          />
                          {center.distance} Km away
                        </li>
                        <li class="col-lg-5 col-sm-12">
                          <img
                            src="/assets/imgs/service-center/pickup&drop.png"
                            alt=""
                            width={18}
                          />
                          Pick and drop available
                        </li>
                        <li class="col-lg-4 col-sm-12">
                          <img
                            src="/assets/imgs/service-center/time.png"
                            alt=""
                            width={18}
                          />
                          Service time = {center.service_time} hrs
                        </li>
                      </ul>
                      <ul class="p-0 mb-0 row features">
                        <li class="col-lg-4 col-md-6 col-sm-8">
                          <div class="garage-features">
                            <img
                              src="/assets/imgs/service-center/sanitize.png"
                              alt=""
                              width={18}
                            />{" "}
                            &nbsp;
                            <p class="m-0">Fully Sanitized</p>
                          </div>
                        </li>
                        <li class="col-lg-4 col-md-6 m-0 col-sm-8">
                          <div class="garage-features">
                            <img
                              src="/assets/imgs/service-center/discount.png"
                              alt=""
                              width={18}
                            />{" "}
                            &nbsp;
                            <p class="m-0">Best Offers available</p>
                          </div>
                        </li>
                        <li class="col-lg-4 col-md-6 col-sm-8">
                          <div class="garage-features d-flex align-items-center">
                            {/* <i class="fas fa-percent" aria-hidden="true"></i> */}
                            <img
                              src="/assets/imgs/service-center/verified.png"
                              alt=""
                              width={18}
                            />{" "}
                            &nbsp; &nbsp; <p class="m-0">Verified</p>
                          </div>
                        </li>
                      </ul>
                      <div class="footer price">
                        <div class="d-flex align-items-center">
                          {center.actual_service_cost !==
                            center.after_discount_service_cost && (
                              <del
                                class="actual-price m-0"
                                style={{ textDecoration: "line-through" }}
                              >
                                {" "}
                                <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                                {center.actual_service_cost}
                              </del>
                            )}
                          &nbsp;
                          <p class="current-price m-0">
                            <i class="fa fa-inr" aria-hidden="true"></i>
                            {center.after_discount_service_cost}
                          </p>
                        </div>
                        <div class="right">
                          <p
                            class="details fw-bold pointer details m-0"
                            onClick={() => {
                              showDetails(center);
                            }}
                          >
                            Details{">>"} &nbsp;
                          </p>
                          <button
                            class="add-cart-btn"
                            onClick={() => {
                              addToCartList(center);
                            }}
                          >
                            <i class="fas fa-plus"></i> ADD
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="features-mobile">
                    <div class="footer my-2">
                      <div class="left">
                        {center.actual_service_cost !==
                          center.after_discount_service_cost && (
                            <p
                              class="actual-price m-0"
                              style={{ textDecoration: "line-through" }}
                            >
                              {" "}
                              <i class="fa fa-inr" aria-hidden="true"></i>{" "}
                              {center.actual_service_cost}
                            </p>
                          )}
                        &nbsp;
                        <p class="fs-5 current-price m-0">
                          <i class="fa fa-inr" aria-hidden="true"></i>
                          {center.after_discount_service_cost}
                        </p>
                      </div>
                      <div class="right">
                        <p
                          class="common_color fw-bold pointer details"
                          onClick={() => {
                            showDetails(center);
                          }}
                        >
                          Details{">>"} &nbsp;
                        </p>
                        <button
                          class="add-cart-btn"
                          onClick={() => {
                            addToCartList(center);
                          }}
                        ><i class="fas fa-plus"></i>
                          ADD
                        </button>
                      </div>
                    </div>

                    <div
                      style={{ backgroundColor: "#c3f7d6" }}
                      class="rounded-3"
                    >
                      <ul class="p-0 d-flex justify-content-between p-2">
                        <li>
                          <img
                            src="/assets/imgs/service-center/sanitize.png"
                            alt=""
                            width={18}
                          />{" "}
                          &nbsp; <span>Fully Sanitized </span>
                        </li>
                        <li>
                          <img
                            src="/assets/imgs/service-center/discount.png"
                            alt=""
                            width={18}
                          />{" "}
                          &nbsp; Best Offers available
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <>
            <SuggestedSpaOrGarages
              spaOrGarageList={serviceCentersList}
              showDetails={(center) => {
                showDetails(center);
              }}
            />
          </>
        )
      ) : (
        <>
          {!isServicecentersLoading && (
            <div class="d-flex align-items-center mt-5">
              <img
                src="../assets/imgs/garages/automobile_repair_ywci.png"
                style={{ width: "35%" }}
              />
              <p>Sorry! No Service-center available at your location</p>
            </div>
          )}
        </>
      )}
      {isServicecentersLoading && (
        <>
          <SkeletonLoader isRectangle={true} count={3} />
        </>
      )}
      {show && <ShowChangeServiceCenterConfirmModal handleClose={handleClose} />}
    </>
  );
};

export default ServiceCenter;
