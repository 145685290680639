import { HttpClient } from '../utils/httpClient';
import {
    AVAILABLE_SLOTS, ORDER_INITIALIZE, ORDER_INIT_PAYMENT
} from '../config/constants';
import { CommonServices } from '../utils/commonServices';

class CheckoutService {
    constructor() {
        this.httpClient = new HttpClient();
        this.commonServices = new CommonServices()
    }

    async getServiceCenterAvailableSlots(data) {
        try {
            const { slot_date } = data
            const serviceCenterId = this.commonServices.getLocalStrg("MMX_CURRENT_CART_SERVICECENTER")
            const response = await this.httpClient.get(AVAILABLE_SLOTS + `${serviceCenterId}?slot_date=${slot_date}`);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async orderInitialize(data) {
        try {
            const response = await this.httpClient.post(ORDER_INITIALIZE, data)
            return response
        } catch (error) {
            throw error;
        }
    }

    async orderInitializePayment(data) {
        try {
            const response = await this.httpClient.put(ORDER_INIT_PAYMENT, data)
            return response
        } catch (error) {
            throw error;
        }
    }
}

export { CheckoutService }