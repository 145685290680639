import { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-autocomplete-places";
import Modal from "react-bootstrap/Modal";
import { useGeolocated } from "react-geolocated";
import { useDispatch, useSelector } from "react-redux";
import LocationChangeConfirmationModal from "../../common/Header/LocationChangeConfirmationModal";
import {
  addressSelector,
  getAddressesList,
  updateAddressTypeDesc,
  updateAddressesList,
  setAddress as setAddressAction
} from "../../features/account/addressSlice";
import { authSelector } from "../../features/auth/authSlice";
import { CommonServices } from "../../utils/commonServices";
import "./SelectAddress.css";

const SelectAddress = (props) => {
  const commonServices = new CommonServices();
  const { coords } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });
  const { showModal, handleClose, setAddress: setSelectedAddress } = props;
  const [address, setAddress] = useState("");
  const [isSearchStarted, setIsSearchStarted] = useState(false);
  const dispatch = useDispatch();
  const { addressList } = useSelector(addressSelector);
  const { isAuthenticated } = useSelector(authSelector);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showChangeLocationConfirmationModal, setShowChangeLocationConfirmationModal] = useState(false);
  const [isSetCurrentLocation, setIsSetCurrentLocation] = useState(false)
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getAddressesList());
    } else {
      dispatch(updateAddressesList(null));
    }
  }, []);

  const handleChange = (address) => {
    if (address !== "") {
      setIsSearchStarted(true);
    } else {
      setIsSearchStarted(false);
    }
    setAddress(address);
  };

  const setCurrentLocation = (event) => {
    props.parentCallback();
  };

  const handleSelect = (address) => {
    setIsSearchStarted(false);
    dispatch(updateAddressTypeDesc(address.split(",")[0]));
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        handleClose();
        props.getAddress(latLng.lat, latLng.lng);
      })
      .catch((error) => console.error("Error", error));
  };


  const submit = (address) => {
    handleClose();
    commonServices.setLocalStrg("MMX_CRRADDRESS", JSON.stringify(address));
    let currentaddress = address.Address1;
    if (
      address.Address2 != null &&
      address.Address2 != "" &&
      address.Address2 !== undefined
    )
      currentaddress += " ," + address.Address2;
    if (
      address.Address3 != null &&
      address.Address3 != "" &&
      address.Address3 !== undefined
    )
      currentaddress += " ," + address.Address3;

    currentaddress += " ," + address.Location;

    if (address.AddressType === 1) dispatch(updateAddressTypeDesc("Home"));

    if (address.AddressType === 2) dispatch(updateAddressTypeDesc("Work"));

    if (address.AddressType === 3)
      dispatch(updateAddressTypeDesc("Family & Friends"));

    if (address.AddressType === 4)
      dispatch(updateAddressTypeDesc(address.SaveAs));
      dispatch(setAddressAction(address.Location));

    setSelectedAddress(address.Location);
    commonServices.setLocalStrg("MMX_ADDRESS", currentaddress);
    commonServices.setLocalStrg("MMX_ADDRESSID", address.customerAddressId);
    commonServices.setUserLocation({
      lat: address.Latitude,
      lng: address.Longitude,
    });
    commonServices.setLocalStrg(
      "MMX_USER_LATLON",
      JSON.stringify({ lat: address.Latitude, lng: address.Longitude })
    );
    commonServices.setLocalStrg("MMX_ISADDRESSSAVED", "true");
  };

  const validateLocationChange = (address, lat, lng) => {
    const currentCartId = commonServices.getLocalStrg("MMX_CURRENT_CARTID")
    const currentLatLng = commonServices.getLocalStrgJSON("MMX_USER_LATLON")
    if (currentCartId) {
      if (currentLatLng && currentLatLng?.lat !== lat && currentLatLng?.lng !== lng) {
        setShowChangeLocationConfirmationModal(true);
      } else {
        handleYes(address)
      }
    } else {
      handleYes(address)
    }
  }

  const handleAddressChange = (address) => {
    setSelectedLocation(address);
    if (address.Latitude && address.Longitude) {
      validateLocationChange(address, address.Latitude, address.Longitude)
    } else {
      geocodeByAddress(address)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => {
          validateLocationChange(address, latLng.lat, latLng.lng)
        })
        .catch((error) => console.error("Error", error));
    }
  }

  const handleYes = (selectedLocation) => {
    if (isSetCurrentLocation) {
      setCurrentLocation()
    } else {
      if (selectedLocation.Latitude && selectedLocation.Longitude) {
        submit(selectedLocation)
      } else {
        handleSelect(selectedLocation)
      }
    }

  }

  const handleNo = () => {
    handleClose()
  }

  const validateSetCurrentLocationChange = (event) => {
    setIsSetCurrentLocation(true);
    const currentCartId = commonServices.getLocalStrg("MMX_CURRENT_CARTID")
    const currentLatLng = commonServices.getLocalStrgJSON("MMX_USER_LATLON")

    if (currentCartId) {
      if (currentLatLng && currentLatLng?.lat !== coords?.latitude && currentLatLng?.lng !== coords?.longitude) {
        setShowChangeLocationConfirmationModal(true);
      } else {
        setCurrentLocation();
      }
    } else {
      setCurrentLocation();
    }
  }
  return (
    <>
      <Modal
        className="select-address-modal"
        show={showModal}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="select-address">
            <div class="body">
              <PlacesAutocomplete
                value={address}
                onChange={handleChange}
                onSelect={(address) => {
                  handleAddressChange(address)
                }}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <div class="search-location d-flex align-items-center">
                      <i
                        class="fas fa-search-location"
                        stlye={{ color: "gray" }}
                      ></i>
                      <input
                        {...getInputProps({
                          placeholder: "Search for area, street name..",
                          className: "location-search-input",
                        })}
                      />
                    </div>
                    <div
                      lines="none"
                      class="current-loc bg-transparent ion-no-padding mb-3 mt-4"
                      onClick={(event) => {
                        validateSetCurrentLocationChange(event)
                        // setCurrentLocation(event);
                      }}
                    >
                      <button
                        style={{ float: "left" }}
                        onClick={(event) => {
                          validateSetCurrentLocationChange(event)
                          // setCurrentLocation(event);
                        }}
                        class="m-0"
                      >
                        <i class="fas fa-crosshairs"></i>
                      </button>
                      <div>
                        <p>
                          Get Current Location
                          <br />
                          <span style={{ color: "gray" }}> Using GPS</span>
                        </p>
                      </div>
                    </div>
                    <div className="autocomplete-dropdown-container">
                      <hr />
                      {loading ? (
                        <div>Loading...</div>
                      ) : (
                        isSearchStarted && (
                          <p class="m-0 mt-4 mb-2 search-results">
                            {" "}
                            SEARCH RESULTS
                          </p>
                        )
                      )}

                      {isSearchStarted &&
                        suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? {
                              backgroundColor: "#d8f9e4",
                              cursor: "pointer",
                              padding: "5px 0px 10px 0px",
                              fontSize: "12px",
                              borderRadius: "5px",
                            }
                            : {
                              cursor: "pointer",
                              padding: "5px 0px 10px 0px",
                              fontSize: "12px",
                            };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <div class="d-flex align-items-center">
                                <i class="fas fa-location-arrow"></i>
                                <p class="m-0 main-text">
                                  {suggestion?.formattedSuggestion?.mainText}
                                </p>
                              </div>
                              <p class="m-0 secondary-text w-100 text-truncate">
                                {suggestion?.formattedSuggestion?.secondaryText}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              {!isSearchStarted && !showChangeLocationConfirmationModal && (
                <div class="row saved-address">
                  <strong>Saved Addresses</strong>
                  <div
                    class="list"
                    style={{
                      height: addressList?.length > 0 ? "360px" : "fit-content",
                    }}
                  >
                    <ul>
                      {addressList && addressList.length > 0 ? (
                        addressList.map((address) => {
                          return (
                            <>
                              <li
                                onClick={() => {
                                  handleAddressChange(address);
                                }}
                              >
                                <p>
                                  {address.AddressType === 1
                                    ? "Home"
                                    : (address.AddressType === 2
                                      ? "Work"
                                      : (address.AddressType === 3?"Friends & Family":address.SaveAs))}{" "}
                                  <br />{" "}
                                  <span
                                    style={{ color: "grey", fontSize: "13px" }}
                                  >
                                    {address.Address1}
                                  </span>{" "}
                                  <br />{" "}
                                  <span
                                    style={{ color: "grey", fontSize: "13px" }}
                                  >
                                    {address.Location}
                                  </span>
                                  <hr></hr>
                                </p>
                              </li>
                            </>
                          );
                        })
                      ) : (
                        <>
                          <p>No Saved addresses</p>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              )}
              {showChangeLocationConfirmationModal &&
                <LocationChangeConfirmationModal
                  closeModal={() => { setShowChangeLocationConfirmationModal(false) }}
                  handleYes={() => handleYes(selectedLocation)}
                  handleNo={handleNo} />}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SelectAddress;
