import { useNavigate } from "react-router-dom";

export default function Page404() {
  const navigate = useNavigate();

  return (
    <div class="page-not-found">
      <p class="header-404 text-center">404</p>
      <p class="line-1 m-0 mb-1 text-center">LOOKS LIKE YOU'RE LOST</p>
      <p class="line-2 m-0 mb-1 text-center">
        The Page You Are Looking For Is Not Available!
      </p>
      <div class="d-flex align-items-center justify-content-center">
        <button class="w-100 " onClick={() => navigate("/home")}>
          Go To Home Page
        </button>
      </div>
    </div>
  );
}
