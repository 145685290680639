import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { cartSelector, deleteCart } from "../../features/cart/cartSlice";
import { CommonServices } from "../../utils/commonServices";

const LocationChangeConfirmationModal = (props) => {
  const commonServices = new CommonServices()
  const { show } = props;
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleConfirmation = () => {
    const currentCartId = commonServices.getLocalStrg("MMX_CURRENT_CARTID")
    dispatch(deleteCart(currentCartId))
    localStorage.removeItem("MMX_CURRENT_CARTID");
    localStorage.removeItem("MMX_CURRENT_CART_SERVICECENTER");
    localStorage.removeItem("MMX_SERVICE_CENTER");
    navigate("/")
    props.closeModal(false);
    props.handleYes()
  }
  return (<>
    <div class="change-location-modal">
      
      <div class="">

        <div class="my-3">
          <h5 class="text-justify" style={{ color: "#428372" }}>
            Changing location will empty your cart.

          </h5>
          <h6 class="mt-3" >Are you sure you want to change your location?</h6>
        </div>
        <div class="d-flex justify-content-end ms-auto">
          <button
            className="btn mx-3 px-3 py-1 rounded-3"
            style={{ backgroundColor: "#428372", color: "#ffff" }}
            onClick={() => {
              handleConfirmation()
            }}
          >
            <i class="fas fa-check"></i> Yes
          </button>
          <button
            className="btn mx-3 px-3 py-1 rounded-3"
            style={{ backgroundColor: "#ce254a", color: "#ffff" }}
            onClick={() => {
              props.handleNo();
            }}
          >
            <i class="fas fa-times"></i> No
          </button>
        </div>
      </div>
    </div>
  </>)
}

export default LocationChangeConfirmationModal;