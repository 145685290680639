import { decode as atob } from "base-64";
import React, { useEffect, useState } from "react";
import useRazorpay from "react-razorpay";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../common/Loader/Loader";
import { S3_BUCKET_URL } from "../config";
import {
  getOrderDetailsByOrderNumber,
  initializeOrderPayment,
  orderPaymentUpdate,
  outsidePaymentSelector,
} from "../features//outsidePayments/outsidePaymentSlice";
import {
  updatePaymentFailureResponse,
  updatePaymentSuccessResponse,
} from "../features/Checkout/checkoutslice";
import { CommonServices } from "../utils/commonServices";

const PaymentPage = () => {
  const Razorpay = useRazorpay();
  let dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  let {
    orderDetailByOrderNumberResp,
    initializeOrderPaymentResp,
    isOrderDetailByOrderNumberSuccess,
    isInitializeOrderPaymentSuccess,
    paymentUpdateResp,
    isPaymentUpdatedSuccess,
  } = useSelector(outsidePaymentSelector);
  let commonServices = new CommonServices();
  const [orderDetails, setOrderDetails] = useState(null);
  const [extraServices, setExtraServices] = useState("");
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isValidOrder, setIsValidOrder] = useState(true);

  useEffect(() => {
    setShowLoader(true);
  }, []);
  var url = location?.search;
  if (url) var orderNumber = url.split("?")[1];

  useEffect(() => {
    if (orderNumber) {
      dispatch(getOrderDetailsByOrderNumber(orderNumber));
      dispatch(initializeOrderPayment(orderNumber));
    }
  }, [orderNumber]);

  useEffect(() => {
    if (isOrderDetailByOrderNumberSuccess && orderDetailByOrderNumberResp) {
      setShowLoader(false);
      if (
        orderDetailByOrderNumberResp.order_status <= 5 &&
        orderDetailByOrderNumberResp.full_payment_status != 2
      ) {
        setIsValidOrder(true);
        let servicesList = "";
        if (orderDetailByOrderNumberResp.extraServiceList.length > 0) {
          orderDetailByOrderNumberResp.extraServiceList.map((ele, index) => {
            servicesList = servicesList + ele.jobcardServiceName;
            if (
              index + 1 <
              orderDetailByOrderNumberResp.extraServiceList.length
            ) {
              servicesList = servicesList + ", ";
            }
          });
          setExtraServices(servicesList);
        }
        setOrderDetails(orderDetailByOrderNumberResp);
      } else {
        setIsValidOrder(false);
      }
    }
  }, [orderDetailByOrderNumberResp, isOrderDetailByOrderNumberSuccess]);

  useEffect(() => {
    if (isInitializeOrderPaymentSuccess && initializeOrderPaymentResp) {
      setPaymentDetails(initializeOrderPaymentResp);
    }
  }, [isInitializeOrderPaymentSuccess, initializeOrderPaymentResp]);

  const proceedPayment = () => {
    const options = {
      description: "Service Booking",
      image: S3_BUCKET_URL + "assets/metamechx-logo.svg",
      order_id:
        paymentDetails?.rezorpay_orderDetails.id ||
        commonServices.generateUUIDV4(),
      handler: function (response) {
        const paymentSuccessResponse = response;
        dispatch(updatePaymentSuccessResponse(response));
        const body = {
          orderId: paymentDetails?.orderId,
          payment_mode: 1,
          is_extra_estimation_pay: true,
          paymentDetails: {
            referenceId: paymentDetails?.orderReference,
            orderNo: paymentDetails?.orderNo,
            notes: paymentDetails?.orderNo,
            amount: paymentDetails?.total_pay_amount,
            ...paymentSuccessResponse,
          },
          paid_amount: paymentDetails?.total_pay_amount,
          payment_status: 1,
        };
        if (
          response.razorpay_payment_id &&
          commonServices.isValidData(response.razorpay_payment_id)
        ) {
          setShowLoader(true);
          dispatch(orderPaymentUpdate(body));
        } else {
          toast.error("Payment faild", "danger", {
            toastId: "paymentfailed",
            autoClose: 2000,
            hideProgressBar: true,
          });
          cancelCallback(response);
        }
      },
      currency: "INR",
      key: atob(paymentDetails?.rezorpay_orderDetails?.razorpay_key_id),
      amount: Number(paymentDetails?.total_pay_amount),
      name: " MetaMechX",
      notes: { orderNo: paymentDetails?.orderNo },
      orderNo: paymentDetails?.orderNo,
      prefill: {
        email: orderDetails?.user_email,
        contact: orderDetails?.user_mobile_no,
        name: orderDetails?.user_name,
      },
      theme: {
        color: "#28B67E",
      },
      modal: {
        ondismiss: function () {
          // dispatch(resetCheckoutSlice());
          setShowLoader(false);
        },
      },
    };
    const RazorpayCheckout = new Razorpay(options);
    try {
      RazorpayCheckout.open(options);
    } catch (e) {
      commonServices.setLocalStrg("MMX_PAYMENT_ERROR", JSON.stringify(e));
    }
  };

  const cancelCallback = (error) => {
    setShowLoader(false);
    let paymentFaildResponse = error;
    paymentFaildResponse.description = paymentFaildResponse.description
      ? JSON.parse(paymentFaildResponse.description)
      : "";
    dispatch(updatePaymentFailureResponse(paymentFaildResponse));
    const body = {
      orderId: paymentDetails?.orderId,
      payment_mode: 1,
      paymentDetails: {
        referenceId: paymentDetails?.orderReference,
        orderNo: paymentDetails?.orderNo,
        notes: paymentDetails?.orderNo,
        amount: paymentDetails?.total_pay_amount,
        ...paymentFaildResponse,
      },
      payment_status: 2,
    };
    dispatch(orderPaymentUpdate(body));
    if (
      error?.code !== 0 &&
      error?.description?.error?.code !== "BAD_REQUEST_ERROR" &&
      error?.description?.error?.reason !== "payment_cancelled"
    ) {
      toast.error("Payment faild! Please try again.", {
        autoClose: 1000,
        hideProgressBar: true,
        toastId: "paymentfailed",
      });

      navigate("/services/mycart");
    } else {
      toast.error("Payment faild! Please try again.", {
        autoClose: 1000,
        hideProgressBar: true,
        toastId: "paymentfailed",
      });
    }
  };

  useEffect(() => {
    if (isPaymentUpdatedSuccess) {
      setShowLoader(false);
      setIsPaymentSuccess(true);
    }
  }, [paymentUpdateResp, isPaymentUpdatedSuccess]);

  return (
    <main>
      <section className="payment-form dark">
        <div className="container">
          <div className="block-heading">
            <h2 class="header fw-light">PAYMENT</h2>
          </div>
          {!isPaymentSuccess && isValidOrder ? (
            <form>
              <div className="products">
                <div className="row title m-lr-0 p-0">
                  <div className="col-5 p-0">
                    <h3 class="fw-light">Checkout</h3>
                  </div>
                  <div className="col-7 p-0">
                    <h5 className="orderid fw-light">
                      Order No : {orderDetails?.orderNo || "-"}
                    </h5>
                  </div>
                </div>
                <div className="item">
                  <span className="price fs-6">
                    {commonServices.numberFormat(
                      orderDetails?.total_pay_amount || 0
                    )}
                  </span>
                  <p className="item-name">Ordered Service</p>
                  <p className="item-description">{orderDetails?.serviceCat}</p>
                </div>
                {orderDetails?.total_extra_service_cost > 0 && (
                  <div className="item">
                    <span className="price fs-6">
                      {commonServices.numberFormat(
                        orderDetails?.total_extra_service_cost || 0
                      )}
                    </span>
                    <p className="item-name">Extra Service</p>
                    <p className="item-description">{extraServices}</p>
                  </div>
                )}
                <div className="total">
                  Total
                  <span className="price fs-6">
                    {commonServices.numberFormat(
                      orderDetails?.total_cost_including_extra_service || 0
                    )}
                  </span>
                </div>
                <div className="total">
                  Total Paid
                  <span className="price fs-6">
                    {commonServices.numberFormat(
                      orderDetails?.total_paid_amount || 0
                    )}
                  </span>
                </div>
                <div className="total">
                  Pay Amount
                  <span className="price fs-6">
                    {commonServices.numberFormat(
                      orderDetails?.total_remain_pay_amount || 0
                    )}
                  </span>
                </div>
              </div>
              <div className="card-details">
                <div className="row">
                  <div className="form-group col-sm-12 col-lg-12">
                    <button
                      type="button"
                      className="btn btn-primary btn-block"
                      id="rzp-button1"
                      style={{ display: "block", width: "100%" }}
                      onClick={proceedPayment}
                    >
                      Proceed to pay
                    </button>
                  </div>
                </div>
              </div>
              {showLoader && <Loader />}
              <div>
                <div>
                  <div></div>
                </div>
              </div>
            </form>
          ) : (
            <>
              <div class="payment-succcess">
                <div>
                  <h2 class="text-center fw-light payment-success-header">
                    {isPaymentSuccess ? "Payment Success !" : "Fully Paid!"}
                  </h2>
                  <p class="text-center payment-success-desc">
                    {isPaymentSuccess
                      ? "Payment Received, Please check the metamechx App form status update"
                      : "There is no due against current orderID. Please check the metaMechx App form status update"}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </main>
  );
};

export default PaymentPage;
