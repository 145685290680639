import React from 'react';
import '../HowMetamechxWorks/how_works.css';
import { HowMetWorksData } from '../../SampleData';

function MetamechxWorks(props) {
  return (
    <div className="how-works">
      <h4>HOW METAMECHX WORKS</h4>
      <div className="row">
        <div className="col-lg-2 side_dots">
          <ul>
            <li class="firstDot">1</li>
            <li className="s-d"> </li>
            <li className="s-d"> </li>
            <li>2</li>
            <li className="s-d"> </li>
            <li className="s-d"> </li>
            <li>3</li>
            <li className="s-d"> </li>
            <li className="s-d"> </li>
            <li>4</li>
          </ul>
        </div>
        <div className="col-lg-10 col-md-12 col-sm-12 works_des">
          {HowMetWorksData && HowMetWorksData.map((item) => {
            return (
              <div className="row ">
                <div className="col-lg-3 col-md-2 col-sm-2">
                  <img src={item.ImageURL} className="car-icon img-fluid" />
                </div>
                <div className="col-lg-9 col-md-10 col-sm-10 work-desc">
                  <h5>{item.Header}</h5>
                  <p>{item.Para}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}

export default MetamechxWorks;