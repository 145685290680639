import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TermsAndConditionsService } from '../../services/termsAndConditions.service';
import { STATUS_CODES } from '../../config/constants';

const intialState = {
    termsAndConditionsData: null,
    isLoading: false
};

export const getTermsAndConditions = createAsyncThunk('termsAndConditions/getTermsAndConditions', async (data, thunkAPI) => {
    const termsAndConditionsService = new TermsAndConditionsService();
    try {
        const response = await termsAndConditionsService.getTermsAndConditions();
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});


const termsAndConditionsSlice = createSlice({
    name: 'termsAndConditions',
    initialState: intialState,
    reducers: {
        reset: (state) => {
            state.termsAndConditionsData = null
        },
    },
    extraReducers: (builder) => {
        // add your async reducers here
        builder
            .addCase(getTermsAndConditions.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(getTermsAndConditions.fulfilled, (state, action) => {
                state.isLoading = false
                if (action.payload.data.statuscode === STATUS_CODES[200])
                    state.termsAndConditionsData = action.payload?.data?.data;
            });
    }
});


export const { reset } = termsAndConditionsSlice.actions;

export const termsAndConditionsSelector = (state) => state.termsAndConditions;

export default termsAndConditionsSlice.reducer;