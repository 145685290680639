import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CustomerProfileService } from "../../services/customerProfile.service";

const initialState = {
    customerDetails: null,
    activeModule: null,
    updateProfileResp: null,
    updateProfilePicResp: null,
    showLogoutModal: false

}

export const getCustomerDetails = createAsyncThunk('customerProfile/getCustomerDetails', async (data, thunkAPI) => {
    const customerProfileService = new CustomerProfileService()
    try {
        const response = await customerProfileService.getCustomerDetails();
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const updateProfile = createAsyncThunk('customerProfile/updateProfile', async (data, thunkAPI) => {
    const customerProfileService = new CustomerProfileService()
    try {
        const response = await customerProfileService.updateProfile(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const updateProfilePic = createAsyncThunk('customerProfile/updateProfilePic', async (data, thunkAPI) => {
    const customerProfileService = new CustomerProfileService()
    try {
        const response = await customerProfileService.updateProfilePic(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});



const customerProfileSlice = createSlice({
    name: "customerProfile",
    initialState,
    reducers: {
        reset: (state) => {
            state.updateProfileResp = null;
            state.updateProfilePicResp = null
        },
        setActiveModule: (state, action) => {
            state.activeModule = action.payload
        },
        displayLogoutModal: (state, action) => {
            state.showLogoutModal = action.payload
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(getCustomerDetails.fulfilled, (state, action) => {
                state.customerDetails = action.payload.data;
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                state.updateProfileResp = action.payload
            })
            .addCase(updateProfilePic.fulfilled, (state, action) => {
                state.updateProfilePicResp = action.payload
            })
    }
})

export const { reset, setActiveModule, displayLogoutModal } = customerProfileSlice.actions;
export const customerProfileSelector = (state) => state.customerProfile;
export default customerProfileSlice.reducer;