import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CheckoutService } from '../../services/checkout.service';
const intialState = {
    isPayOnCompletionTrue: false,
    getServiceCenterAvailableSlotsResp: null,
    serviceCenterAvailableSlots: null,
    isAvailableSlotsLoading: false,
    selectedSchedule: null,
    selectedAddress: null,
    orderInitializationResponse: null,
    enableBookingButton: false,
    initPaymentResp: null,
    paymentSuccessResponse: null,
    isPaymentFailed: false,
    paymentFailureResponse: null
};

export const getServiceCenterAvailableSlots = createAsyncThunk('couponsAndWallet/getServiceCenterAvailableSlots', async (data, thunkAPI) => {
    const checkoutService = new CheckoutService()
    try {
        const response = await checkoutService.getServiceCenterAvailableSlots(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const orderInitialize = createAsyncThunk('couponsAndWallet/orderInitialize', async (data, thunkAPI) => {
    const checkoutService = new CheckoutService()
    try {
        const response = await checkoutService.orderInitialize(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const orderInitializePayment = createAsyncThunk('couponsAndWallet/orderInitializePayment', async (data, thunkAPI) => {
    const checkoutService = new CheckoutService()
    try {
        const response = await checkoutService.orderInitializePayment(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

const checkoutSlice = createSlice({
    name: 'checkout',
    initialState: intialState,
    reducers: {
        reset: (state) => {
            state.isPayOnCompletionTrue = false;
            state.orderInitializationResponse = null;
            state.initPaymentResp = null
        },
        setPayOnCompletionTrue: (state, action) => {
            state.isPayOnCompletionTrue = action.payload
        },
        updateSelectedSchedule: (state, action) => {
            state.selectedSchedule = action.payload
        },
        updateSelectedAddress: (state, action) => {
            state.selectedAddress = action.payload
        },
        updateEnableBookingButton: (state, action) => {
            state.enableBookingButton = action.payload
        },
        updateAvailableSlots: (state, action) => {
            state.serviceCenterAvailableSlots = action.payload
        },
        updatePaymentSuccessResponse: (state, action) => {
            state.isPaymentFailed = false
            state.paymentSuccessResponse = action.payload
        },
        updatePaymentFailureResponse: (state, action) => {
            state.isPaymentFailed = true
            state.paymentFailureResponse = action.payload
        }
    },
    extraReducers: (builder) => {
        // add your async reducers here
        builder
            .addCase(getServiceCenterAvailableSlots.pending, (state, action) => {
                state.isAvailableSlotsLoading = true
            })
            .addCase(getServiceCenterAvailableSlots.fulfilled, (state, action) => {
                state.isAvailableSlotsLoading = false
                state.getServiceCenterAvailableSlotsResp = action.payload.data.data
                const morningSlots = action.payload?.data?.data?.slotDetails?.morningSlot
                const eveningSlots = action.payload?.data?.data?.slotDetails?.eveningSlot
                state.serviceCenterAvailableSlots = [...morningSlots, ...eveningSlots]
            })
            .addCase(orderInitialize.fulfilled, (state, action) => {
                state.orderInitializationResponse = action.payload.data
                if (action.payload.data.statuscode === 200)
                    state.orderDetails = action.payload.data.data
            })
            .addCase(orderInitializePayment.fulfilled, (state, action) => {
                if (action.payload.data.statuscode === 200) {
                    state.initPaymentResp = action.payload.data;
                    localStorage.removeItem("MMX_CART_COUNT")
                    localStorage.removeItem("MMX_CURRENT_CARTID")
                    localStorage.removeItem("MMX_CURRENT_CART_SERVICECENTER")
                }
            })
    }
});

export const { reset, setPayOnCompletionTrue, updatePaymentSuccessResponse, updatePaymentFailureResponse, updateSelectedSchedule, updateSelectedAddress, updateEnableBookingButton, updateAvailableSlots } = checkoutSlice.actions;

export const checkoutSelector = (state) => state.checkout;

export default checkoutSlice.reducer;