import { useDispatch, useSelector } from "react-redux";
import { cartSelector, deleteCart } from "../../features/cart/cartSlice";
import { CommonServices } from "../../utils/commonServices";
import Modal from "react-bootstrap/Modal";

 const ShowChangeServiceCenterConfirmModal = (props) => {

    const dispatch = useDispatch()
    const commonServices = new CommonServices()

    const {  cartDetails } =
    useSelector(cartSelector);
    const currentServiceCenter =
      commonServices.getLocalStrgJSON("MMX_SERVICE_CENTER");

       const deleteCurrentCart = (cartId, serviceCenterId) => {
    dispatch(deleteCart(cartId));
  };
  
    return (
      <>
        <Modal
          className="change-service-center-confirm-modal"
          show={true}
          onHide={() => {
            props.handleClose();
          }}
          centered
          // backdrop="static"
          // keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#428372" }}>
              Replace cart item?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              height: "14rem",
              borderRadius: "7px",
              paddingTop: "5%",
            }}
          >
            <p>
              Your cart contains services from <br></br>
              <strong style={{ color: "#428372", fontSize: "18px" }}>
                {`${cartDetails?.data?.service_center_dispaly_name}`}.
              </strong>{" "}
              <br /> <br />
              Do you want to replace them with services from <br />
              <strong style={{ color: "#428372", fontSize: "18px" }}>
                {`${currentServiceCenter?.service_center_dispaly_name}`}
              </strong>
              ?
            </p>
            <div class="w-50 d-flex justify-content-evenly ms-auto">
              <button
                className="btn"
                style={{ backgroundColor: "#428372", color: "#ffff" }}
                onClick={() => {
                  deleteCurrentCart(
                    cartDetails?.data?.cartId,
                    currentServiceCenter?.serviceCenterId
                  );
                  props.handleClose();
                }}
              >
                <i class="fas fa-check"></i> Yes
              </button>
              <button
                className="btn"
                style={{ backgroundColor: "#ce254a", color: "#ffff" }}
                onClick={() => {
                  props.handleClose();
                }}
              >
                <i class="fas fa-times"></i> No
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };

  export default ShowChangeServiceCenterConfirmModal;