
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {Helmet} from "react-helmet";
import store from './app/store';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import 'bootstrap/dist/css/bootstrap.css';
// import "bootstrap/dist/js/bootstrap.bundle.min";

// import './css/responsive.css';
// import 'boxicons/css/boxicons.min.css';
import './index.css';
// import './css/body.css';

import ReactGA from 'react-ga';
ReactGA.initialize('G-LM54ERT1K4');
ReactGA.pageview(window.location.pathname + window.location.search);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <Helmet>
        <meta charset="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <meta name="facebook-domain-verification" content="0uke7e08rpj7z0b5evopllvm1d6vj6" />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <title>Best car premium services| Repairs jobs | Car Cleaning detailing services</title>
        <meta name="description" content="MetaMechX Strap in to uncover the new efficient way of caring for your beloved car. Book General Car Service, Car Wheel Repair, Car Denting, Car Painting, Car Wash Services Online " />
        <meta name="keywords" content="MetaMechX, Book General Car Service, Car Wheel Repair,   Car Denting, Car Painting, Car Wash Services Online , Best car services , car repair estimate, brake repair shops near me, mechanic, mechanic near me , car repair near me , car repair shops near me, car wash near me , Car services in india , Car service in Bangalore"  /> 
      </Helmet>
      <App />
    </BrowserRouter>
  </Provider>
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
