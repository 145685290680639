import { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { useDispatch, useSelector } from "react-redux";
import SkeletonLoader from "../../common/SkeletonLoaders/SkeletonLoaders";
import {
  getmodels,
  homeselector,
  updatefueltype,
  updateFueltypesList,
  updatemodel,
  updateSelectedVehicle,
  updateModelsList,
} from "../../features/home/homeSlice";

const SelectVehicleModel = (props) => {
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState("");
  const { vehicleType, selectedbrand, models, selectedmodel } =
    useSelector(homeselector);

  useEffect(() => {
    if (selectedbrand != null) {
      const brandId = JSON.parse(selectedbrand).brandId;
      const data = { vehicleType: vehicleType, brandId: brandId };
      dispatch(getmodels(data));
    }
  }, [selectedbrand]);

  const search = (event) => {
    const searchKey = event.target.value || "";
    const brandId = JSON.parse(selectedbrand).brandId;
    dispatch(updateModelsList(null));
    setSearchKey(event.target.value);
    const data = {
      vehicleType: vehicleType,
      search: searchKey,
      brandId: brandId,
    };
    dispatch(getmodels(data));
  };

  const handleModelChange = (modal) => {
    props.changeVehicleModel();
    if (
      JSON.parse(modal).vehicleModelId !=
      JSON.parse(selectedmodel)?.vehicleModelId
    ) {
      dispatch(updateFueltypesList(null));
    }
    dispatch(updatefueltype(null));
    dispatch(updatemodel(modal));
    dispatch(updateSelectedVehicle(null));
    localStorage.removeItem("MMX_VEHICLE");
  };

  return (
    <>
      <form action="" className="side-box select-model">
        <div class="header">
          <i
            class="fas fa-arrow-left"
            onClick={() => {
              props.gotoManufacturerScreen();
            }}
          ></i>
          <label htmlFor="model">
            <h6>Select Model ({JSON.parse(selectedbrand)?.brandName})</h6>
          </label>
        </div>
        <div class="search-field">
          <DebounceInput
            minLength={2}
            debounceTimeout={1000}
            type="text"
            placeholder="Search Model"
            value={searchKey}
            name="model"
            className="inside form-control"
            id=" model"
            autocomplete="off"
            onChange={(e) => {
              search(e);
            }}
          />
          <i class="fas fa-search"></i>
        </div>
        <div class=" brand-logos">
          {models ? (
            models.map((model) => {
              return (
                <>
                  <div
                    class="each-modal"
                    onClick={() => {
                      handleModelChange(JSON.stringify(model));
                    }}
                  >
                    <img
                      src={model.brandLogoUrl}
                      className="img-fluid w-50 h-50"
                      title={model.modelName}
                    />
                    <div class=" card-body p-2 d-flex align-items-center justify-content-around">
                      <p class="m-0 text-truncate">{model.modelName}</p>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <SkeletonLoader
              isSmallLoader={true}
              count={3}
              loopCount={9}
              width="20%"
            />
          )}
        </div>
      </form>
    </>
  );
};

export default SelectVehicleModel;
