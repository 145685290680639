import React, { useEffect, useState } from "react";
import {
  emergencyserviceselector,
  getServiceCategories,
  updatecategoryselected,
} from "../../features/emergency/emergencySlice";
import { useDispatch, useSelector } from "react-redux";

import { CommonServices } from "../../utils/commonServices";
import OwlCarousel from "react-owl-carousel";
import { updateServicePackagesList } from "../../features/servicepackage/servicepackageSlice";
import { getVehicleType, homeselector } from "../../features/home/homeSlice";

function SelectionNav(props) {
  const commonServices = new CommonServices();

  const { categories, selectedCategory, selectedCategoryIndex } = useSelector(
    emergencyserviceselector
  );
  const { vehicleType } = useSelector(homeselector);

  const [categoriesList, setCategoriesList] = useState(categories);
  const [currentSelectedCategory, setCurrentSelectedCategory] =
    useState(selectedCategory);
  const [key, setKey] = useState(
    selectedCategoryIndex ? selectedCategoryIndex : 0
  );
  const dispatch = useDispatch();
  const options = {
    items: 6,
    nav: true,
    navText: [
      "<div className='nav-btn prev-slide'></div>",
      "<div className='nav-btn next-slide'></div>",
    ],
    loop: false,
    margin: 15,
    dots: false,
    autoplay: true,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 4,
      },
      980: {
        items: 5,
      },
      1024: {
        items: 4,
      },
      1199: {
        items: 6,
      },
    },
    merge: true,
  };
  useEffect(() => {
    if (vehicleType == null) {
      dispatch(getVehicleType());
    }
    else {
      const data = { vehicleType: commonServices.getLocalStrg("VEHICLE_TYPE") };
      if (categories == null) {
        dispatch(getServiceCategories(data));
      }

    }
  }, [vehicleType, categories]);
  useEffect(() => {
    if (categories && Object.keys(categories).length > 0) {
      setCategoriesList(categories);
      setCurrentSelectedCategory((prev) => {
        return { ...prev, ...selectedCategory };
      });
    }
  }, [categories, selectedCategory]);

  const categoryselected = (data, key) => {
    const prevCat = commonServices.getLocalStrgJSON("MMX_SERVICE");
    if (prevCat?.serviceCatId != data.serviceCatId) {
      dispatch(updateServicePackagesList(null));
    }
    dispatch(updatecategoryselected(data));

    setCurrentSelectedCategory(data);
    commonServices.setLocalStrg("MMX_SERVICE", JSON.stringify(data));
    setKey(key);
  };

  return (
    <React.Fragment>
      <div className="custom_selections">
        <div className="row">
          <div className="col-lg-12">
            <OwlCarousel
              className="owl-carousel"
              {...options}
              startPosition={key}
              center={false}
            >
              {categoriesList &&
                categoriesList.servicecatList &&
                categoriesList.servicecatList.map((service, i) => {
                  return (
                    <div
                      onClick={() => {
                        categoryselected(service, i);
                      }}
                      key={service.serviceCatId}
                      className="services_carousel pointer"
                    >
                      <div
                        className={`card pt-1 text-center services_carousel ${currentSelectedCategory?.serviceCatId ===
                          service.serviceCatId && "active-cat"
                          }`}
                      >
                        { }
                        <div class="card-image">
                          <img
                            alt={service.name}
                            src={service.serviceCatDesktopImage}
                            className="w-100"
                            style={{ height: "55px" }}
                          />
                        </div>
                        <div className="card-body d-flex align-items-center justify-content-around">
                          <p className="card-text">{service.name}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SelectionNav;
