import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { OrderService } from '../../services/order.service';
import { STATUS_CODES } from '../../config/constants';

const intialState = {
    isLoading: false,
    isError: false,
    getOrdersListResp: null,
    ordersList: null,
    isOrderDetailsLoading: false,
    orderDetailsResp: null,
    orderCancelResp:null
};

export const getOrdersList = createAsyncThunk('order/getOrdersList', async (data, thunkAPI) => {
    const orderService = new OrderService()
    try {
        const response = await orderService.getOrdersList(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const getOrderDetails = createAsyncThunk('order/getOrderDetails', async (data, thunkAPI) => {
    const orderService = new OrderService()
    try {
        const response = await orderService.getOrderDetails(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const cancelOrder = createAsyncThunk('order/cancelOrder', async (data, thunkAPI) => {
    const orderService = new OrderService()
    try {
        const response = await orderService.cancelOrder(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});



const orderSlice = createSlice({
    name: 'order',
    initialState: intialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isError = false;
            state.orderCancelResp=null
        },
        updateOrderDetails: (state, action) => {
            state.orderDetailsResp = action.payload;
        }
    },
    extraReducers: (builder) => {
        // add your async reducers here
        builder
            .addCase(getOrdersList.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(getOrdersList.fulfilled, (state, action) => {
                state.isLoading = false;
                state.getOrdersListResp = action.payload;
                state.ordersList = action.payload.data
            })
            .addCase(getOrderDetails.fulfilled, (state, action) => {
                state.orderDetailsResp = action.payload.data;
                // state.ordersList = action.payload.data
            })
            .addCase(cancelOrder.fulfilled,(state,action)=>{
                if(action.payload.statuscode === STATUS_CODES[200]){
                    state.orderCancelResp = action.payload
                }
            })
    }
});

export const { reset, updateOrderDetails } = orderSlice.actions;

export const orderSelector = (state) => state.order;

export default orderSlice.reducer;