import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../common/Loader/Loader";
import { STATUS_CODES } from "../../config/constants";
import {
  addVehicle,
  getUserVehiclesList,
  removeVehicle,
  reset,
  vehicleSelector,
} from "../../features/account/vehicleSlice";
import { authSelector } from "../../features/auth/authSlice";
import {
  homeselector,
  updateSelectedVehicle,
} from "../../features/home/homeSlice";
import { CommonServices } from "../../utils/commonServices";
import SelectFuelType from "../SearchServices/SelectFuelType";
import SelectManufacturer from "../SearchServices/SelectManufacturer";
import SelectVehicleModel from "../SearchServices/SelectVehicleModel";
import "./MyVehicles.css";

const MyVehicles = () => {
  const commonServices = new CommonServices();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    selectedbrand,
    selectedfueltype,
    selectedYear,
    selectedmodel,
    selectedvehicle,
  } = useSelector(homeselector);

  const { vehiclesList,getvehiclesListResp, deleteVehicleResp, addVehicleResponse } =
    useSelector(vehicleSelector);
  const [myVehiclesList, setMyVehiclesList] = useState([]);
  const vehicleType = commonServices.getLocalStrg("VEHICLE_TYPE");
  const [showLoader, setShowLoader] = useState(false);
  const [showSelectVehicleModal, setShowSelectVehicleModal] = useState(false);
  const [isManufacturerInputSelected, setIsManufacturerInputSelected] =
    useState(false);
  const [isModelInputSelected, setIsModelInputSelected] = useState(false);
  const [isFuelTypeSelected, SetIsFuelTypeSelected] = useState(false);
  const { isAuthenticated } = useSelector(authSelector);

  useEffect(() => {
    dispatch(getUserVehiclesList());
  }, []);

  useEffect(() => {
    if(getvehiclesListResp?.statuscode === STATUS_CODES[200]){
      if (vehiclesList?.length > 0) {
        setMyVehiclesList(vehiclesList);
      }
      setShowLoader(false);
    }else if(getvehiclesListResp?.statuscode === STATUS_CODES[204]){
      setMyVehiclesList([]);
      setShowLoader(false);
    }else{
      setShowLoader(false);
    }
  }, [vehiclesList,getvehiclesListResp]);

  const DisplayMySavedVehicles = () => {
    return (
      <div class="my-vehicles-list">
        {myVehiclesList && (
          <>
            {myVehiclesList.map((vehicle) => {
              return (
                <div class="row px-3 py-2 each-vehicle  mt-3 m-0">
                  <div class="text-start p-0 col-lg-12 d-flex justify-content-between align-items-center">
                    <div class="col-lg-6 col-md-5 col-sm-6">
                      <p class="m-0 vehicle-name fw-600">
                        {vehicle?.brandName} {vehicle?.vehicleModelName} -{" "}
                        {vehicle.vehicleFueltype}
                      </p>
                    </div>

                    <div class="col-lg-2 col-md-3 col-sm-2">
                      <p class="m-0 vehicle-number fw-600">
                        {vehicle?.registration_no}
                      </p>
                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-4">
                      <span
                        class="fw-600 service-now pointer"
                        onClick={() => {
                          serviceVehicle(vehicle);
                        }}
                      >
                        Service Now
                      </span>
                    </div>
                    <div class="col-lg-1 col-md-1 text-center">
                      <i
                        class="fas fa-trash-alt remove"
                        onClick={() => {
                          handleRemoveVehicle(vehicle?.customerVehicleId);
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    );
  };

  const serviceVehicle = (vehicle) => {
    commonServices.setLocalStrg(
      "MMX_SELECTED_VEHICLE",
      JSON.stringify(vehicle)
    );
    commonServices.setLocalStrg("VEHICLE_TYPE", vehicle.vehicleTypeId);
    const vehicleDetails = {
      selectedbrand: JSON.stringify({
        brandId: vehicle.brandId,
        brandName: vehicle.brandName,
        brandLogo: vehicle.brandLogo,
      }),
      selectedmodel: JSON.stringify({
        vehicleModelId: vehicle.vehicleModelId,
        vehicleModelName: vehicle.vehicleModelName,
        vehicleBodyTypeId: vehicle.vehicleBodyTypeId,
      }),
      selectedfueltype: JSON.stringify({
        fueltypeId: vehicle.vehicleFueltypeId,
        vehicleFueltype: vehicle.vehicleFueltype,
      }),
      selectedvehicle:
        vehicle.brandName +
        " " +
        vehicle.vehicleModelName +
        ", " +
        vehicle.vehicleFueltype,
    };
    const selectedvehicle =
      vehicle.brandName +
      " " +
      vehicle.vehicleModelName +
      ", " +
      vehicle.vehicleFueltype;
    commonServices.setLocalStrg("MMX_VEHICLE", JSON.stringify(vehicleDetails));
    dispatch(updateSelectedVehicle(selectedvehicle));
    dispatch(reset());
    navigate("/home");
  };

  const addSelectedVehicle = (selectedfueltype) => {
    if (isAuthenticated && selectedmodel && selectedfueltype) {
      const body = {
        vehicleBrandId: JSON.parse(selectedbrand).brandId,
        vehicleModelId: JSON.parse(selectedmodel).vehicleModelId,
        vehicleFueltypeId: JSON.parse(selectedfueltype).fueltypeId,
        vehicleTypeId: vehicleType,
        manufactured_year: selectedYear && selectedYear,
      };
      dispatch(addVehicle(body));
    }
  };

  useEffect(() => {
    if (
      addVehicleResponse &&
      addVehicleResponse.statuscode === STATUS_CODES[200]
    ) {
      toast.success(addVehicleResponse?.message, {
        toastId: "addVehicle",
        autoClose: 2000,
        hideProgressBar: true,
      });
      dispatch(getUserVehiclesList());
    } else {
      toast.error(addVehicleResponse?.message, {
        toastId: "addVehicle",
        autoClose: 2000,
        hideProgressBar: true,
      });
    }
    dispatch(reset());
  }, [addVehicleResponse]);

  const handleRemoveVehicle = (vehicleId) => {
    setShowLoader(true);
    dispatch(removeVehicle(vehicleId));
  };

  useEffect(() => {
    if (
      deleteVehicleResp &&
      deleteVehicleResp?.statuscode === STATUS_CODES[200]
    ) {
      toast.success(deleteVehicleResp?.message, {
        toastId: "vehicleRemoved",
        autoClose: 1000,
        hideProgressBar: true,
      });
      dispatch(getUserVehiclesList());

      dispatch(reset());
    } else {
      toast.error(deleteVehicleResp?.message, {
        toastId: "vehicleRemoved",
        autoClose: 1000,
        hideProgressBar: true,
      });
      dispatch(reset());
    }
  }, [deleteVehicleResp]);

  const SelectVehicleModal = () => {
    const handleBrandChange = () => {
      setIsManufacturerInputSelected(false);
      setIsModelInputSelected(true);
      SetIsFuelTypeSelected(false);
    };

    const closeManufacturerScreen = () => {
      setShowSelectVehicleModal(false);
      setIsManufacturerInputSelected(false);
      setIsModelInputSelected(false);
      SetIsFuelTypeSelected(false);
      // setShowModal(false);
    };

    const handleVehicleModelChange = () => {
      setIsModelInputSelected(false);
      SetIsFuelTypeSelected(true);
    };

    const gotoManufacturerScreen = () => {
      setIsModelInputSelected(false);
      setIsManufacturerInputSelected(true);
    };

    const handleFueltypeChange = (selectedfueltype) => {
      SetIsFuelTypeSelected(false);
      setShowSelectVehicleModal(false);
      addSelectedVehicle(selectedfueltype);
    };

    const gotoModelScreen = () => {
      setIsModelInputSelected(true);
    };

    return (
      <>
        <Modal
          // className="select-manufacturer-modal"
          show={showSelectVehicleModal}
          onHide={() => setShowSelectVehicleModal(false)}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Body>
            {" "}
            {isManufacturerInputSelected && (
              <SelectManufacturer
                changeBrand={handleBrandChange}
                closeManufacturerScreen={closeManufacturerScreen}
              />
            )}
            {isModelInputSelected && (
              <SelectVehicleModel
                changeVehicleModel={handleVehicleModelChange}
                gotoManufacturerScreen={gotoManufacturerScreen}
              />
            )}
            {selectedbrand != null &&
              selectedmodel != null &&
              selectedfueltype == null &&
              !isModelInputSelected &&
              !isManufacturerInputSelected &&
              isFuelTypeSelected && (
                <SelectFuelType
                  changeFuelType={handleFueltypeChange}
                  gotoModelScreen={gotoModelScreen}
                />
              )}
          </Modal.Body>
        </Modal>
      </>
    );
  };

  return (
    <>
      <div class="my_vehicles">
        <div class="row">
          <div class="col-lg-9 col-md-8 col-sm-12">
            <h5>My Vehicles</h5>
            <p>An overview of your vehicles listed add or remove more</p>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-8 mb-3">
            <button
              class="add-vehicle-btn  px-3 py-1"
              onClick={() => {
                setShowSelectVehicleModal(true);
                setIsManufacturerInputSelected(true);
              }}
            >
              <i class="fas fa-plus pe-2"></i>
              Add Vehicle
            </button>
          </div>
        </div>
        {myVehiclesList?.length == 0 ? (
          <div class="text-center mt-5 p-3 common_border border_radius">
            <>
              <h6>No Vehicle added</h6>
              <p>Add to see list of vehicles</p>
            </>
          </div>
        ) : (
          <>
            <h5 class="text-start">Saved Vehicles</h5>
            {<DisplayMySavedVehicles />}
          </>
        )}
      </div>
      {showSelectVehicleModal && SelectVehicleModal()}
      {showLoader && <Loader />}
    </>
  );
};

export default MyVehicles;
