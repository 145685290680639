import { HttpClient } from '../utils/httpClient';
import { GETWALLET, UPDATEWALLET, WALLETHISTORY } from '../config/constants';
class WalletService {
    constructor() {
        this.httpClient = new HttpClient();
    }

    async updateWallet(body) {
        try {
            const response = await this.httpClient.put(UPDATEWALLET, body);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getCustomerWalletDetails() {
        try {
            const response = await this.httpClient.get(GETWALLET);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getWalletHistory() {
        try {
            const response = await this.httpClient.get(WALLETHISTORY);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

}
export { WalletService }