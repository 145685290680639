import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AuthService } from '../../services/auth.service';
import { CommonServices } from '../../utils/commonServices';
let commonServices = new CommonServices();

const user = commonServices.getLocalStrgJSON("MMX_USER")

const intialState = {
  user: user,
  loginResponse: null,
  message: null,
  isAuthenticated: user ? true : false,
  otpsent: false,
  otpresponse: null,
  registerResponse: null,
  registerResp: null,
  isLogoutSuccess: false
};


/**
 * Write your Async reducers here and add builders for them to the extraReducers object
 */
export const sendotp = createAsyncThunk('auth/sendotp', async (user, thunkAPI) => {
  const authService = new AuthService();
  try {
    const response = await authService.sendotp(user);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});


export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
  const authService = new AuthService();
  try {
    const response = await authService.login(user);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const logout = createAsyncThunk(
  'auth/logout',
  async (user, thunkAPI) => {
    const authService = new AuthService();
    try {
      const response = await authService.logout();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const register = createAsyncThunk(
  'auth/register',
  async (user, thunkAPI) => {
    const authService = new AuthService();
    try {
      const response = await authService.register(user);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState: intialState,
  reducers: {
    reset: (state) => {
      state.user = null;
      state.isLoading = false;
      state.isError = false;
      state.error = null;
      state.isSuccess = false;
      state.success = null;
      state.isAuthenticated = false;
      state.otpsent = false;
      state.otpresponse = null;
      state.loginResponse = null
    },
    resetotp: (state) => {
      state.otpresponse = null;
      state.otpsent = false;
    },
    resetLoginResponse: (state, action) => {
      state.loginResponse = null
    }
  },
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(sendotp.fulfilled, (state, action) => {
        if (action.payload && action.payload.data) {
          var data = action.payload.data;
          if (data.statuscode == 200) {
            state.otpresponse = action.payload.data;
            state.otpsent = true;
            state.message = null;
          }
          else {
            state.otpresponse = action.payload
            state.message = data.message;
          }
        }
      })
      .addCase(login.pending, (state, action) => {
        // state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload && action.payload.data) {
          var data = action.payload.data;
          state.loginResponse = action.payload.data;
          if (data.statuscode == 200) {
            state.user = action.payload.data.data;
            state.isAuthenticated = true;
            state.otpresponse = null;
          }
          else {
            state.message = data.message;
          }

        }
      })
      .addCase(login.rejected, (state, action) => {
        state.isAuthenticated = false;
      })
      .addCase(logout.fulfilled, (state) => {
        state.otpresponse = null;
        state.otpsent = false;
        state.user = null;
        state.isLogoutSuccess = true;
        state.isAuthenticated = false;
      })
      .addCase(register.pending, (state, action) => {
        // state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        if (action.payload && action.payload.data) {
          var data = action.payload.data;
          state.registerResp = action.payload.data
          if (data.statuscode == 200) {
            state.user = action.payload.data.data;
            state.isAuthenticated = true;
            state.otpresponse = null;
          }
          else {
            state.message = data.message;
          }

        }
      })
      .addCase(register.rejected, (state, action) => {
        state.isError = true;
      })
  }
});

export const { reset, resetotp, resetLoginResponse } = authSlice.actions;

export const authSelector = (state) => state.auth;

export default authSlice.reducer;
