import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { addToCart, cartSelector } from "../../features/cart/cartSlice";
import { emergencyserviceselector } from "../../features/emergency/emergencySlice";
import { servicepackageselector } from "../../features/servicepackage/servicepackageSlice";
import { CommonServices } from "../../utils/commonServices";
import "./otherServices.css";
const commonServices = new CommonServices();

const OtherServicesListComponent = (props) => {
  const { serviceCenterAllServices, selectedCategory } = props;
  const [allServices, setAllServices] = useState();

  useEffect(() => {
    if (selectedCategory != null && selectedCategory != undefined) {
      filterServices(selectedCategory);
    } else {
      setAllServices(serviceCenterAllServices);
    }
  }, [serviceCenterAllServices, selectedCategory]);

  const filterServices = (item) => {
    commonServices.setLocalStrg("MMX_SERVICE", JSON.stringify(item));
    setAllServices(null);
    const newList = serviceCenterAllServices?.filter((service) => {
      if (service.serviceCatId === item.serviceCatId) {
        return service;
      }
    });
    setAllServices(newList);
  };

  const isTablet = useMediaQuery({
    query: "(min-width: 500px) and (max-width: 912px)",
  });

  const handleAdd = (service) => {
    const newArray = allServices.map((item) => {
      if (item.servicesId === service.servicesId) {
        return { ...item, isAdded: true };
      } else {
        return item;
      }
    });
    setAllServices(newArray);
  };
  return (
    <>
      <div class="row justify-content-between">
        {allServices && allServices?.length>0 ?
          allServices.map((service, i) => {
            return (
              <>
                <div
                  key={i}
                  class={`each-service col-lg-11 col-md-5 col-sm-12 ${isTablet ? "mx-4" : "mx-auto"
                    }`}
                >
                  <div class="left-part col-lg-3 col-md-2 col-sm-2 m-0">
                    <img
                      src={
                        service?.serviceImage[0]
                          ? service?.serviceImage[0]
                          : service?.servicesCatIcon
                      }
                      className="img-fluid w-100"
                    />
                  </div>
                  <div class="right-part col-lg-8 col-md-9 col-sm-9">
                    <h6 class="fw-600 text-start" style={{ color: "#428372" }}>
                      {service.serviceName}
                    </h6>
                    <div class="d-flex justify-content-between align-items-center">
                      <p class="m-0">
                        <i class="fa fa-inr" aria-hidden="true"></i>
                        {service.after_discount_service_cost}
                      </p>
                      <button
                        class={`${service.is_service_avaible_in_cart
                          ? "added-item px-2"
                          : "add-cart-btn"
                          } `}
                        onClick={() => {
                          // handleAdd(service);
                          props.addOtherService(service);
                        }}
                      >
                        {!service.is_service_avaible_in_cart ? (
                          <>
                            <i class="fas fa-plus"></i>ADD
                          </>
                        ) : (
                          "Added"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </>
            );
          }):(
            <div class="d-flex align-items-center ">
              <img
                src="../assets/imgs/garages/automobile_repair_ywci.png"
                style={{ width: "50%" }}
              />
              <p>Sorry! No Services available </p>
            </div>
          )}
      </div>
    </>
  );
};

const OtherServicesByCenter = () => {
  const { categories } = useSelector(emergencyserviceselector);
  const selectedCategory = commonServices.getLocalStrgJSON("MMX_SERVICE");
  const [active, setActive] = useState(selectedCategory?.serviceCatId);
  const { serviceCenterAllServices } = useSelector(servicepackageselector);
  const { addToCartResponse } = useSelector(cartSelector);
  const [changedCategory, setChangedCategory] = useState(selectedCategory);
  const dispatch = useDispatch();

  const addOtherService = (service) => {
    commonServices.setLocalStrg(
      "MMX_OTHER_SERVICE_DETAILS",
      JSON.stringify(service)
    );
    const vehicle = commonServices.getLocalStrgJSON("MMX_VEHICLE");
    const data = {
      customerVehicleId:
        commonServices.getLocalStrgJSON("MMX_SELECTED_VEHICLE")
          ?.customerVehicleId || "",
      servicesId: service.servicesId,
      vehicleModelId: JSON.parse(vehicle.selectedmodel).vehicleModelId,
      vehicleFueltypeId: JSON.parse(vehicle.selectedfueltype).fueltypeId,
      serviceCenterId: service.serviceCenterId,
      scsId: service.scsId,
    };
    dispatch(addToCart(data));
  };

  return (
    <>
      <div class="row other-services d-flex justify-content-around">
        <div class="col-lg-3 left mt-3">
          <ul>
            {categories &&
              categories.servicecatList &&
              categories.servicecatList.map((item) => {
                return (
                  <>
                    <li
                      onClick={() => {
                        setChangedCategory(item);
                        // filterServices(item);
                        setActive(item.serviceCatId);
                      }}
                    >
                      <Link to={"#"} className="nav-link">
                        <div
                          class={`element row d-flex align-items-center ${active === item.serviceCatId && "active"
                            }`}
                        >
                          <div class="col-lg-3">
                            <img
                              src={item.serviceCatDesktopImage}
                              className="img-fluid"
                            />
                          </div>
                          <div class="col-lg-9">
                            <p>{item.name}</p>
                          </div>
                        </div>
                      </Link>
                    </li>
                  </>
                );
              })}
          </ul>
        </div>
        <div class="col-lg-8 right">
          <OtherServicesListComponent
            serviceCenterAllServices={serviceCenterAllServices}
            selectedCategory={changedCategory}
            addOtherService={(service) => {
              addOtherService(service);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default OtherServicesByCenter;
export { OtherServicesListComponent };
