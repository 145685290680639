import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import "./MOPWallet.css";
import {
  getCustomerWalletDetails,
  getWalletHistory,
  walletSelector,
} from "../../features/account/walletSlice";
import { CommonServices } from "../../utils/commonServices";
import { authSelector } from "../../features/auth/authSlice";

const MMXWallet = () => {
  const [wallet, setWallet] = useState(null);
  const commonServices = new CommonServices();
  const dispatch = useDispatch();
  const { customerWalletDetails, walletHistory } = useSelector(walletSelector);
  const [walletHistoryList, setWalletHistoryList] = useState(null);
  const { isAuthenticated } = useSelector(authSelector);
  const [referralCode, setreferralCode] = useState(null);
  useEffect(() => {
    if (isAuthenticated) {
      const referral_code =
        commonServices.getLocalStrgJSON("MMX_USER").referral_code;
      setreferralCode(referral_code);
    } else {
      setreferralCode(null);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    dispatch(getCustomerWalletDetails());
    dispatch(getWalletHistory());
  }, []);

  useEffect(() => {
    if (walletHistory?.length > 0) {
      const newArray = walletHistory.map((item) => {
        let date = commonServices.formateDate(item.created_at);
        return { ...item, created_at: date };
      });
      setWalletHistoryList(newArray);
    }
  }, [walletHistory]);

  const isLaptopOrDesktop = useMediaQuery({
    query: "(min-width: 1008px) and (max-width: 1824px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width: 500px) and (max-width: 912px)",
  });

  return (
    <>
      <div class="wallet">
        <div class="row section1">
          <div class="left-part col-lg-9 col-md-8 col-sm-12">
            <h5>My Wallet</h5>
            <p>
              An overview of your MMX wallet- Manage wallet balance, add more,
              check history
            </p>
          </div>
          <div class="right-part col-lg-3 col-md-4 col-sm-12 px-2 py-2">
            <div class="d-flex align-items-center justify-content-between">
              <p class="m-0">Wallet Id</p>
              <p class="m-0 fw-600"> mywallet@upi</p>
            </div>
            <div class="mt-2 d-flex align-items-center justify-content-between">
              <p class="m-0">Balance</p>
              <h4 class="m-0">
                <i class="fa fa-inr" aria-hidden="true"></i>
                {customerWalletDetails ? customerWalletDetails.amount : 0}
              </h4>
            </div>
          </div>
        </div>
        <div class="row section2">
          <div class="left-part col-lg-9 col-md-8 col-sm-12">
            <strong>
              <span>Refer & Earn More</span>
            </strong>
            <p>
              Get a friend to start using MMX today and earn 100/- when they
              complete their first transaction
            </p>
          </div>
          <div class=" rounded-3 right-part col-lg-3 col-md-4 col-sm-12 p-2">
            <p class="text-center m-0 mb-2 share-referal-txt">
              Share your Referral Code
            </p>
            <div class="d-flex align-items-center justify-content-center">
              <button>
                <p class="m-0 py-2 px-2 fw-600">{referralCode}</p>
                {/* <i class="fas fa-share-alt fa-1x"></i>  */}
              </button>
            </div>
            {/* </div> */}
          </div>
        </div>

        <div class="row walletHistory">
          <h6>Wallet Histroy</h6>
          <div class="wallet-history-list">
            {walletHistoryList?.length > 0 &&
              walletHistoryList?.map((item) => {
                return (
                  <div class="hist px-3 py-2 mb-3">
                    <div>
                      <span
                        class={`fw-600 ${item.credittype === "Dr" ? "debited" : "credited"
                          }`}
                      >
                        {item.credittype === "Dr"
                          ? "Debited money from wallet"
                          : "Added money to wallet"}
                      </span>
                      <p class="m-0 mt-2">
                        {item?.created_at}
                        {/* {/ <a>Download Invoice</a> /} */}
                      </p>
                    </div>
                    <div>
                      <h5
                        class={`${item.credittype === "Dr" ? "debited" : "credited"
                          }`}
                      >
                        {item.credittype === "Dr" ? "-" : "+"}
                        {item?.amount}
                      </h5>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default MMXWallet;
