import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { emergencyserviceselector, updatecategoryselected, updateSelectedCategoryIndex } from '../../features/emergency/emergencySlice';
import '../CarServices/car_services.css';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    updateServicePackagesList
} from "../../features/servicepackage/servicepackageSlice";
import { useMediaQuery } from "react-responsive";
import { CommonServices } from '../../utils/commonServices';

function CarServices(props) {
    const { categories } = useSelector(emergencyserviceselector);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const commonServices = new CommonServices()
    const [categoriesList, setCategoriesList] = useState([]);

    const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
    const isTablet = useMediaQuery({
        query: "(min-width: 500px) and (max-width: 912px)",
    });
    const isLaptopOrDesktop = useMediaQuery({
        query: "(min-width: 1008px) and (max-width: 1824px)",
    });

    useEffect(() => {
        if (categories !== null) {
            setCategoriesList(categories);
        }
    }, [categories])

    const categoryselected = (data, i) => {
        dispatch(updateServicePackagesList(null))
        dispatch(updateSelectedCategoryIndex(i))
        commonServices.setLocalStrg("MMX_SERVICE", JSON.stringify(data));
        dispatch(updatecategoryselected(data));
        navigate('/services');
    };


    return (
        <div className={`car-services ${(isMobile || isTablet) && "mt-4"}`}>
            <h4>CAR SERVICES</h4>
            <p>Search from our service centres and book a slot closest to you and at your convenience</p>
            <div className="row">
                {categoriesList && categoriesList.servicecatList && categoriesList.servicecatList.map((service, i) => {

                    // Return the element. Also pass key     
                    return (<div key={i} className="col-lg-3 col-md-4 col-sm-4 col-4">
                        <div className="card text-center m-auto" onClick={() => categoryselected(service, i)}>


                            {isLaptopOrDesktop && <div class="card-image"><img src={service.serviceCatDesktopImage} /></div>}


                            {(isMobile || isTablet) && <div class="img-background"><img src={service.serviceCatIcon} class="w-100" /></div>}

                            <div className="card-body w-100 p-1 d-flex align-items-center justify-content-around">
                                <p className="card-text " style={{ fontSize: "12px" }}>{service.name}</p>
                            </div>
                        </div>
                    </div>)
                })}
            </div>
        </div>

    );
}

export default CarServices;