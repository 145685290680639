import { useSelector, useDispatch } from "react-redux";
import { setActiveModule } from "../features/account/customerProfileSlice";
import { checkoutSelector } from "../features/Checkout/checkoutslice";
import { useNavigate } from "react-router-dom";
import OrderDetailsModal from "../components/OrderDetailsModal/OrderDetailsModal";
import {
  getOrderDetails,
  updateOrderDetails,
} from "../features/account/orderSlice";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useMediaQuery } from "react-responsive";

const OrderSuccessfull = () => {
  window.scrollTo(0, 0);
  const { selectedSchedule, orderDetails } = useSelector(checkoutSelector);
  const [showOrderDetails, setShowOrderDetails] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  const handleClick = (orderId) => {
    dispatch(updateOrderDetails(null));
    dispatch(getOrderDetails(orderId));
    if (isMobile) {
      navigate("/order-details")
    } else {
      setShowOrderDetails(true);
    }
  };

  return (
    <>
      <div class="order-successfull-page">
        <div>
          <div class="d-flex align-items-center justify-content-around">
            <img
              src="../assets/imgs/orderImages/successfulImg.png"
              style={{ width: "215px" }}
            ></img>
          </div>
          <h5 style={{ color: "#2D2D2D" }} class="text-center my-3 fw-600">
            Order Confirmed!
          </h5>
          <div class="mb-3">
            <p
              class="m-0 text-center fw-normal pointer"
              onClick={() => {
                handleClick(orderDetails?.orderId);
              }}
            >
              Order Id :{orderDetails?.orderNo}
            </p>
            <p class="m-0 text-center fw-normal">
              Date: {selectedSchedule?.selectedDay.slot_date}
            </p>
            <p class="m-0 text-center fw-normal">
              Slot Time - {selectedSchedule?.selectedSlot.from_time} -{" "}
              {selectedSchedule?.selectedSlot.to_time}
            </p>
          </div>
          <div class="mb-5">
            <p class="m-0 text-center fw-normal">
              Thank you for placing the service!!
            </p>
            <p class="m-0 text-center fw-normal">
              Sit back and relax while we deliver an
            </p>
            <p class="m-0 text-center fw-normal">awesome service experience</p>
          </div>
          <div class="d-flex align-items-center justify-content-around mb-3">
            <button
              class="order-track-btn text-white border-0 py-2"
              onClick={() => {
                dispatch(setActiveModule("Order History"));
                navigate("/profile/order-history");
              }}
            >
              Track Service
            </button>
          </div>
        </div>
      </div>
      {showOrderDetails && (
        // <OrderDetailsModal
        //   show={showOrderDetails}
        //   handleClose={() => {
        //     setShowOrderDetails(false);
        //   }}
        // />
        <>
          <Modal
            className="order-details-modal"
            show={showOrderDetails}
            onHide={() => { setShowOrderDetails(false) }}
            // backdrop="static"
            // keyboard={false}
            centered
          >
            <Modal.Body>
              <OrderDetailsModal handleClose={() => { setShowOrderDetails(false) }} />
            </Modal.Body>
          </Modal>l
        </>
      )}
    </>
  );
};

export default OrderSuccessfull;
