import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BannerService } from '../../services/banner.service'

const intialState = {
  banners: null
};

export const getBanners = createAsyncThunk('banner/getBanners', async (vehicleType, thunkAPI) => {
  const bannerservice = new BannerService();
  try {
    const response = await bannerservice.getBanners(vehicleType);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});


const bannerSlice = createSlice({
  name: 'banner',
  initialState: intialState,
  reducers: {
    reset: (state) => {
      state.banners = null
    },
  },
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getBanners.fulfilled, (state, action) => {
        state.banners = action.payload.data;
      });
  }
});


export const { reset } = bannerSlice.actions;

export const bannerselector = (state) => state.banner;

export default bannerSlice.reducer;