export const EmergencyServiceData =
    [
        {
            id: 1,
            PresentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-01.png",
            ObsentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-13.png",
            ServiceName: "Periodic General Service"
        },
        {
            id: 2,
            PresentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-02.png",
            ObsentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-14.png",
            ServiceName: "Periodic General Service"
        },
        {
            id: 3,
            PresentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-03.png",
            ObsentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-14.png",
            ServiceName: "Periodic General Service"
        },
        {
            id: 4,
            PresentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-04.png",
            ObsentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-15.png",
            ServiceName: "Periodic General Service"
        },
        {
            id: 5,
            PresentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-05.png",
            ObsentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-16.png",
            ServiceName: "Periodic General Service"
        },
        {
            id: 6,
            PresentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-06.png",
            ObsentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-17.png",
            ServiceName: "Periodic General Service"
        },
        {
            id: 7,
            PresentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-07.png",
            ObsentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-18.png",
            ServiceName: "Periodic General Service"
        }
    ]

export const CarServiceData =
    [
        {
            id: 1,
            PresentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-01.png",
            ObsentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-13.png",
            ServiceName: "Periodic General Service"
        },
        {
            id: 2,
            PresentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-02.png",
            ObsentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-14.png",
            ServiceName: "Periodic General Service"
        },
        {
            id: 3,
            PresentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-03.png",
            ObsentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-14.png",
            ServiceName: "Periodic General Service"
        },
        {
            id: 4,
            PresentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-04.png",
            ObsentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-15.png",
            ServiceName: "Periodic General Service"
        },
        {
            id: 5,
            PresentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-05.png",
            ObsentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-16.png",
            ServiceName: "Periodic General Service"
        },
        {
            id: 6,
            PresentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-06.png",
            ObsentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-17.png",
            ServiceName: "Periodic General Service"
        },
        {
            id: 7,
            PresentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-07.png",
            ObsentIcon: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/emergency-services/pro_icons-18.png",
            ServiceName: "Periodic General Service"
        }
    ]

export const CarSapNearYouData =
    [
        {
            id: 1,
            ImageURL: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/spa/spa1.png",
            Tittle: "Narayana Autoparts",
            Address: "#12, HSR Layout"
        },
        {
            id: 2,
            ImageURL: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/spa/spa1.png",
            Tittle: "Narayana Autoparts",
            Address: "#13, HSR Layout"
        },
        {
            id: 3,
            ImageURL: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/spa/spa1.png",
            Tittle: "Narayana Autoparts",
            Address: "#14, HSR Layout"
        },
        {
            id: 4,
            ImageURL: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/spa/spa1.png",
            Tittle: "Narayana Autoparts",
            Address: "#13, HSR Layout"
        },
        {
            id: 5,
            ImageURL: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/spa/spa1.png",
            Tittle: "Narayana Autoparts",
            Address: "#13, HSR Layout"
        },
        {
            id: 6,
            ImageURL: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/spa/spa1.png",
            Tittle: "Narayana Autoparts",
            Address: "#16, HSR Layout"
        }
    ]
export const benifitData =
    [
        {
            id: 0,
            ImageURL: "assets/imgs/benifits/Verified-Garages.png",
            Header: "Verified Garages",
            Para: "Our platform offers access to the best verified gararges, ensuring that your car receives the highest quality of service"
        },
        {
            id: 1,
            ImageURL: "assets/imgs/benifits/Convenient-Booking.png",
            Header: "Convenient Booking",
            Para: "You can book your preferred garage and time slot at your convenience through our platform, making the process hassle-free."
        },
        {
            id: 2,
            ImageURL: "assets/imgs/benifits/Service-Buddy.png",
            Header: "Service Buddy",
            Para: "Our innovative service buddy system provides timely updates and assistance throughout the entire car service process, ensuring you are always in the loop."
        },
        {
            id: 3,
            ImageURL: "assets/imgs/benifits/All-Car-Services.png",
            Header: "All Car Services",
            Para: "We provide all kinds of car services at one place, from routine maintenance to major repairs, giving you peace of mind that your vehicle is always in peak condition."
        }
    ]

export const GaragesServicesData =
    [
        {
            id: 1,
            ImageURL: "assets/imgs/garages/garages-1.png",
            Tittle: "Narayana Autoparts",
            Address: "#13, HSR Layout"
        },
        {
            id: 2,
            ImageURL: "assets/imgs/garages/garages-1.png",
            Tittle: "Narayana Autoparts",
            Address: "#13, HSR Layout"
        },
        {
            id: 3,
            ImageURL: "assets/imgs/garages/garages-1.png",
            Tittle: "Narayana Autoparts",
            Address: "#13, HSR Layout"
        },
        {
            id: 4,
            ImageURL: "assets/imgs/garages/garages-1.png",
            Tittle: "Narayana Autoparts",
            Address: "#13, HSR Layout"
        },
        {
            id: 5,
            ImageURL: "assets/imgs/garages/garages-1.png",
            Tittle: "Narayana Autoparts",
            Address: "#13, HSR Layout"
        },
        {
            id: 6,
            ImageURL: "assets/imgs/garages/garages-1.png",
            Tittle: "Narayana Autoparts",
            Address: "#13, HSR Layout"
        }
    ]
export const GiftServicesData =
    [
        {
            id: 1,
            ImageURL: "https://work.eruditewebsolutions.co.in/html/metamechx/assets/imgs/key/key.png",
            GiftDesc: "Silver Key Ring",
            Amount: "₹ 899"
        },
        {
            id: 2,
            ImageURL: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/key/key.png",
            GiftDesc: "Silver Key Ring",
            Amount: "₹ 899"
        },
        {
            id: 3,
            ImageURL: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/key/key.png",
            GiftDesc: "Silver Key Ring",
            Amount: "₹ 899"
        },
        {
            id: 4,
            ImageURL: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/key/key.png",
            GiftDesc: "Silver Key Ring",
            Amount: "₹ 899"
        },
        {
            id: 5,
            ImageURL: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/key/key.png",
            GiftDesc: "Silver Key Ring",
            Amount: "₹ 899"
        },
        {
            id: 6,
            ImageURL: "https://work.eruditewebsolutions.co.in/html/metamechx/assets//imgs/key/key.png",
            GiftDesc: "Silver Key Ring",
            Amount: "₹ 899"
        }
    ]

export const HowMetWorksData =
    [
        {
            id: 0,
            ImageURL: "assets/imgs/metamechx-works/first.png",
            Header: "Select your car Service",
            Para: "Select your car and the service required from a variety of services"
        },
        {
            id: 1,
            ImageURL: "assets/imgs/metamechx-works/second.png",
            Header: "Book a slot and schedule pickup",
            Para: "Search from our service centres and book a slot closest to you and at your convenience"
        },
        {
            id: 2,
            ImageURL: "assets/imgs/metamechx-works/third.png",
            Header: "Track your service in real time",
            Para: "Select your car and the service required from a variety of services"
        },
        {
            id: 3,
            ImageURL: "assets/imgs/metamechx-works/fourth.png",
            Header: "Relax and refer",
            Para: "Refer and Earn amazing rewards"
        }
    ]

export const BannerData =
    [
        {
            id: 0,
            ImageURL: "https://work.eruditewebsolutions.co.in/html/metamechx/assets/imgs/banner_1.jpg"
        },
        {
            id: 1,
            ImageURL: "https://work.eruditewebsolutions.co.in/html/metamechx/assets/imgs/banner_1.jpg"
        },
        {
            id: 2,
            ImageURL: "https://work.eruditewebsolutions.co.in/html/metamechx/assets/imgs/banner_1.jpg"
        }
    ]

export const Worktypes = {
    carSpaWorkTypes: [
        "62f0efa760c9fba4d1613e48",
        "62f1137e60c9fba4d1613f1f",
    ],
    garageWorkTypes: [
        "62f1139460c9fba4d1613f24"
    ]
}