import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { contactUs, partnerSelector,reset } from "../../features/partner/partnerSlice";
import { STATUS_CODES } from "../../config/constants";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const WritetoUs = () => {


    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { contactUsResp } = useSelector(partnerSelector)
    const [formData, setFormData] = useState({})
    const [showSuccessResp, setShowSuccessResp] = useState(null);
    const [error, setError] = useState(null);
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [isInvalidForm,setIsInvalidForm] = useState(false)
    const validateMobileNumber = (e) => {
        const onlyDigits = e.target.value.replace(/\D/g, "");
        setFormData({ ...formData, mobile_no: onlyDigits })
        const expr = /^[6-9]{1}[0-9]{9}$/;
        if (expr.test(e.target.value) && e.target.value.length === 10) {
            setError(null);
            setShowErrorMsg(false);
        }else{
            setError("Please Enter a valid mobile number");
            setShowErrorMsg(true);
        }
    }

    const handleSubmit = () => {

        if(formData.mobile_no && formData.serviceCenterName && formData.name && formData.location){
            setIsInvalidForm(false)
            dispatch(contactUs({ ...formData, type: 2 }))
        }
        else {
            setIsInvalidForm(true)
        }
    }

    useEffect(() => {
        if (contactUsResp && contactUsResp.statuscode === STATUS_CODES[200]) {
            setFormData({});
            setShowSuccessResp(true);
            toast.success(contactUsResp.message,{
                autoClose:3000,
                hideProgressBar:true,
                toastId:"form-submitted-successfully"
            })
            setTimeout(()=>{
                dispatch(reset())
            },[3000])
            navigate("/");
        } else {
            setShowSuccessResp(true)
            setTimeout(() => {
                setShowSuccessResp(false)
            }, [5000])
        }
    }, [contactUsResp])

    return (
        <>
            <div class="write-to-us ">
                <p class="write-to-us-header text-center my-4">Write to Us</p>
                <form class={`${isInvalidForm ?"was-validated":"needs-validation"} `} novalidate>
                    <div className="row w-100 m-auto">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="mx-4">
                                <div class="mb-3 row">
                                    <label for="Service-Center" class="form-label mb-2 label">Service Center Name</label>
                                    <input type="text"
                                        id="Service-Center"
                                        placeholder="Service Center Name"
                                        class="col-lg-12 col-sm-12 col-md-11 form-control "
                                        value={formData?.serviceCenterName || ""}
                                        autoComplete="nope" 
                                        onChange={(event) => { setFormData({ ...formData, serviceCenterName: event.target.value }) }} 
                                        required/>
                                    <div class="invalid-feedback">Please enter service center name</div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="mb-2 label">Contact person Name</label>
                                    <input type="text"
                                        placeholder="Contact person Name"
                                        class="col-lg-12 col-sm-12 col-md-11 form-control "
                                        value={formData?.name || ""}
                                        autoComplete="nope" 
                                        onChange={(event) => { setFormData({ ...formData, name: event.target.value }) }} 
                                        required/>
                                    <div class="invalid-feedback">Please enter Contact person Name</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div class="mx-4">
                                <div class="mb-3 row">
                                    <label class="mb-2 label">Contact Number</label>
                                    <input type="tel"
                                        maxLength={10}
                                        placeholder="Contact Number"
                                        class="col-lg-12 col-sm-12 col-md-11 form-control "
                                        value={formData?.mobile_no || ""}
                                        autoComplete="nope" 
                                        onChange={(event) => { validateMobileNumber(event); }}
                                        required
                                    />
                                    {showErrorMsg && <p class="m-0 error-text text-start">{error}*</p>}

                                    <div class="invalid-feedback">Please enter Contact Number</div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="mb-2 label">Locality</label>
                                    <input type="text"
                                        placeholder="Locality"
                                        class="col-lg-12 col-sm-12 col-md-11 form-control "
                                        value={formData?.location || ""}
                                        onChange={(event) => { setFormData({ ...formData, location: event.target.value }) }}
                                        autoComplete="nope" 
                                        required/>
                                    <div class="invalid-feedback">Please enter Locality</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div class="d-flex align-items-center justify-content-center mt-4">
                    <button class="write-us-submit-btn" type="submit" onClick={handleSubmit}>
                        Submit
                    </button>
                </div>
                {showSuccessResp && <p class="mt-5 mb-0 success-resp text-center">{contactUsResp?.message}</p>}
            </div>
        </>
    )
}

export default WritetoUs;