import { useState, useEffect } from "react";
import { DebounceInput } from "react-debounce-input";
import { useDispatch, useSelector } from "react-redux";
import SkeletonLoader from "../../common/SkeletonLoaders/SkeletonLoaders";
import {
  getbrands,
  homeselector,
  updatebrand,
  updateModelsList,
  updateBrands,
  getmodels,
} from "../../features/home/homeSlice";
import { CommonServices } from "../../utils/commonServices";

const SelectManufacturer = (props) => {
  const commonServices = new CommonServices()
  const dispatch = useDispatch();
  const [searchKey, setSearchKey] = useState("");
  const { brands, selectedbrand } = useSelector(homeselector);
  const vehicleTypeId = commonServices.getLocalStrg("VEHICLE_TYPE");
  useEffect(() => {
    dispatch(updateBrands(null));
    const data = { vehicleType: vehicleTypeId };
    dispatch(getbrands(data));
  }, []);

  const search = (event) => {
    dispatch(updateBrands(null));
    const searchKey = event.target.value || "";
    setSearchKey(event.target.value);
    const data = { vehicleType: vehicleTypeId, search: searchKey };
    dispatch(getbrands(data));
  };

  const handleBrandChange = (brand) => {
    props.changeBrand();
    if (JSON.parse(brand).brandId != JSON.parse(selectedbrand)?.brandId) {
      dispatch(updateModelsList(null));
    }
    dispatch(updatebrand(brand));
  };

  const handleClose = () => {
    props.closeManufacturerScreen();
  };

  return (
    <>
      <form action="" className="side-box select-manufacturer">
        <div class="header">
          <label htmlFor="Manufacturer">Search Manufacturer</label>
          <i
            class="fas fa-times"
            onClick={() => {
              handleClose();
            }}
          ></i>
        </div>
        <div class="search-field">
          <DebounceInput
            minLength={2}
            debounceTimeout={1000}
            type="text"
            placeholder="Search Manufacturer"
            value={searchKey}
            name="Manufacturer"
            className="inside"
            id=" Manufacturer"
            autocomplete="off"
            onChange={(e) => {
              search(e);
            }}
          />
          <i class="fas fa-search"></i>
        </div>
        <div class=" brand-logos">
          {brands ? (
            brands.map((brand) => {
              return (
                <div
                  class="each-brand rounded-3"
                  onClick={() => {
                    handleBrandChange(JSON.stringify(brand));
                  }}
                >
                  <img
                    src={brand.brandLogoUrl}
                    className="img-fluid w-50"
                    style={{ height: "60px" }}
                  />
                  <p class="m-0 text-truncate" style={{ fontSize: "13px" }}>
                    {brand.brandName}
                  </p>
                </div>
              );
            })
          ) : (
            <SkeletonLoader
              isSmallLoader={true}
              count={3}
              loopCount={9}
              width="20%"
            />
          )}
        </div>
      </form>
    </>
  );
};

export default SelectManufacturer;
