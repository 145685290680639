import React, { useState } from 'react';
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from 'react-redux';
import { bannerselector } from '../../features/banner/bannerSlice';

const Banner = () => {
  const { banners } = useSelector(bannerselector);
  const dispatch = useDispatch();
  const [bannersList, setBannersList] = useState([])

  const options = {
    items: 4,
    nav: true,
    navText: [
      "<div className='nav-btn prev-slide'></div>",
      "<div className='nav-btn next-slide'></div>",
    ],
    loop: true,
    dots: false,
    // pagination:true,
    autoplay: true,
    responsiveBaseElement: "body",
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      980: {
        items: 1,
      },
      1199: {
        items: 1,
      },
    },
  };
  return (
    <div class="banner">
      <OwlCarousel className="owl-carousel owl-theme position-absolute" {...options}>
        {banners && banners.map((banner) => {
          return (
            <div >
              <img src={banner.image_path} className="banner-img d-block w-100" alt="..." style={{ height: "100vh" }} />
            </div>
          )
        })}

      </OwlCarousel>
    </div>
  );
};

export default Banner;