import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HomeService } from '../../services/home.service';
import { CommonServices } from '../../utils/commonServices';
const commonServices = new CommonServices();

const vehicleselected = commonServices.getLocalStrgJSON("MMX_VEHICLE");
const vehicleType = commonServices.getLocalStrg("VEHICLE_TYPE")
let intialState;
if (vehicleselected != null) {
  intialState = vehicleselected
}
else {
  intialState = {
    vehicleType: vehicleType ? vehicleType : null,
    brands: null,
    selectedbrand: null,
    models: null,
    selectedmodel: null,
    fueltypes: null,
    selectedfueltype: null,
    selectedYear: null,
    selectedvehicle: null,
    message: null,
    addVehicleResponse: null,
    closeMenuflag: false
  };
}


/**
 * Write your Async reducers here and add builders for them to the extraReducers object
 */
export const getVehicleType = createAsyncThunk('home/getVehicleType', async (vehicleType, thunkAPI) => {
  const homeService = new HomeService();
  try {
    const response = await homeService.getvehicletype();
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getfuelType = createAsyncThunk('home/getfuelType', async (data, thunkAPI) => {
  const homeService = new HomeService();
  try {
    const response = await homeService.getFuelList(data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getbrands = createAsyncThunk('home/getbrands', async (data, thunkAPI) => {
  const homeService = new HomeService();
  try {
    const response = await homeService.getBrandList(data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getmodels = createAsyncThunk('home/getmodels', async (data, thunkAPI) => {
  const homeService = new HomeService();
  try {
    const response = await homeService.getModelList(data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const addVehicle = createAsyncThunk('home/addVehicle', async (data, thunkAPI) => {
  const homeService = new HomeService();
  try {
    const response = await homeService.addVehicle(data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});


const homeSlice = createSlice({
  name: 'home',
  initialState: intialState,
  reducers: {
    reset: (state) => {
      state.vehicleType = 0
      state.message = null
    },
    updateBrands: (state, action) => {
      state.brands = action.payload
    },
    updateModelsList: (state, data) => {
      state.models = data.payload
    },
    updateFueltypesList: (state, data) => {
      state.fueltypes = data.payload
    },
    updatebrand: (state, data) => {
      state.selectedbrand = data.payload
    },
    updatemodel: (state, data) => {
      state.selectedmodel = data.payload
    },
    updatefueltype: (state, data) => {

      state.selectedfueltype = data.payload
      if (state.selectedfueltype != null) {
        state.selectedvehicle = JSON.parse(state.selectedbrand).brandName + " " + JSON.parse(state.selectedmodel).modelName + "," + JSON.parse(state.selectedfueltype).fueltype;
        commonServices.setLocalStrg("MMX_VEHICLE", JSON.stringify(state));
      }
    },
    updateModelYear: (state, data) => {
      if (data.payload != null) {
        state.selectedYear = data.payload;
      } else {
        state.selectedYear = data.payload
      }
      commonServices.setLocalStrg("MMX_VEHICLE", JSON.stringify(state));
    },
    updateSelectedVehicle: (state, action) => {
      state.selectedvehicle = action.payload;
    },
    updateCloseMenuFlag: (state, action) => {
      state.closeMenuflag = action.payload
    }
  },
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getbrands.fulfilled, (state, action) => {
        if (action.payload && action.payload.data) {
          var data = action.payload.data;
          if (data.statuscode == 200) {
            state.brands = data.data;
            state.message = null;
          }
          else {
            state.message = data.message;
          }
        }
      })
      .addCase(getmodels.fulfilled, (state, action) => {
        if (action.payload && action.payload.data) {
          var data = action.payload.data;
          if (data.statuscode == 200) {
            state.models = data.data;
            state.message = null;
          }
          else {
            state.message = data.message;
          }
        }
      })
      .addCase(getfuelType.fulfilled, (state, action) => {
        if (action.payload && action.payload.data) {
          var data = action.payload.data;
          if (data.statuscode == 200) {
            state.fueltypes = data.data;
            state.message = null;
          }
          else {
            state.message = data.message;
          }
        }
      })
      .addCase(getVehicleType.fulfilled, (state, action) => {
        if (action.payload && action.payload.data) {
          var data = action.payload.data;
          if (data.statuscode == 200) {
            data.data.forEach((vehicle) => {
              if (vehicle.disp_name === "Car") {
                state.vehicleType = vehicle.vehicleTypeId;
                commonServices.setLocalStrg("VEHICLE_TYPE", state.vehicleType)
              } else if (vehicle.disp_name === "Bike") {
                state.vehicleType = vehicle.vehicleTypeId;
                commonServices.setLocalStrg("VEHICLE_TYPE", state.vehicleType)
              }
            });
            state.message = null;
          }
          else {
            state.message = data.message;
          }
        }
      })
      .addCase(addVehicle.fulfilled, (state, action) => {
        if (action.payload && action.payload.data) {
          state.addVehicleResponse = action.payload.data;
        }
      })
  }
});

export const { reset, updatebrand, updatefueltype, updatemodel, updateModelYear, updateBrands, updateModelsList, updateFueltypesList, updateSelectedVehicle, updateCloseMenuFlag } = homeSlice.actions;

export const homeselector = (state) => state.home;

export default homeSlice.reducer;
