import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import Cart from "../../components/Cart";
import SearchServices from "../../components/SearchServices/SearchServices";
import { cartSelector } from "../../features/cart/cartSlice";
import { servicepackageselector } from "../../features/servicepackage/servicepackageSlice";

function Services(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { cartItemsCount } = useSelector(cartSelector);

  const location = useLocation();
  const { showSelectVehicleModal } = useSelector(servicepackageselector);

  return (
    <div className="container-fluid" style={{ contain: "paint" }}>
      <div className={`row main_device d-flex justify-content-between `}>
        <div
          className={`left col-md-12 col-sm-12 services-sub-module   h-100 float-start col-lg-8`}
        >
          <Outlet />
          {/* <EngineMaintainance /> */}
        </div>
        <div
          class={`col-lg-4 float-end mb-5 ${
            location.pathname === "/services/mycart" &&
            (cartItemsCount === 0 ||
              cartItemsCount === null ||
              cartItemsCount === undefined) &&
            "d-none"
          }`}
          style={{
            height: "fit-content",
            position: "sticky",
            top: `${!showSelectVehicleModal ? "8%" : "13%"}`,
          }}
        >
          {!showSelectVehicleModal ? <Cart /> : <SearchServices />}
        </div>
      </div>
    </div>
  );
}

export default Services;
