import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  authSelector,
  login,
  resetotp,
  sendotp,
  register,
  reset,
  resetLoginResponse,
} from "../../features/auth/authSlice";
import { useMediaQuery } from "react-responsive";

import Modal from "react-bootstrap/Modal";
import { DebounceInput } from "react-debounce-input";
import { toast } from "react-toastify";
import OTPInput, { ResendOTP } from "otp-input-react";
import Loader from "../../common/Loader/Loader";
import "../Login/login.css";
import { STATUS_CODES } from "../../config/constants";



const LoginOrRegistrationModalComponent = (props) => {
  const { show } = props;
  let mobileNumberRef = useRef(null);
  const [mobileNum, setMobileNum] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [referralCode, setReferralCode] = useState("")
  const [nameErr, setNameErr] = useState(false);
  const [disable, setDisable] = useState(true);
  const [emailValid, setEmailValid] = useState(false);

  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const { otpsent, otpresponse, loginResponse } = useSelector(authSelector);
  const dispatch = useDispatch();
  const [OTP, setOTP] = useState("");
  const [timerCompleted, setTimerCompleted] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  useEffect(() => {
    mobileNumberRef.focus();
  }, []);

  useEffect(() => {
    validateButton();
  }, [name, email]);

  const validateEmail = (event) => {
    let mail = event.target.value;

    const mailRegex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,6}$/;

    if (!mailRegex.test(mail)) {
      setEmailValid(true);
      setDisable(true);
      toast.warning("Please enter a valid mail address", {
        toastId: "emailAddress",
        autoClose: 2000,
        hideProgressBar: true,
      });
    } else {
      setEmailValid(false);
      setEmail(event.target.value);
    }
  };

  const validateName = (event) => {
    let customerName = event.target.value;
    if (customerName.length > 0) {
      setName(event.target.value);
      validateButton();
    } else {
      setDisable(true);
    }
  };

  const validateButton = () => {
    if (name && email) {
      setDisable(false);
    }
  };

  const handleLoginClick = () => {
    let mobileNumber = mobileNum;

    const expr = /^[6-9]{1}[0-9]{9}$/;

    if (!expr.test(mobileNumber) || mobileNumber.length < 10) {
      toast.warning("please enter a valid mobile number", {
        toastId: "mobileNumber",
        autoClose: 2000,
        hideProgressBar: true,
      });
    } else {
      if (!otpsent) {
        const data = { mobile: mobileNumber };
        dispatch(sendotp(data));
        setOTP(null);
      } else {
        const sentotpresponse = otpresponse.data;
        const data = { otp: OTP, ...sentotpresponse };
        dispatch(login(data));
      }
    }
  };

  useEffect(() => {
    if (otpresponse && otpresponse.statuscode !== STATUS_CODES[200]) {
      toast.error(otpresponse.message, {
        toastId: "error in otp",
        autoClose: 2000,
        hideProgressBar: true,
      });
      dispatch(resetotp());
    }
  }, [otpresponse]);

  useEffect(() => {
    if (OTP) {
      if (OTP.length === 6) {
        const sentotpresponse = otpresponse.data;
        const data = { otp: OTP, ...sentotpresponse };
        dispatch(login(data));
      }
    }
  }, [OTP]);

  useEffect(() => {
    if (
      loginResponse?.status === "success" &&
      loginResponse?.statuscode === 200
    ) {
      toast.success(loginResponse.message, {
        autoClose: 2000,
        hideProgressBar: true,
      });
    } else if (
      loginResponse?.statuscode === 301 ||
      loginResponse?.statuscode === 303 ||
      loginResponse?.statuscode === 302
    ) {
      toast.error(loginResponse.message, {
        autoClose: 2000,
        hideProgressBar: true,
      });
    } else if (loginResponse?.statuscode === 310) {
      toast.info(loginResponse.message, {
        autoClose: 2000,
        hideProgressBar: true,
      });
      setShowRegistrationForm(true);
    } else {
      toast.warning(loginResponse?.message, {
        autoClose: 2000,
        hideProgressBar: true,
      });
    }
    dispatch(resetLoginResponse());
  }, [loginResponse]);

  const resendOTP = () => {
    let mobileNumber = mobileNum;
    const data = { mobile: mobileNumber };
    dispatch(sendotp(data));
    setTimerCompleted(false);
  };

  const handleRegister = () => {
    let customerName = name;

    if (
      customerName === "" ||
      customerName === undefined ||
      customerName === null
    ) {
      setNameErr(true);
    } else {
      const sentotpresponse = otpresponse.data;
      dispatch(
        register({
          session: sentotpresponse.session,
          mobile: sentotpresponse.mobile,
          fullname: customerName,
          email: email,
          home_address: address,
          referralCode: referralCode,
        })
      );
      setNameErr(false);
    }
  };
  const validateMobileNum = (e) => {
    const onlyDigits = e.target.value.replace(/\D/g, "");
    setMobileNum(onlyDigits);
  };
  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleLoginClick();
      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetotp());
    }
  }, [])

  return (
    <>
      <div class="login-modal">
        <Modal
          className="login-modal"
          show={show}
          onHide={() => {
            props.setShow(false);
          }}
        // backdrop="static"
        // keyboard={false}
        >
          <Modal.Body
            style={{
              height: "fit-content",

              borderRadius: "7px",
            }}
          >
            <div class="">
              <div class="d-flex justify-content-end">
                <img
                  src="../assets/icons/cancel.png"
                  style={{ width: "33px" }}
                  class="pointer"
                  onClick={() => {
                    dispatch(resetotp());
                    props.setShow(false);
                  }}
                ></img>
              </div>
              {!showRegistrationForm ? (
                <>
                  <h3 class="text-center">Login to MetaMechx</h3>
                  {isMobile && (
                    <div class="d-flex justify-content-around">
                      <img
                        src="/assets/imgs/login/mobile_img.png"
                        class="w-75 my-4"
                      ></img>
                    </div>
                  )}
                  <p class="mt-2 text-center">
                    To place an order, log in to your existing account or sign
                    up
                  </p>

                  <form>
                    <input
                      type="tel"
                      placeholder="Enter Mobile Number"
                      autoComplete="nope"
                      ref={(input) => (mobileNumberRef = input)}
                      class="input-field w-100 p-2 mb-3"
                      maxLength={10}
                      value={mobileNum}
                      disabled={otpsent}
                      onChange={(event) => validateMobileNum(event)}
                      autoFocus
                      onKeyDown={handleKeypress}
                    />

                    {otpsent && (
                      <>
                        <div class="otp-fields d-flex justify-content-around my-3">
                          <OTPInput
                            value={OTP}
                            onChange={setOTP}
                            autoFocus
                            OTPLength={6}
                            otpType="number"
                            disabled={false}
                            autoComplete="nope"

                          />
                        </div>
                        <div
                          class={` resend-otp-btn ${timerCompleted ? "time-completed" : "display-timer"
                            }`}
                        >
                          <ResendOTP
                            onResendClick={resendOTP}
                            onTimerComplete={() => {
                              setOTP(null)
                              setTimerCompleted(true);
                            }}
                          />
                        </div>
                      </>
                    )}
                  </form>
                  <div class="d-flex align-items-center justify-content-around my-3">
                    <button
                      class="otp-btn text-white"
                      onClick={handleLoginClick}
                    >
                      {otpsent ? "Verify OTP" : "Send OTP"}
                    </button>
                  </div>
                </>
              ) : (
                <div>
                  <h3 class="text-center">Sign Up to MetaMechx</h3>
                  <p class="mt-2 text-center">
                    To place an order, log in to your existing account or sign
                    up
                  </p>
                  <div class="row">
                    <p class="registraion-labels m-0 p-0">Name *</p>
                    <DebounceInput
                      minLength={6}
                      debounceTimeout={1000}
                      type="text"
                      placeholder="Enter your name"
                      class={`input-field w-100 p-2 mb-3 ${nameErr && "err"}`}
                      autoComplete="nope"
                      value={name}
                      onKeyUp={(event) => validateName(event)}
                    />
                    {nameErr && (
                      <p class="m-0 p-0" style={{ color: "red" }}>
                        Please Enter your name
                      </p>
                    )}
                  </div>
                  <div class="row">
                    <p class="registraion-labels m-0 p-0">Email *</p>
                    <DebounceInput
                      debounceTimeout={1000}
                      type="email"
                      placeholder="Email"
                      class={`input-field w-100 p-2 mb-3 ${emailValid && "emailClass"
                        }`}
                      autoComplete="nope"
                      value={email}
                      onChange={(event) => validateEmail(event)}
                    />
                  </div>
                  <div class="row">
                    <p class="registraion-labels m-0 p-0">City</p>
                    <DebounceInput
                      debounceTimeout={1000}
                      type="text"
                      placeholder="City Name"
                      class={`input-field w-100 p-2 mb-3`}
                      autoComplete="nope"
                      value={address}
                      onKeyUp={(event) => {
                        setAddress(event.target.value);
                      }}
                    />
                  </div>
                  <div class="row">
                    <p class="registraion-labels m-0 p-0">Referral Code (optional)</p>
                    <DebounceInput
                      debounceTimeout={1000}
                      type="text"
                      maxLength={7}
                      placeholder="Referral code"
                      class={`input-field w-100 p-2 mb-3`}
                      autoComplete="nope"
                      value={referralCode}
                      onKeyUp={(event) => {
                        setReferralCode(event.target.value);
                      }}
                    />
                  </div>
                  <div class="d-flex align-items-center justify-content-around my-3">
                    <button
                      class={`otp-btn text-white ${disable && "registerBtnValid"
                        }`}
                      onClick={handleRegister}
                      disabled={disable}
                    >
                      Register
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

// export default Login;
export { LoginOrRegistrationModalComponent };
