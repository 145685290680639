import { useState } from "react"

const PartnerFAQs = () => {
    const faqs = [
        {
            id: 1,
            question: "What are the mandatory documents needed to list my workshops on metamechx?",
            answer: ["License OR Acknowledgement", "Owner Adhdar ", "Pan Card", "GSTIN Certificate", "Cancelled Cheque OR bank Passbook", "Service catalog"]
        },
        {
            id: 2,
            question: "After I submit all documents, how long will it take for my  gurage to go live on metamechx?",
            answer: ["After all mandatory documents have been received and verified it takes upto 3-4 working days for the onboarding to be completed and make your workshop live on the platform."]
        },
        {
            id: 3,
            question: "What is this one time Onboarding fees? Do I have to pay for it while registering?",
            answer: ["This is a one-time fee charged  towards the system & admin costs incurred during the onboarding process. It is deducted from the weekly payouts after you start receiving orders from Metamechx."]
        },
        {
            id: 4,
            question: "Who should I contact if I need help & support in getting onboarded?",
            answer: ["You can connect with Partner Support on +91 90359 85672 or write to partnersuport@Metamechx.com"]
        },
        {
            id: 5,
            question: "How much commission will I be charged by metamechx?",
            answer: ["The commission charges vary for different cities. You will be able to see the commission applicable for you once the preliminary onboarding details have been filled."]
        },
    ]

    const [selectedItem, setSelectedItem] = useState(null);

    const handleClick = (item) => {
        if (selectedItem?.id !== item.id) {
            setSelectedItem(item)
        } else {
            setSelectedItem(null)
        }
    }

    return (
        <>
            <div class="partner-faqs ">
                <p class="text-center my-4 partner-faqs-header">Frequently Asked Questions</p>
                <div class="row w-100 m-auto">
                    {faqs && faqs.map((item) => {
                        return (
                            <div class="col-lg-9  each-question" key={item.id}>
                                <div class="d-flex align-items-center justify-content-between pointer" onClick={() => { handleClick(item) }}>
                                    <p class="question m-0">{item.question}</p>
                                    <i class={`fas ${selectedItem?.id === item.id ? "fa-chevron-up" : "fa-chevron-down"}`} ></i>
                                </div>
                                {selectedItem?.id === item.id && <div>
                                    <hr />
                                    {item.answer.length === 1 ? <p class="answer m-0">{item.answer}</p> :
                                        <ul>
                                            {item.answer.map((answer) => {
                                                return (
                                                    <li class="answer ans-list-item">{answer}</li>
                                                )
                                            })}
                                        </ul>
                                    }
                                </div>}
                            </div>)
                    })}
                </div>
            </div>
        </>
    )
}
export default PartnerFAQs;