import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { VehicleService } from "../../services/vehicle.service";

const initialState = {
    addVehicleResponse: null,
    vehiclesList: null,
    vehicleRegistrationNumber: null,
    updateVehicleResp: null,
    deleteVehicleResp: null,
    getvehiclesListResp: null
}

export const addVehicle = createAsyncThunk('vehicle/addVehicle', async (data, thunkAPI) => {
    const vehicleService = new VehicleService();
    try {
        const response = await vehicleService.addVehicle(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
});

export const getUserVehiclesList = createAsyncThunk('vehicle/getUserVehiclesList', async (data, thunkAPI) => {
    const vehicleService = new VehicleService();
    try {
        const response = await vehicleService.getUserVehiclesList(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const updateVehicle = createAsyncThunk('vehicle/updateVehicle', async (data, thunkAPI) => {
    const vehicleService = new VehicleService();
    try {
        const response = await vehicleService.updateVehicle(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})

export const removeVehicle = createAsyncThunk('vehicle/removeVehicle', async (data, thunkAPI) => {
    const vehicleService = new VehicleService();
    try {
        const response = await vehicleService.removeVehicle(data);
        return response;
    } catch (error) {
        return thunkAPI.rejectWithValue(error);
    }
})


const vehicleSlice = createSlice({
    name: "vehicle",
    initialState,
    reducers: {
        reset: (state) => {
            state.addVehicleResponse = null;
            state.deleteVehicleResp = null;
            state.getvehiclesListResp = null
        },
        updateVehiclesList: (state, action) => {
            state.vehiclesList = action.payload
        },
        updateRegistrationNumber: (state, action) => {
            state.vehicleRegistrationNumber = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserVehiclesList.fulfilled, (state, action) => {
                state.getvehiclesListResp = action.payload;
                if (state.getvehiclesListResp.statuscode === 200) {
                    state.vehiclesList = action.payload.data
                }

            })
            .addCase(addVehicle.fulfilled, (state, action) => {
                if (action.payload && action.payload.data) {
                    state.addVehicleResponse = action.payload.data;
                }
            })
            .addCase(updateVehicle.fulfilled, (state, action) => {
                state.updateVehicleResp = action.payload.data;
            })
            .addCase(removeVehicle.fulfilled, (state, action) => {
                state.deleteVehicleResp = action.payload.data;
            })
    }
})

export const { reset, updateVehiclesList, updateRegistrationNumber } = vehicleSlice.actions;
export const vehicleSelector = (state) => state.vehicle;
export default vehicleSlice.reducer;