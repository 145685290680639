import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import _ from "underscore";
import { STATUS_CODES } from "../../config/constants";
import { addToCart, cartSelector, reset } from "../../features/cart/cartSlice";
import { emergencyserviceselector } from "../../features/emergency/emergencySlice";
import {
  garageSelector,
  getServiceCenterDetails,
} from "../../features/garage/garageSlice";
import {
  getServiceCenterAllServices,
  servicepackageselector,
} from "../../features/servicepackage/servicepackageSlice";
import { carspaserviceselector } from "../../features/spa/spaserviceSlice";
import { CommonServices } from "../../utils/commonServices";
import SelectionNav from "../SelectionNav";
import ServiceCenter from "../Service-center/ServiceCenter.jsx";
import OtherServicesByCenter, {
  OtherServicesListComponent,
} from "../otherServicesByCenter";

function Garages3() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const reviews = [1, 2, 3, 4, 5];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const commonServices = new CommonServices();

  const [isServiceCenterSelected, setIsServiceCenterSelected] = useState(false);
  const [showOtherServicesByCenter, setShowOtherServicesByCenter] =
    useState(false);
  const [showOtherServicesModal, setShowOtherServicesModal] = useState(false);
  const [showCompleteDesc, setShowCompleteDesc] = useState(false);
  const { serviceCenterDetails, showMoreServices, showGarageDetailsFlag } =
    useSelector(garageSelector);
  const { showSpaDetailsFlag } = useSelector(carspaserviceselector);
  const { serviceCenterAllServices } = useSelector(servicepackageselector);
  const { selectedCategory } = useSelector(emergencyserviceselector);
  const [currentSelectedCategory, setCurrentSelectedCategory] = useState(null);
  const { addToCartResponse } = useSelector(cartSelector);
  const selectedService = commonServices.getLocalStrgJSON(
    "MMX_SERVICE_DETAILS"
  );
  const isLaptopOrDesktop = useMediaQuery({
    query: "(min-width: 1008px) and (max-width: 1824px)",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 500px) and (max-width: 912px)",
  });

  useEffect(() => {
    if (selectedCategory && _.keys(selectedCategory).length > 0) {
      setCurrentSelectedCategory(selectedCategory);
    }
  }, [selectedCategory]);

  useEffect(() => {
    const category = commonServices.getLocalStrgJSON("MMX_SERVICE");
    setCurrentSelectedCategory(category);
  }, []);
  const showServiceCenterDetails = (serviceCenter) => {
    setIsServiceCenterSelected(true);
    dispatch(getServiceCenterAllServices(serviceCenter));
  };

  const addOtherService = (service) => {
    commonServices.setLocalStrg(
      "MMX_OTHER_SERVICE_DETAILS",
      JSON.stringify(service)
    );
    const vehicle = commonServices.getLocalStrgJSON("MMX_VEHICLE");
    const data = {
      customerVehicleId:
        commonServices.getLocalStrgJSON("MMX_SELECTED_VEHICLE")
          ?.customerVehicleId || "",
      servicesId: service.servicesId,
      vehicleModelId: JSON.parse(vehicle.selectedmodel).vehicleModelId,
      vehicleFueltypeId: JSON.parse(vehicle.selectedfueltype).fueltypeId,
      serviceCenterId: service.serviceCenterId,
      scsId: service.scsId,
    };
    dispatch(addToCart(data));
  };

  useEffect(() => {
    if (addToCartResponse !== null) {
      if (addToCartResponse.statuscode === STATUS_CODES[200]) {
        toast.info("1 Service Added", {
          toastId: "success1",
          autoClose: 2000,
          hideProgressBar: true,
        });
        if (showMoreServices || isServiceCenterSelected) {
          dispatch(
            getServiceCenterDetails(addToCartResponse?.data?.serviceCenterId)
          );
          dispatch(
            getServiceCenterAllServices({
              serviceCenterId: addToCartResponse?.data?.serviceCenterId,
            })
          );
        }
      } else if (addToCartResponse.statuscode === STATUS_CODES[301]) {
        toast.warning("Service already exist in cart", {
          toastId: "warning1",
          autoClose: 2000,
          hideProgressBar: true,
        });
      } else if (addToCartResponse.statuscode === STATUS_CODES[400]) {
        toast.warning("Validation error", {
          toastId: "Validation",
          autoClose: 2000,
          hideProgressBar: true,
        });
      } else {
        toast.error(addToCartResponse.message, {
          toastId: "Validation",
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    }
    dispatch(reset());
  }, [addToCartResponse]);

  useEffect(() => {
    if (showMoreServices) {
      setIsServiceCenterSelected(true);
      if (isMobile) {
        setShowOtherServicesModal(true);
      }
    } else {
      setShowOtherServicesByCenter(false);
      setShowOtherServicesModal(false);
    }
  }, [showMoreServices]);

  const OtherServicesModal = () => {
    return (
      <>
        <Modal
          show={true}
          className="show-other-services"
          onHide={() => {
            setShowOtherServicesModal(false);
          }}
        // backdrop="static"
        // keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#428372" }}>
              Select Other Services
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              height: "fit-content",
              backgroundColor: "whitesmoke",
            }}
          >
            <div className="mx-3">
              <SelectionNav />
            </div>
            <OtherServicesListComponent
              serviceCenterAllServices={serviceCenterAllServices}
              addOtherService={(service) => addOtherService(service)}
              selectedCategory={currentSelectedCategory}
            />
          </Modal.Body>
        </Modal>
      </>
    );
  };

  return (
    <>
      {/* <div className="container-fluid"> */}
      <div className="col-lg-12 col-md-12 col-sm-12 h-100 float-start">
        <div class="garages3">
          {(isMobile || isTablet) && (
            <div
              class="row mb-4 align-items-center back-to-services"
            // style={{ color: "#62a781" }}
            >
              <img
                src="/assets/icons/chevron-left.png"
                alt=""
                style={{ width: "25px" }}
                class="p-0 pointer"
                onClick={() => {
                  navigate("/services");
                }}
              />

              <h6 class="m-0 col-sm-11">{selectedService?.name}&nbsp;</h6>
            </div>
          )}
          <div class="header">
            <h5 style={{ color: "#62a781" }}>
              Choose Service Center &nbsp;
              {!showGarageDetailsFlag &&
                !showSpaDetailsFlag &&
                !isMobile &&
                !isTablet && (
                  <span style={{ color: "black", fontSize: "16px" }}>
                    {selectedService && ("(" + selectedService?.name + ")")}
                  </span>
                )}
            </h5>
          </div>
          {isServiceCenterSelected && serviceCenterDetails && (
            <>
              <div class="recommended">
                <div class="top row">
                  <div class="left col-lg-2 col-md-2 col-sm-2">
                    <img
                      src={serviceCenterDetails?.pic}
                      className="img-fluid"
                    />
                  </div>
                  <div class="right col-lg-9 col-md-10 col-sm-10">
                    <div class="garageName">
                      <h4>
                        {serviceCenterDetails && serviceCenterDetails?.name}
                      </h4>{" "}
                      &nbsp; &nbsp; <span class="common_color">Selected</span>
                    </div>
                    <div>
                      <ul class="p-0 d-flex">
                        {reviews.map((item) => {
                          return (
                            <>
                              {serviceCenterDetails?.overAllRatingData
                                ?.avarageRate != 0 &&
                                item <=
                                serviceCenterDetails?.overAllRatingData
                                  ?.avarageRate ? (
                                <li>
                                  <img
                                    class="rating"
                                    style={{ width: "17px" }}
                                    src="../assets/icons/star-selected.svg"
                                  ></img>
                                </li>
                              ) : (
                                <li>
                                  <img
                                    class="rating"
                                    style={{ width: "17px" }}
                                    src="../assets/icons/star.svg"
                                  ></img>
                                </li>
                              )}
                            </>
                          );
                        })}
                      </ul>
                    </div>
                    <div>
                      <p class="w-75 m-0 d-inline-block text-truncate">
                        {serviceCenterDetails &&
                          serviceCenterDetails?.experiance}{" "}
                        Experience
                      </p>
                    </div>
                    <div>
                      <p class="w-75 m-0 d-inline-block text-truncate">
                        {serviceCenterDetails &&
                          serviceCenterDetails?.full_address}
                      </p>
                    </div>
                  </div>
                </div>
                <>
                  <ins>Description:</ins>
                  <div
                    style={{ fontSize: "12px" }}
                    class={!showCompleteDesc && "service-center-desc"}
                    dangerouslySetInnerHTML={{
                      __html: serviceCenterDetails?.description,
                    }}
                  ></div>{" "}
                  {!showCompleteDesc && (
                    <p
                      class="m-0 common_color text-end pointer text-decoration-underline"
                      onClick={() => setShowCompleteDesc(true)}
                    >
                      more
                    </p>
                  )}
                  {showCompleteDesc && (
                    <p
                      class="m-0 common_color text-start pointer text-decoration-underline"
                      onClick={() => setShowCompleteDesc(false)}
                    >
                      less
                    </p>
                  )}
                </>
              </div>
              <div class="mt-3">
                {!showOtherServicesByCenter && !isLaptopOrDesktop && (
                  <button
                    class="common-btn rounded-0 p-2 fw-bold w-100  border-0"
                    onClick={() => {
                      setShowOtherServicesModal(true);
                    }}
                  >
                    Show Other Services
                  </button>
                )}
              </div>
              {isLaptopOrDesktop && (
                <div class="otherServices">
                  <div
                    class="heading pointer"
                    onClick={() => setShowOtherServicesByCenter(false)}
                  >
                    <h5>
                      Other Services by{" "}
                      {showGarageDetailsFlag
                        ? "Garage"
                        : showSpaDetailsFlag
                          ? "CarSpa"
                          : "Service center"}
                    </h5>
                  </div>
                  <div class="body">
                    <OtherServicesByCenter />
                  </div>
                </div>
              )}
            </>
          )}
          <ServiceCenter
            showServiceCenterDetails={(data) => showServiceCenterDetails(data)}
          />
        </div>
      </div>
      {/* </div> */}
      {showOtherServicesModal && OtherServicesModal()}
    </>
  );
}

export default Garages3;
