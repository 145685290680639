import { HttpClient } from '../utils/httpClient';
import { ADDRESS_LIST, ADD_ADDRESS, UPDATE_ADDRESS, DELETE_ADDRESS } from '../config/constants';
class AddressesService {
    constructor() {
        this.httpClient = new HttpClient();
    }

    async getAddressesList() {
        try {
            const body = {
                "length": 10,
                "start": 0,
                "search": ""
            }
            const response = await this.httpClient.post(ADDRESS_LIST, body);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
    async addAddress(data) {
        try {
            let saveData;
            if (data.Location) {
                saveData = data;
            } else {
                saveData = {
                    Address1: data.address1,
                    Address2: data.address2,
                    City: data.town,
                    PinCode: data.pindcode,
                    Location: data.address1 + "," + data.address2 + "," + data.town + "," + data.state + "," + data.pindcode,
                    State: data.state,
                    SaveAs: data.saveAs,
                    AddressType: data.addressType,
                    Latitude:data.latitude,
                    Longitude:data.longitude
                }
            }


            const response = await this.httpClient.post(ADD_ADDRESS, saveData)
            return response;
        } catch (error) {
            throw error;
        }
    }

    async editAddress(data) {
        try {
            let saveData;
            if (data.Location) {
                saveData = data;
            } else {
                saveData = {
                    Address1: data.address2,
                    Address2: data.address1,
                    City: data.town,
                    PinCode: data.pindcode,
                    Location: data.address1 + data.address2 + data.town + data.state + data.pindcode,
                    State: data.state,
                    SaveAs:data.saveAs,
                    AddressType: data.addressType
                }
            }


            const response = await this.httpClient.put(UPDATE_ADDRESS + `${data.customerAddressId}`
                , saveData)
            return response;
        } catch (error) {
            throw error;
        }
    }
    async removeAddress(data) {
        try {
            const Data = { ...data, status: 0 }
            const response = await this.httpClient.put(UPDATE_ADDRESS + `${data.customerAddressId}`
                , Data)
            return response;
        } catch (error) {
            throw error;
        }
    }
}
export { AddressesService }