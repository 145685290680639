import { useMediaQuery } from "react-responsive";

const ServiceFeatures = (props) => {

    const { service, showAllFeatures, setShowAllFeatures } = props;
    const isTablet = useMediaQuery({
        query: "(min-width: 500px) and (max-width: 912px)",
      });

    const expandFeatures = () => {
        if ((showAllFeatures.isTrue && service.servicesId === showAllFeatures.service.servicesId)) {
            return true;
        } else {
            return false;
        }
    }
    return (
        <>
            <div className="service-features">
                <ul className={`description row px-2 mt-3 mb-0 ${isTablet && "mx-1"}`}>
                    {(expandFeatures() || service?.total_features <= 4) ? service?.features?.map((item) => {
                        return (<>
                            <li class="col-lg-6 col-md-6 p-0 each-service-feature">
                                <div class="mx-2">
                                {item !== "" && <i
                                    className="fa fa-check-circle"
                                    aria-hidden="true"
                                />}{" "}
                                {item}
                                </div>
                            </li>
                        </>)
                    }) : (
                        <>
                            {service?.features?.map((item, key) => {
                                return (<>
                                    {key <= 2 && <li class="col-lg-6 col-md-6 p-0 each-service-feature">
                                    <div class="mx-2">
                                {item !== "" && <i
                                    className="fa fa-check-circle"
                                    aria-hidden="true"
                                />}{" "}
                                {item}
                                </div>
                                    </li>}
                                </>)
                            })}
                            {service?.features?.length >0 && <li class="col-lg-6 col-md-6 text-decoration-underline pointer show-more-features d-flex align-items-center" onClick={() => setShowAllFeatures({ isTrue: true, service: service })}>
                                Show more {">>"}
                            </li>}

                        </>

                    )}
                </ul>
            </div>
        </>
    )
}

export default ServiceFeatures;