import { HttpClient } from '../utils/httpClient';
import { ORDER_LIST, ORDER_DETAILS,ORDER_CANCEL } from '../config/constants';
class OrderService {
    constructor() {
        this.httpClient = new HttpClient();
    }

    async getOrdersList(body) {
        try {
            const response = await this.httpClient.post(ORDER_LIST, body);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async getOrderDetails(orderId) {
        try {
            const response = await this.httpClient.get(ORDER_DETAILS + orderId);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async cancelOrder(orderId) {
        try {
            const response = await this.httpClient.post(ORDER_CANCEL + orderId);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

}
export { OrderService }