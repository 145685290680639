import Modal from "react-bootstrap/Modal";
import React from "react";

const Loader = () => {
  return (
    <div>
      <Modal
        className="loader-modal"
        show={true}
        onHide={() => {
          // handleClose();
        }}
        backdrop="static"
        // keyboard={false}
        centered
      >
        <Modal.Body
          style={{
            borderRadius: "7px",
            paddingTop: "10%",
          }}
        >
          <img
            src="../assets/loader/imgpsh_fullsize_anim.gif"
            style={{ width: "100%", height: "100%" }}
          ></img>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Loader;
