import { CMS_PAGE_CONTENT } from '../config/constants';
import { HttpClient } from '../utils/httpClient';
import { CommonServices } from '../utils/commonServices';
class TermsAndConditionsService {
    constructor() {
        this.httpClient = new HttpClient();
        this.commonServices = new CommonServices()
    }

    async getTermsAndConditions() {
        try {
            const response = await this.httpClient.get(CMS_PAGE_CONTENT + "?page_name=terms-conditions");
            return response;
        } catch (error) {
            throw error;
        }
    }


}

export { TermsAndConditionsService };
