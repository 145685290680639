import { API_URL, SECURE_KEY } from '../config/';
import axios from 'axios';
import { decode as atob, encode as btoa } from 'base-64';

class HttpClient {
  instance;
  constructor(
    baseURL = API_URL,
    headers
  ) {
    this.instance = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.getToken(),
        secure_key: SECURE_KEY,
        device: this.getLocalStrg("MMX_DEVICE"),
        deviceid: this.getLocalStrg("MMX_DEVICEID"),
        ...headers
      },
    });

    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        // TODO: Add proper error handling
        return Promise.reject(error);
      },
    );
  }

  getToken() {
    const jwt = this.getLocalStrg('MMX_AUTH_TOKEN');
    return (jwt) ? `Bearer ${jwt}` : '';
  }

  get = (url) => {
    return this.instance.get(url);
  };

  post = (url, data) => {
    return this.instance.post(url, data);
  };

  filePost = (url, data, headers) => {
    return this.instance.post(url, data, headers);
  };

  put = (url, data) => {
    return this.instance.put(url, data);
  };

  delete = (url) => {
    return this.instance.delete(url);
  };

  patch = (url, data) => {
    return this.instance.patch(url, data);
  };


  getLocalStrg(key) {
    try {
      if (localStorage.getItem(key) !== null && localStorage.getItem(key) !== undefined) {
        let gval = localStorage.getItem(key);
        return atob(atob(gval));
      } else { return ''; }
    } catch (e) {
      return '';
    }
  }
}

export { HttpClient };
