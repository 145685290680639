import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ServicePackageService } from '../../services/servicepackage.service';

// const services = JSON.parse(localStorage.getItem(""));

let intialState;
// if(services != null){
// intialState = services 
// }
// else{
intialState = {
  isLoading: false,
  getServicesResp:null,
  servicepackageservices: null,
  selectedservice: "",
  message: null,
  serviceCenterAllServices: null,
  errorResponse: {},
  showSelectVehicleModal: false,
  servicesSearchKey: null
};
// }


/**
 * Write your Async reducers here and add builders for them to the extraReducers object
 */
export const getservices = createAsyncThunk('servicepackage/getservices', async (data, thunkAPI) => {
  const service = new ServicePackageService();
  try {
    const response = await service.getservices(data);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getServiceCenterAllServices = createAsyncThunk('servicepackage/getServiceCenterAllServices', async (data, thunkAPI) => {
  const service = new ServicePackageService();
  try {
    const serviceCenterId = data.serviceCenterId;
    const response = await service.getServiceCenterAllServices(serviceCenterId)
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})


const servicepackageSlice = createSlice({
  name: 'servicepackage',
  initialState: intialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.message = null
      state.serviceCenterAllServices = null
      state.getServicesResp = null
    },
    setVehicleModal: (state, action) => {
      state.showSelectVehicleModal = action.payload
    },
    updateServicePackagesList: (state, action) => {
      state.servicepackageservices = action.payload
    },
    updateServicesSearchKey: (state, action) => {
      state.servicesSearchKey = action.payload;
    }
  },
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getservices.pending, (state, action) => {
        state.isLoading = true
      })
      .addCase(getservices.fulfilled, (state, action) => {
        state.isLoading = false
        if (action.payload && action.payload.data) {
          var data = action.payload.data;
          state.getServicesResp = action.payload.data
          if (data.statuscode == 200) {
            state.servicepackageservices = action.payload.data.data;
            state.message = null;
            // localStorage.setItem("SERVICES", JSON.stringify(state));
          }
          else {
            state.message = data.message;
          }
        }
      })
      .addCase(getServiceCenterAllServices.pending, (state, action) => {

      })
      .addCase(getServiceCenterAllServices.fulfilled, (state, action) => {

        state.serviceCenterAllServices = action.payload.data.data;
      })
      .addCase(getServiceCenterAllServices.rejected, (state, action) => {
        if (action.payload) {
          state.errorResponse = action.payload;
        }
      })
  }
});

export const { reset, setVehicleModal, updateServicePackagesList, updateServicesSearchKey } = servicepackageSlice.actions;

export const servicepackageselector = (state) => state.servicepackage;

export default servicepackageSlice.reducer;
