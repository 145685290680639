import React from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import "react-loading-skeleton/dist/skeleton.css";
import { useMediaQuery } from "react-responsive";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Router from './router/routes';
import { CommonServices } from './utils/commonServices';

function App() {

  let commonServices = new CommonServices();
  commonServices.setDeviceId();

  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 500px) and (max-width: 912px)",
  });

  if (isMobile) { commonServices.setLocalStrg("MMX_DEVICE", 'mweb'); }
  else if (isTablet) {
    commonServices.setLocalStrg("MMX_DEVICE", 'mwebd');
  }
  else { commonServices.setLocalStrg("MMX_DEVICE", 'web'); }

  return (
    <div className="App">
      <ToastContainer />
      <SkeletonTheme baseColor="#E4DFE3" highlightColor="#f9f9f9">
        <Router />
      </SkeletonTheme>
    </div>
  );
}

export default App;
