import React from 'react';
//import '../Testimonials/testimonials.css';
import OwlCarousel from 'react-owl-carousel';

function Testimonials(props) {
    const options = {
        items: 2,
        nav: true,
        navText: ["<div className='nav-btn prev-slide'></div>", "<div className='nav-btn next-slide'></div>"],
        loop: true,
        dots: false,
        // autoplay: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            980: {
                items: 2
            },
            1199: {
                items: 2
            }
        },
    };

    return (
        <React.Fragment>
            {/* <link href="assets/css/testimonials.css" type="text/css" rel="stylesheet"></link> */}
            <h4>SEE WHAT PEOPLE SAY ABOUT US</h4>
            <div className="testimonials my-4">
                <OwlCarousel className="owl-carousel" {...options}>
                    <div class="col-lg-11 col-md-12 d-flex align-items-stretch">
                        <div class="icon-box p-2 mx-3  rounded-3 feedback_1">
                            <div class="container-fluid px-0 py-2">
                                <div class="row">
                                    <div class="col-4">
                                        <img src="assets/imgs/testimonials/img-1.png" class="user_rate" alt="" />
                                    </div>
                                    <br />
                                    <div class="col-4 text-left text-bold">
                                        <h5 class="userName">
                                            <b class="text-dark">
                                                Santosh
                                            </b>
                                        </h5>
                                        <p>
                                            <small style={{ color: "black" }}>
                                                posted on 7th June
                                            </small>
                                        </p>
                                    </div>
                                    <div class="col-4 p-0 ">
                                        <div class="rate">
                                            <small>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star"></span>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p>
                                            <b class="text-dark">
                                                "Remarkable cleaning service"
                                            </b>
                                        </p>
                                        <p>
                                            <small class="text-dark review-desc">
                                                " Due to the lockdown and curfews, I was not able to turn on my car for a long time and it
                                                accumulated dust and rust
                                                because i wasnt using it. I gave my car for cleaning services from MetaMechX and it was
                                                delivered back to me as good as new.
                                                Smell and all!"
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-11 col-md-12 d-flex align-items-stretch mt-md-0">
                        <div class="icon-box p-2 mx-3  rounded-3 feedback_2">
                            <div class="container-fluid px-0 py-2">
                                <div class="row">
                                    <div class="col-4">
                                        <img src="assets/imgs/testimonials/img-1.png" class="user_rate" alt="" />
                                    </div>
                                    <div class="col-4 text-left text-bold">
                                        <h5 class="userName">
                                            <b>
                                                Advit
                                            </b>
                                        </h5>
                                        <p>
                                            <small >
                                                posted on 18th May
                                            </small>
                                        </p>
                                    </div>
                                    <div class="col-4 p-0">
                                        <div class="rate">
                                            <small>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p>
                                            <b>
                                                "Satisfied with the service finishing"
                                            </b>
                                        </p>
                                        <p>
                                            <small class="review-desc">
                                                "Very good experienced technicians. Gave my car for dent repair and painting, frankly didnt
                                                expect the end product to
                                                be so well done for the price i paid. Totally worth the money "
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-11 col-md-12 d-flex align-items-stretch  mt-lg-0">
                        <div class="icon-box p-2 mx-3 rounded-3 feedback_1">
                            <div class="container-fluid px-0 py-2">
                                <div class="row">
                                    <div class="col-4">
                                        <img src="assets/imgs/testimonials/img-1.png" class="user_rate" alt="" />
                                    </div>
                                    <div class="col-4 text-lefttext-dark text-bold">
                                        <h5 class="userName">
                                            <b>
                                                Sachin
                                            </b>
                                        </h5>
                                        <p>
                                            <small >
                                                posted on 15th May
                                            </small>
                                        </p>
                                    </div>
                                    <div class="col-4 p-0">
                                        <div class="rate">
                                            <small>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col text-dark">
                                        <p>
                                            <b>
                                                "Thankful for the emergency services"
                                            </b>
                                        </p>
                                        <p>
                                            <small class="review-desc">
                                                "My car broke down on the NH near Bedudi as i was returning from a trip from home with my
                                                daughter and i was really
                                                worried as it was very far from my home in Jayanagar and it was getting late.  Thankfully, MetaMechX's emergency services got me out of
                                                my problem when service
                                                mechanics approached my location and did a swift job. Will surely call MetaMechX for all my car
                                                services now!"
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-11 col-md-12 d-flex align-items-stretch ">
                        <div class="icon-box p-2 mx-3 rounded-3 feedback_2">
                            <div class="container-fluid px-0 py-2">
                                <div class="row">
                                    <div class="col-4">
                                        <img src="assets/imgs/testimonials/img-1.png" class="user_rate" alt="" />
                                    </div>
                                    <div class="col-4 text-lefttext-bold">
                                        <h5 class="userName">
                                            <b>
                                                Akash
                                            </b>
                                        </h5>
                                        <p>
                                            <small >
                                                posted on 1st May
                                            </small>
                                        </p>
                                    </div>
                                    <div class="col-4 p-0">
                                        <div class="rate">
                                            <small>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star "></span>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p>
                                            <b>
                                                "Best value for money"
                                            </b>
                                        </p>
                                        <p>
                                            <small class="review-desc">
                                                "Pleased with the prompt response and service time. Expected a higher bill but got the services
                                                under the best value for
                                                money. Keep it up"
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-11 col-md-12 d-flex align-items-stretch ">
                        <div class="icon-box p-2 mx-3 rounded-3 feedback_1">
                            <div class="container-fluid px-0 py-2">
                                <div class="row text-dark">
                                    <div class="col-4">
                                        <img src="assets/imgs/testimonials/img-1.png" class="user_rate" alt="" />
                                    </div>
                                    <div class="col-4 text-lefttext-bold">
                                        <h5 class="userName">
                                            <b>
                                                Kiran
                                            </b>
                                        </h5>
                                        <p>
                                            <small >
                                                posted on 25th April
                                            </small>
                                        </p>
                                    </div>
                                    <div class="col-4 p-0">
                                        <div class="rate">
                                            <small>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star "></span>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col text-dark">
                                        <p>
                                            <b>
                                                "Service was efficent and quick"
                                            </b>
                                        </p>
                                        <p>
                                            <small class="review-desc">
                                                "I had booked from MetaMechX services to get my tires changed and I was able to get it done very
                                                soon and in cheap price.
                                                The mechanic was an experienced guy too so I could relax."
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-11 col-md-12 d-flex align-items-stretch">
                        <div class="icon-box p-2 mx-3 rounded-3 feedback_2">
                            <div class="container-fluid px-0 py-2">
                                <div class="row">
                                    <div class="col-4">
                                        <img src="assets/imgs/testimonials/img-1.png" class="user_rate" alt="" />
                                    </div>
                                    <div class="col-4 text-lefttext-bold">
                                        <h5 class="userName">
                                            <b>
                                                Rahul Subramaniam
                                            </b>
                                        </h5>
                                        <p>
                                            <small >
                                                posted on 15th April
                                            </small>
                                        </p>
                                    </div>
                                    <div class="col-4 p-0">
                                        <div class="rate">
                                            <small>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p>
                                            <b>
                                                "Fast Service"
                                            </b>
                                        </p>
                                        <p>
                                            <small class="review-desc">
                                                "I needed to get my car serviced before a sudden trip and MetaMechX service agents were very
                                                swift and quick in their work.
                                                Very satisfied with the work!"
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel>
            </div>
        </React.Fragment>
    );
}

export default Testimonials;
