import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  cartSelector,
  getCartItems,
  removeCartItem,
  setOpenCartPage,
  updateCart,
  reset,
} from "../../features/cart/cartSlice";
import {
  getServiceCenterDetails,
  setShowMoreServices,
  updateGarageDetailsFlag,
  updateShowSuggestedServiceCentersFlag,
} from "../../features/garage/garageSlice";
import { updateSpaDetailsFlag } from "../../features/spa/spaserviceSlice";
import { getServiceCenterAllServices } from "../../features/servicepackage/servicepackageSlice";
import { CommonServices } from "../../utils/commonServices";
import { STATUS_CODES } from "../../config/constants";
import Loader from "../../common/Loader/Loader";
import { getWalletAmountToRedeem } from "../../features/couponsAndWallet/couponsAndWallet";
import { authSelector } from "../../features/auth/authSlice";

export default function MainCartPage() {
  const commonServices = new CommonServices();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    cartDetails,
    updateCartResponse,
    removeCartItemResp,
    cartItemsCount,
  } = useSelector(cartSelector);
  const { isAuthenticated } = useSelector(authSelector);
  const [cartItems, setCartItems] = useState(null);
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 500px) and (max-width: 912px)",
  });
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    dispatch(getCartItems());
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      if (
        cartDetails?.data &&
        cartDetails?.data.finalPayAmount &&
        !cartDetails?.data.is_walletamount_included
      ) {
        dispatch(getWalletAmountToRedeem(cartDetails?.data?.finalPayAmount));
      }
    }
  }, [isAuthenticated, cartDetails]);

  useEffect(() => {
    if (cartDetails && cartDetails.data?.cartIteams) {
      setCartItems(cartDetails.data?.cartIteams);
    } else {
      setCartItems(null);
    }
  }, [cartDetails]);

  const addMoreServices = () => {
    if (cartItemsCount > 0) {
      dispatch(setOpenCartPage(false));
      dispatch(getCartItems());
      dispatch(updateGarageDetailsFlag(false));
      dispatch(updateSpaDetailsFlag(false));
      dispatch(updateShowSuggestedServiceCentersFlag(true));
      dispatch(getServiceCenterDetails(cartDetails.data?.serviceCenterId));
      dispatch(
        getServiceCenterAllServices({
          serviceCenterId: cartDetails.data?.serviceCenterId,
        })
      );
      dispatch(setShowMoreServices(true));
      navigate("/services/service-centers");
    } else {
      navigate("/services");
    }
  };

  const removeItem = (cartItemId) => {
    const cartId = commonServices.getLocalStrg("MMX_CURRENT_CARTID");
    dispatch(
      removeCartItem({
        cartId,
        cartItemId,
      })
    );
  };

  useEffect(() => {
    if (
      removeCartItemResp &&
      removeCartItemResp.statuscode === STATUS_CODES[200]
    ) {
      toast.info("Item removed successfully!", {
        toastId: "removeItem1",
        autoClose: 2000,
        hideProgressBar: true,
      });
      dispatch(getCartItems());
    }
    dispatch(reset());
  }, [removeCartItemResp]);

  const updateQuantity = (cartItemId, quantity) => {
    setShowLoader(true);
    const data = {
      cartId: cartDetails?.data?.cartId,
      cartItemId: cartItemId,
      quantity: quantity,
    };
    dispatch(updateCart(data));
  };

  useEffect(() => {
    if (
      updateCartResponse?.status === "success" &&
      updateCartResponse?.statuscode === 200
    ) {
      dispatch(getCartItems());
      toast.info(updateCartResponse?.message, {
        toastId: "removeItem",
        autoClose: 2000,
        hideProgressBar: true,
      });
      setShowLoader(false);
    }
    dispatch(reset());
  }, [updateCartResponse]);

  const ChangeQuantityComponent = (props) => {
    const { cartItemId, quantity } = props;
    return (
      <>
        <div class="quantity col-sm-4 d-flex align-items-center justify-content-around">
          <i
            class="fas fa-minus pointer"
            onClick={() => {
              if (quantity > 1) {
                updateQuantity(cartItemId, quantity - 1);
              } else {
                removeItem(cartItemId);
              }
            }}
          ></i>
          <p class="m-0 quantity_number">{quantity}</p>
          <i
            class="fas fa-plus pointer"
            onClick={() => {
              updateQuantity(cartItemId, quantity + 1);
            }}
          ></i>
        </div>
      </>
    );
  };
  return (
    <>
      <div className="mycart p-3">
        <div class="row d-flex align-items-center justify-content-between">
          <h4 style={{ color: "#62a781" }} class="col-lg-4 col-md-4 col-sm-6">
            Your Cart
          </h4>
          <div
            className="add-more-services col-lg-3 col-md-4 col-sm-6 p-2 mb-2 rounded-3 border-0 text-center pointer d-flex align-items-center justify-content-center"
            style={{ backgroundColor: "#d8f9e4" }}
            onClick={() => {
              addMoreServices();
            }}
          >
            <img
              src="/assets/icons/addMore.png"
              style={{ width: "20px" }}
              alt=""
            />{" "}
            &nbsp;
            <p class="m-0">
              {cartItemsCount > 0 ? "Add More Services" : "Add Services"}
            </p>
          </div>
        </div>
        {cartItems && cartItems.length > 0 ? (
          cartItems.map((item) => {
            return (
              <>
                <div class="item row common_border rounded-3 p-2 mb-3">
                  <div className="col-lg-2 col-md-2 col-sm-4 ps-0">
                    <img
                      src={item.service_photos[0]}
                      alt=""
                      class="service-image w-100 rounded-3"
                    />
                  </div>
                  <div className="col-lg-10 col-md-10 col-sm-8 p-0">
                    <div className="mb-1 d-flex align-items-center justify-content-between">
                      <h6 class="service-name m-0">{item.service_name}</h6>
                      <img
                        src="/assets/icons/cancel.png"
                        class=" pointer remove-item-icon"
                        style={{ width: "25px" }}
                        alt=""
                        onClick={() => {
                          removeItem(item.cartItemId);
                        }}
                      />
                    </div>
                    <div
                      class={`d-flex align-items-center ${
                        isMobile ? "w-100" : isTablet ? "w-75" : "w-50"
                      } justify-content-between`}
                    >
                      <p class="m-0">
                        {cartDetails?.data?.service_center_name}
                      </p>
                      <ChangeQuantityComponent
                        cartItemId={item.cartItemId}
                        quantity={item.quantity}
                      />
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                      <p class="m-0">
                        {item.actual_service_cost !==
                          item.after_discount_service_cost && (
                          <del class="actual-price">
                            <i class="fa fa-inr" aria-hidden="true"></i>
                            {item.actual_service_cost}
                          </del>
                        )}
                        <strong class="discount-price">
                          <i class="fa fa-inr" aria-hidden="true"></i>
                          {item.after_discount_service_cost} ({item.quantity})
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <>
            <div class="d-flex align-items-center mt-5">
              <img
                src="../assets/imgs/cart/undraw_empty_cart_co35.png"
                style={{ width: "35%" }}
              />
              <p>Your Cart is empty now lets service your gaadi!</p>
            </div>
          </>
        )}
      </div>
      {showLoader && <Loader />}
    </>
  );
}
