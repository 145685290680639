import { useDispatch, useSelector } from "react-redux";
import { cartSelector, deleteCart } from "../../features/cart/cartSlice";
import { CommonServices } from "../../utils/commonServices";
import Modal from "react-bootstrap/Modal";

const CancelOrderConfirmModal = (props)=>{
    return(
        <>
        <Modal
          className="change-service-center-confirm-modal"
          show={true}
          onHide={() => {
            props.handleClose();
          }}
          centered
          // backdrop="static"
          // keyboard={false}
        >
          <Modal.Header >
            <Modal.Title style={{ color: "#428372" }}>
              Hey, Wait!!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              height: "8rem",
              borderRadius: "7px",
              paddingTop: "5%",
            }}
          >
            <p style={{fontSize:"17px"}}>
             Are you sure you want to cancel this service?
            </p>
            <div class="w-50 d-flex justify-content-evenly ms-auto">
              <button
                className="btn"
                style={{ backgroundColor: "#428372", color: "#ffff" }}
                onClick={() => {
                  props.handleConfirmation();
                  props.handleClose();
                }}
              >
                <i class="fas fa-check"></i> Yes
              </button>
              <button
                className="btn"
                style={{ backgroundColor: "#ce254a", color: "#ffff" }}
                onClick={() => {
                  props.handleClose();
                }}
              >
                <i class="fas fa-times"></i> No
              </button>
            </div>
          </Modal.Body>
        </Modal>
        </>
    )
}

export default CancelOrderConfirmModal;