import React from 'react';
import "../Footer/footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import { METAMECHX_ADDRESS, METAMECHX_EMAIL, METAMECHX_MOBILE } from '../../config/constants';
const Footer = () => {
  return (
    <div className="footer">
      <div className="foot-strip">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-7 float-start">
            <h6>Start Exploring the wheels of freedom </h6>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-5 ">
            <a href="https://play.google.com/store/apps/details?id=com.metamechx" target="_blank" className="btn-footer float-end" type="submit">Download</a>
          </div>
        </div>
      </div>
      <footer>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-xl-3   my-2 text-white">
              <Link to={"/home"} className="navbar-brand logo" onClick={() => { window.scrollTo(0, 0) }}>
                {/* <img
                src="assets/imgs/cart/basic_service-01.png"
                className="img-fluid"
              /> */}
                <img src="../assets/imgs/logo/metamechx-logo-gw.svg" alt="" class="img-fluid" />
                <span class="green_text logo_name">Meta</span><span style={{ color: "white" }} class="logo_name">Mech</span><span
                  class="green_text logo_name">X</span>
                <p class="logo_tagline m-0">YOUR MECHANIC EVERYWHERE</p>

              </Link>
            </div>
            <div className="col-lg-2 col-xl-3  my-2 ">
              <ul>
                <li>
                  {/* <a href="#" className="bx bx-chevron-right"> About</a> */}
                  <Link to={"/about"} className="nav-link p-0" onClick={() => { window.scrollTo(0, 0) }}>
                    About Us
                  </Link></li>
                <li>
                  <Link to={"/partners"} className="nav-link p-0" onClick={() => { window.scrollTo(0, 0) }}>
                    Partners
                  </Link></li>
                <li>
                  <Link to={"/terms&conditions"} className="nav-link p-0" onClick={() => { window.scrollTo(0, 0) }}>
                    Terms And Conditions
                  </Link></li>

              </ul>
            </div>
            <div className="col-lg-3 col-xl-3 my-2 pt-2">
              <span className="foot-icons">
                <a href="https://m.facebook.com/metamechx" target="_blank">
                  {/* <i className="fa-brands fa-facebook" /> */}
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a href="https://www.instagram.com/metamechx/" target="_blank">
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a href="https://twitter.com/MechxMeta/status/1566703390540701696?s=20&t=9ZrIxaunclorIjg4YZCPlA" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} />
                </a><a href="https://www.linkedin.com/company/metamechx/" target="_blank">
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a></span>
            </div>
            <div className="col-lg-4 col-xl-3 my-2 ">
              <ul>
                <li><a href="mailto:connect@metamechx.com" className="bx bx-envelope"><i class="far fa-envelope"></i>: {METAMECHX_EMAIL}</a></li>
                <li>
                  <i class="fas fa-phone-alt"></i>: {METAMECHX_MOBILE}
                </li>
                <li><a href="https://goo.gl/maps/DRuDncjVCbB2CWux7" className="bx bxl-telegram" target="_blank"><i class="fas fa-address-book"></i> {METAMECHX_ADDRESS} </a></li>
              </ul>
            </div>
            <div className="copy">
              <p>Copyright © 2022 MetaMechx All Rights Reserved</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;