import { HttpClient } from '../utils/httpClient';
import { PROFILE, UPLOAD_PROFILE_PIC } from '../config/constants';
class CustomerProfileService {
    constructor() {
        this.httpClient = new HttpClient();
    }

    async getCustomerDetails() {
        try {
            const response = await this.httpClient.get(PROFILE);
            return response.data;
        } catch (error) {
            throw error;
        }
    }


    async updateProfile(body) {
        try {
            const response = await this.httpClient.put(PROFILE, body);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async updateProfilePic(body) {
        try {
            const response = await this.httpClient.put(UPLOAD_PROFILE_PIC, body);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}
export { CustomerProfileService }